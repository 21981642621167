export const PARKING_SET = 'PARKING_SET';
export const PARKING_RESET = 'PARKING_STOP';
export const LICENSINGS_SET = 'LICENSINGS_SET';

const INITIAL_STATE = {

    parking: [],
    licensings: []
}

export function reportReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case PARKING_SET:
            
            return {
                ...state,
                parking: action.payload
            }

        case PARKING_RESET:
            return INITIAL_STATE

        case LICENSINGS_SET:
            
            return {
                ...state,
                licensings: action.payload
            }

        default:
            return state
    }

}