import { useSelector } from 'react-redux';
import clsx from "clsx";
import { 
    makeStyles,
    Grid,
    Avatar,
    Typography,
    Box
 } from '@material-ui/core';
import myAccountImg from './accountImage.jpeg';
import MyAccountTab from './components/MyAccountTab';
import './styles.css';

const useStyles = makeStyles((theme) => ({
    avatarContent: {
        width: theme.spacing(15.7),
        height: theme.spacing(15.7),
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        fontSize: '4rem',
        backgroundColor: '#A61E17'
    },
}));

export default function MyAccount() {

    const { user } = useSelector((state) => state.user);
    const classes = useStyles();

    return (
        
        <Grid container spacing={3} id="myaccount-container">
            <Grid item xs={12} id="image-container">
                <img src={myAccountImg} alt="Artificial Intelligence" height={220} width="100%" />
            </Grid>

            <Grid item xs={12} id="profile-container">
                <Box className={clsx(classes.avatarContent, 'avatar-content')}>
                    <Avatar className={classes.large}>{user.hasOwnProperty('id') && user?.name.charAt(0)}</Avatar>
                </Box>
                <Typography variant="h4" className="username">{user.name}</Typography>
            </Grid>

            <Grid item xs={12}>
                <MyAccountTab />
            </Grid>
        </Grid>
    );
}