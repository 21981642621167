import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    Typography,
    MenuItem,
    Box
} from '@material-ui/core';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import FormikSelect from '../../App/components/FormikSelect';
import GreatMask from '../../App/utils/GreatMask';
import { createLicensingEstablishment, setLicensing } from '../licensingActions';
import './styles.css';

export default function FormNewLicensing({ open, setOpen, license }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const formik = useFormik({

        initialValues: {
            name: '',
            phone_number: '',
            free_minutes: 'Tempo em minutos',
            parking_free: false,
        },
        validate: values => {

            const errors = {};

            if (!values.free_minutes) {
                errors.free_minutes = true;
            }

            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            dispatch(createLicensingEstablishment({

                id_licensing: license.id,
                free_minutes: Number(values.free_minutes),
                parking_free: values.parking_free,

            }, (resp) => {

                setModal({

                    open: true,
                    title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                    text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
                })

                if (typeof resp === 'boolean' && resp && typeof setOpen === 'function') {

                    dispatch(setLicensing((resp) => {

                        if (typeof resp !== 'boolean' || !resp) {

                            setModal({
                                modal,
                                open: true,
                                text: resp
                            })
                        }

                        setLoading(false)
                    }))

                    setOpen();
                }

                setLoading(false);
            }));
        },
    });

    const searchZipcode = async (zipcode) => {

        setLoading(true);

        const json = await fetch(`https://viacep.com.br/ws/${zipcode}/json`).then(resp => resp.json());

        if (json.hasOwnProperty('erro')) {

            setLoading(false);
            return setModal({
                open: true,
                title: 'ERRO!',
                text: 'CEP não existe, informe o CEP corretamente.'
            })
        }

        await formik.setFieldValue('district', json.bairro)
        await formik.setFieldValue('city', json.localidade)
        await formik.setFieldValue('street', json.logradouro)

        setLoading(false);
    }

    function handleClose() {
        if (typeof setOpen === 'function') setOpen();
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent>
                <Typography variant="h5" className="dialog-title">Vincular convênio</Typography>

                <Box className="content-form-newholder">
                    <Typography variant="subtitle1" className="dialog-title">
                        Insira os dados para vincular o convênio
                    </Typography>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form"
                    >
                        {/*

                        <Box className="half-div">
                            <FormikTextInput
                                label="Nome"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className="half-input"
                            />
                            <FormikTextInput
                                label="Número do celular"
                                name="phone_number"
                                value={formik.values.phone_number}
                                onChange={(e) => formik.setFieldValue('phone_number', GreatMask.toPhone(e.target.value))}
                                InputLabelProps={{ maxLength: 14 }}
                                className="half-input"
                            />
                        </Box> */}

                        <Box className="half-div">
                            <FormikSelect
                                label="Estacionamento gratuito"
                                name="parking_free"
                                value={formik.values.parking_free}
                                onChange={(e) => formik.setFieldValue('parking_free', e.target.value)}
                                className="half-input"
                                required
                                >
                                <MenuItem value={true} >Sim</MenuItem>
                                <MenuItem value={false} >Não</MenuItem>
                            </FormikSelect>
                            <FormikTextInput
                                label="Tempo gratuito"
                                name="free_minutes"
                                value={formik.values.free_minutes}
                                onChange={(e) => formik.setFieldValue('free_minutes', GreatMask.onlyNumber(e.target.value))}
                                className="half-input"
                                required
                                error={formik.errors.free_minutes}
                            />
                        </Box>

                        <Box className="button-content">
                            <MUIButton onClick={handleClose} buttonType="red">
                                Cancelar
                            </MUIButton>
                            <MUIButton
                                type="submit"
                                buttonType="green"
                                disabled={formik.isValid && formik.dirty ? false : true}
                            >
                                Cadastrar
                            </MUIButton>
                        </Box>
                    </form>
                </Box>
            </DialogContent>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}