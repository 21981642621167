import { isValid, isFuture } from 'date-fns';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Loader from '../../App/components/Loader';
import MUIButton from '../../App/components/MUIButton';
import InputKeyboardDatePicker from '../../App/components/InputKeyboardDatePicker';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import { filterCash } from '../cashActions';
import './styles.css';

export default function CashHeader({ userAccessGroup, onNewCash }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });
    const formik = useFormik({
        initialValues: {
            initialDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            finalDate: new Date()
        },
        validate: values => {
            const errors = {};
            if (!isValid(values.initialDate) || isFuture(values.initialDate)) {
                errors.initialDate = true;
            }
            if (!isValid(values.finalDate) || isFuture(values.finalDate)) {
                errors.finalDate = true;
            }
            return errors;
        },
        onSubmit: values => {
            setLoading(true);
            dispatch(filterCash({
                initialDate: values.initialDate,
                finalDate: values.finalDate,
            }, (resp) => {
                if (typeof resp !== 'boolean' || !resp) {
                    setModal({
                        ...modal,
                        open: true,
                        title: 'Aviso',
                        text: resp
                    })
                }
                
                setLoading(false);
            }));
        },
    });

    function handleNewCash() {
        typeof onNewCash === 'function' && onNewCash();
    }

    return (
        <Card id="header-container">
            <div className="title-content">
                <Typography variant="h4">Caixa</Typography>
                <Typography variant="h6">
                    {userAccessGroup === 1 ? 'Cadastro, Alteração e Busca dos caixas' : 'Alteração e Busca dos caixas'}
                </Typography>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="form"
            >
                <div className="cash-filter-content">
                    <InputKeyboardDatePicker
                        label="Data inicial"
                        name="initialDate"
                        value={formik.values.initialDate}
                        onChange={(date) => formik.setFieldValue('initialDate', date)}
                        className="filter-itens"
                    />
                    <InputKeyboardDatePicker
                        label="Data final"
                        name="finalDate"
                        value={formik.values.finalDate}
                        onChange={(date) => formik.setFieldValue('finalDate', date)}
                        className="filter-itens"
                    />
                    <MUIButton color="primary" className="filter-itens" type="submit">
                        Filtrar
                    </MUIButton>

                    {/*userAccessGroup === 1 && (
                        <MUIButton color="primary" className="filter-itens" onClick={handleNewCash}>
                            Cadastrar
                        </MUIButton>
                    )*/}
                </div>
            </form>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Card>
    );
}