import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import './styles.css';

export default function MainWithData({ title, children }) {
    return (
        <Card id="content-with-data">
            <Typography variant="h6">{title}</Typography>
            <div className="result-content">
                {children}
            </div>
        </Card>
    );
}