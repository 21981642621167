export const PARKING_SET = 'PARKING_SET';
export const PARKING_CREATE = 'PARKING_CREATE';
export const PARKING_STOP = 'PARKING_STOP';
export const PARKING_RESET = 'PARKING_STOP';

const INITIAL_STATE = {
    parking: []
}

export function parkingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case PARKING_SET:
            return {
                ...state,
                parking: action.payload
            }
        case PARKING_CREATE:
            const newParking = [...state.parking, action.payload]
            return {
                ...state,
                parking: newParking
            }
        case PARKING_STOP:
            let parkings = state.parking.filter(item => item.id !== action.payload.id)
            parkings.push(action.payload)
            return {
                ...state,
                parking: parkings
            }
        case PARKING_RESET:
            return INITIAL_STATE
        default:
            return state
    }
}