export const types = [
    { id_establishment: "1", name: "Rotativo" },
    { id_establishment: "2", name: "Diária" },
    { id_establishment: "3", name: "Mensalista" },
];

export const parkingTypes = {
    1 : 'Rotativo',
    2 : 'Diária',
    3 : 'Mensalista',
}

// type
// 'ROTATING'
// 'DIARY'
// 'HOLDER'