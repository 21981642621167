const useColors = () => ({
    greenCS: '#0E773C',
    blueCS: '#17194B',
    yellowCS: '#FFE600',
    redCS: '#E7141C',
    redDarkCS: '#A61E17',
    grayDarkCS: '#1D1D1D',
    white: '#fff',
    redLight: '#d36e6e',
    red: '#d9534f',
    gray: '#ddd',
    grayLight: '#f7f7f7',
    grayDark: '#666',
    green: '#0cff00',
    yellow: '#fff600',
    orange: '#ff7b00',
});

export default useColors;
