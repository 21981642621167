import { format } from 'date-fns';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import FormikSelect from '../../App/components/FormikSelect';
import MUIButton from '../../App/components/MUIButton';
import { vehicleTypes } from '../../App/utils/typesVehicles';
import { types } from '../../App/utils/typesEstablishment';
import GreatMask from '../../App/utils/GreatMask';
import Validation from '../../App/utils/Validation';
import { createParking } from '../parkingActions';
import './styles.css';

export default function FormNewParking({ open, onConfirm, cancel }) {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });
    const formik = useFormik({
        initialValues: {
            id_vehicle_type: '1',
            name_type_vehicle: 'Carro',
            plaque: '',
            date: format(new Date(), 'dd-MM-yyyy'),
            hour: format(new Date(), 'HH:mm'),
            name: '',
            typeEstablishment: '1',
            comments: ''
        },
        validate: values => {
            const errors = {};
            if (!values.id_vehicle_type) {
                errors.id_vehicle_type = true;
            }
            if (!values.plaque || values.plaque.length !== 7 || !Validation.plaque(values.plaque)) {
                errors.plaque = true;
            }
            if (!values.hour || values.hour.length !== 5 || !Validation.hours(values.hour)) {
                errors.hour = true;
            }
            if (!values.typeEstablishment) {
                errors.typeEstablishment = true;
            }
            return errors;
        },
        onSubmit: values => {
            setLoading(true);
            dispatch(createParking(values, user.external_id, (resp) => {
                setModal({
                    open: true,
                    title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                    text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
                })
                if (typeof resp === 'boolean' && resp && typeof onConfirm === 'function') {
                    onConfirm();
                }
                setLoading(false);
            }));
        },
    });

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent>
                <Typography variant="h5" className="dialog-title">Inserir registo de veículo</Typography>

                <div className="content-form-newholder">
                    <Typography variant="subtitle1" className="dialog-title">
                        Insira os dados para cadastrar o veículo
                    </Typography>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form"
                    >
                        <div className="half-div">
                            <FormikTextInput
                                label="Data"
                                name="date"
                                value={formik.values.date}
                                onChange={formik.handleChange}
                                className="half-input"
                                required
                                error={formik.errors.date}
                                disabled
                            />
                            <FormikTextInput
                                label="Horas"
                                name="hour"
                                value={formik.values.hour}
                                onChange={(e) => formik.setFieldValue('hour', GreatMask.hours(e.target.value))}
                                className="half-input"
                                InputLabelProps={{ maxLength: 5 }}
                                required
                                error={formik.errors.hour}
                            />
                        </div>
                        <div className="half-div">
                            <FormikTextInput
                                label="Placa"
                                name="plaque"
                                value={formik.values.plaque}
                                onChange={formik.handleChange}
                                className="half-input"
                                InputLabelProps={{ maxLength: 7 }}
                                required
                                error={formik.errors.plaque}
                            />
                            <FormikTextInput
                                label="Descrição breve do veículo"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className="half-input"
                            />
                        </div>
                        <div className="half-div">
                            <FormikSelect
                                label="Tipo do veículo"
                                name="id_vehicle_type"
                                value={formik.values.id_vehicle_type}
                                onChange={(e) => {
                                    formik.setFieldValue('id_vehicle_type', vehicleTypes[e.target.value - 1].id_vehicle_type);
                                    formik.setFieldValue('name_type_vehicle', vehicleTypes[e.target.value - 1].name);
                                }}
                                className="half-input"
                                required
                            >
                                {vehicleTypes.map((item) => <MenuItem value={item.id_vehicle_type} key={item.id_vehicle_type} className="menu">
                                    {`${item.name}`}
                                </MenuItem>
                                )}
                            </FormikSelect>
                            <FormikSelect
                                label="Tipo do estacionamento"
                                name="typeEstablishment"
                                value={formik.values.typeEstablishment}
                                onChange={(e) => formik.setFieldValue('typeEstablishment ', types[e.target.value - 1].id_establishment)}
                                className="half-input"
                                required
                            >
                                {types.map((item) => item.id_establishment !== "3" && (
                                    <MenuItem value={item.id_establishment} key={item.id_establishment} className="menu">
                                        {`${item.name}`}
                                    </MenuItem>
                                ))}
                            </FormikSelect>
                        </div>
                        <div className="full-div">
                            <FormikTextInput
                                label="Observações"
                                name="comments"
                                value={formik.comments}
                                onChange={formik.handleChange}
                                className="full-input"
                            />
                        </div>
                        <div className="button-content">
                            <MUIButton onClick={handleClose} buttonType="red">
                                Cancelar
                            </MUIButton>
                            <MUIButton type="submit" buttonType="green" disabled={formik.isValid === true && formik.dirty === true ? false : true}>
                                Cadastrar
                            </MUIButton>
                        </div>
                    </form>
                </div>
            </DialogContent>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}