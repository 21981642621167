import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AlertModalDialog from '../App/components/AlertModalDialog';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import Loader from '../App/components/Loader';
import CashTable from './components/CashTable';
import CashHeader from './components/CashHeader';
import NewCashDialog from './components/NewCashDialog';
import { createCash, listCash, openCash, changeCash, closeCash } from './cashActions';
import { cashListPDF } from './reports/cashListReports';
import MUIButton from '../App/components/MUIButton';

export default function Cash() {
    const { cash } = useSelector((state) => state.cash);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [newCash, setNewCash] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        setLoading(false);
        dispatch(listCash());
    }, [dispatch])

    function handleNewCash(value) {
        setLoading(true);
        setNewCash(false);
        dispatch(createCash({
            openValue: value,
        }, (resp) => {
            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Cadastro de novo caixa com sucesso!' : resp
            })
            setLoading(false);
        }));
    }

    function handleCancelNewCash() {
        setLoading(false);
        setNewCash(false);
    }

    function handleOpenCash(cash) {
        setLoading(true);
        dispatch(openCash(cash, (resp) => {
            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Abertura do caixa com sucesso!' : resp
            })
            setLoading(false);
        }));
    }

    function handleCloseCash(cash) {
        setLoading(true);
        dispatch(closeCash(cash, (resp) => {
            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Fechamento registrado com sucesso!' : resp
            })
            setLoading(false);
        }));
    }

    function handleChangeCash(cash) {
        setLoading(true);
        dispatch(changeCash(cash, (resp) => {
            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Alteração do valor com sucesso!' : resp
            })
            setLoading(false);
        }));
    }

    function getTableData() {
        
        if (cash.length === 0)
            return <MainWithoutData title="Lista dos caixas" />

        return (
            <MainWithData title="Lista dos caixas">

                <MUIButton
                    buttonType="green"
                    onClick={() => cashListPDF(cash)}>
                    Gerar PDF
                </MUIButton>

                <CashTable
                    resultSet={cash}
                    openCash={handleOpenCash}
                    closeCash={handleCloseCash}
                    changeCash={handleChangeCash}
                />
            </MainWithData>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <CashHeader userAccessGroup={user.id_access_group} onNewCash={() => setNewCash(true)} />
            </Grid>
            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            <NewCashDialog
                open={newCash}
                onConfirm={handleNewCash}
                cancel={handleCancelNewCash}
            />
        </Grid>
    );
}