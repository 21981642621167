export const SET_FIRST = 'SET_FIRST';
export const SET_API_KEY = 'SET_API_KEY';

const INITIAL_STATE = {
    first: true,
    apiKey: ''
}

export function appReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_FIRST:
            return {
                ...state,
                first: false
            }
        case SET_API_KEY:
            return {
                ...state,
                apiKey: action.payload
            }
        default:
            return state
    }
}