import { useSelector } from 'react-redux';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
    IconButton,
    MenuItem
} from '@material-ui/core';
import { Settings } from "@material-ui/icons";
import GreatMask from '../../App/utils/GreatMask';
import useStylesTable from '../../App/styles/useStylesTable'
import { PlateParkingFreeStatus } from './PlateParkingFreeStatus';

export default function PlateParkingFree({changeActive}) {
    
    const { platesParkingFree } = useSelector((state) => state.plateParkingFree);
    const classes = useStylesTable();

    function handleChangeActive (plateParkingFree, close = () => {}) {

        if (typeof changeActive === 'function') {
            
            changeActive(plateParkingFree);            
        }

        close()
    }

    return (
        
        <TableContainer component={Paper}>
            
            <Table className={classes.table} aria-label="simple table">
                
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Placa</TableCell>
                        <TableCell align="left">Renavam</TableCell>
                        <TableCell align="left">Observação</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell>Opções</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {platesParkingFree.map((plateParkingFree) => {

                    {/* console.log(licensing) */}

                    return (
                        <TableRow key={plateParkingFree.id}>

                            <TableCell align="justify" component="th" scope="row">
                                {plateParkingFree.vehicle.plaque}
                            </TableCell>

                            <TableCell align="justify" component="th" scope="row">
                                {plateParkingFree.vehicle.renavam}
                            </TableCell>

                            <TableCell align="left">
                                {plateParkingFree.observation}
                            </TableCell>

                            <TableCell align="center">
                                <PlateParkingFreeStatus status={plateParkingFree.active} />
                            </TableCell>

                            <TableCell width={50}>

                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <>
                                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                <Settings />
                                            </IconButton>

                                            <Menu {...bindMenu(popupState)}>
                                            
                                                <MenuItem onClick={() => handleChangeActive(plateParkingFree, popupState.close()) }>
                                                    {plateParkingFree.active ? 'Desativar' : 'Ativar' }
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </TableCell>
                        </TableRow>
                    )
                    })}

                </TableBody>
            </Table>
        </TableContainer>
    );
}