import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { parkingTypes } from '../../App/utils/typesEstablishment';
import ParkingStatus from "../../Parking/components/ParkingStatus";
import GreatMask from '../../App/utils/GreatMask';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function SummaryHistoryParkingTable({ resultSet }) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">

                <TableHead>
                    <TableRow>
                        <TableCell>Tipo</TableCell>
                        <TableCell align={"center"}>Data Entrada</TableCell>
                        <TableCell align={"center"}>Placa</TableCell>
                        <TableCell align={"center"}>Data Saída</TableCell>
                        <TableCell align={"center"}>Permanência</TableCell>
                        <TableCell align={"right"}>Valor</TableCell>
                        <TableCell align={"center"}>Situação</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {resultSet.map((parking, index) => (
                        
                        <TableRow key={parking?.id ? parking?.id : index}>
                            <TableCell>{parkingTypes[parking.id_parking_type]}</TableCell>
                            <TableCell align={"center"}>{parking.arrival}</TableCell>
                            <TableCell align={"center"}>{parking.plaque}</TableCell>
                            <TableCell align={"center"}>{parking.exit === '' ? 'Não contém' : parking.exit}</TableCell>
                            <TableCell align={"center"}>
                                {parking.minutes_elapsed === '' ? 'EM ANDAMENTO' : GreatMask.minutesToHoursMinutes(parking.minutes_elapsed)}
                            </TableCell>
                            <TableCell align={"right"}>R${parking.value}</TableCell>
                            <TableCell width={185} align={"center"} >
                                <ParkingStatus finalizado={parking.finished} />
                            </TableCell>

                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    );
}