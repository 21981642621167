import { useHistory } from 'react-router';
import {
    Card,
    Box,
    CardActions,
    CardContent,
    Button,
    Typography
} from '@material-ui/core';
import useColors from "../../App/hooks/useColors";
import './styles.css';

export default function ValuesWidget({ title, values, onClickButton }) {
    
    const history = useHistory();
    const colors = useColors();

    function handleGoTo() {
        if (typeof onClickButton === 'string') {
            history.push(onClickButton);
        } 
    }

    return (
        <Card id="widget-container">
            <CardContent className="widget-card-content">
                <Typography variant="h6">{title}</Typography>
                <Box className="content-value">
                    <Typography variant="h3" style={{ color: colors.redDarkCS }}>{values}</Typography>
                </Box>
            </CardContent>
            <CardActions className="widget-card-action">
                <Button size="small" onClick={handleGoTo}>Mais detalhes</Button>
            </CardActions>
        </Card>
    );
}