import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    Menu,
    IconButton,
    MenuItem
} from '@material-ui/core';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import FormikSelect from '../../App/components/FormikSelect';
import GreatMask from '../../App/utils/GreatMask';
import { createLicensingType, setLicensingType, deleteLicensingTypeValue, createLicensingTypeValue } from '../licensingTypeActions';
import './styles.css';
import useStylesTable from '../../App/styles/useStylesTable'
import { PlateParkingFreeStatus } from './PlateParkingFreeStatus';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Settings } from "@material-ui/icons";
import { vehicleTypes } from '../../App/utils/typesVehicles';

export default function LicensingTypeValue({ open, setOpen, licensingType }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const [initialValue,    setInitialValue]    = useState('0');
    const [additionalValue, setAdditionalValue] = useState('0');
    const [licensingValue,  setLicensingValue]  = useState('0');
    const [clientValue,     setClientValue]     = useState('0');

    const classes = useStylesTable();

    const formik = useFormik({

        initialValues: {

            id_licensing_type:      licensingType.id,
            id_establishment:       licensingType.id_establishment,
            id_vehicle_type:        '',
            initial_hour_value:     initialValue,
            additional_hour_value:  additionalValue,
            licensing_value:        licensingValue,
            client_value:           clientValue,
            vehicleType:            ''
        },
        validate: values => {

            const errors = {};

            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            let res = false

            Object.values(licensingType.licensingTypeValue).forEach(val => {
                
                if (val.vehicleType.id === formik.values.id_vehicle_type)
                    res = true
            });

            if (res) {

                setModal({
                    modal,
                    open: true,
                    text: "Tipo de Veículo já cadastrado."
                })

                setLoading(false);

                return
            }

            const vehicleType = vehicleTypes.filter(function (el) {
                return el.id_vehicle_type === formik.values.id_vehicle_type.toString()
            });

            if (vehicleType.length > 0) {

                let newVehicleType = {

                    id:     vehicleType[0].id_vehicle_type,
                    name:   vehicleType[0].name,
                }

                formik.values.vehicleType = newVehicleType;
            }

            dispatch(createLicensingTypeValue(values, licensingType, (resp) => {

                setModal({

                    open: true,
                    title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                    text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
                })

                if (typeof resp === 'boolean' && resp && typeof setOpen === 'function') {

                    dispatch(setLicensingType((resp) => {

                        if (typeof resp !== 'boolean' || !resp) {

                            setModal({
                                modal,
                                open: true,
                                text: resp
                            })
                        }

                        setLoading(false)
                    }))

                    setOpen();
                }

                setLoading(false);
            }));
        },
    });

    function handleCreateLicensingTypeValue () {

        setOpen(true)
    }

    function handleDeleteLicensingTypeValue(licensingTypeValue) {

        setLoading(true);

        dispatch(deleteLicensingTypeValue(licensingType, licensingTypeValue, (resp) => {

            setModal({

                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Excluído com sucesso!' : resp
            })

            setLoading(false);
        }));
    }

    function handleClose() {
        if (typeof setOpen === 'function') setOpen();
    }

    function handleSetInitialValue(field) {
        
        setInitialValue(GreatMask.onlyNumber(field.value))

        formik.values.initial_hour_value = GreatMask.onlyNumber(field.value) / 100;

        handleSetClientValue(field)
    }

    function handleSetAdditionalValue(field) {
        
        setAdditionalValue(GreatMask.onlyNumber(field.value))

        formik.values.additional_hour_value = GreatMask.onlyNumber(field.value) / 100;
    }

    function handleSetLicensginValue(field) {
        
        if (GreatMask.onlyNumber(field.value) > GreatMask.onlyNumber(initialValue)) {

            return;
        }

        setLicensingValue(GreatMask.onlyNumber(field.value))

        formik.values.licensing_value = GreatMask.onlyNumber(field.value) / 100

        handleSetClientValue(field)
    }

    function handleSetClientValue(field) {

        // setInitialValue(value)
        // console.log(licensingValue)
        // console.log(field.name)
        
        let value = '';

        if (field.name === 'initial_hour_value')
            value = setClientValue(GreatMask.onlyNumber(field.value) - GreatMask.onlyNumber(licensingValue))
        else
            value = setClientValue(GreatMask.onlyNumber(initialValue) - GreatMask.onlyNumber(field.value))
    }

    function handleSetVehicleType (value) {

        formik.setFieldValue('id_vehicle_type', Number(value))
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm">

            <DialogContent>

                <Typography variant="h5" className="dialog-title">Novo Valor</Typography>

                <Divider />

                <Box className="content-form">
                    
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form">

                        <Box className="half-div">

                            <FormikSelect
                                label="Veículo"
                                name="id_vehicle_type" 
                                value={formik.values.id_vehicle_type}
                                onChange={(e) => handleSetVehicleType(e.target.value)}
                                className="half-input">

                                {vehicleTypes.map((item) => (
                                        
                                    <MenuItem value={item.id_vehicle_type} key={item.id_vehicle_type} className="menu">
                                        {`${item.name}`}
                                    </MenuItem>
                                ))}

                            </FormikSelect>
                            
                        </Box>
                        
                        <Box className="half-div">

                            <FormikTextInput
                                label="Valor Hora Inicial"
                                name="initial_hour_value"
                                value={GreatMask.formatCurrencyBR(initialValue)}
                                onChange={(e) => handleSetInitialValue(e.target)}
                                className="half-input"
                                required/>

                            <FormikTextInput
                                label="Valor Hora Adicional"
                                name="additional_hour_value"
                                value={GreatMask.formatCurrencyBR(additionalValue)}
                                onChange={(e) => handleSetAdditionalValue(e.target)}
                                className="half-input" 
                                required/>
                        </Box>

                        <Box className="half-div">

                            <FormikTextInput
                                label="Valor pago pelo Convênio"
                                name="licensing_value"
                                value={GreatMask.formatCurrencyBR(licensingValue)}
                                onChange={(e) => handleSetLicensginValue(e.target)}
                                className="half-input" 
                                required/>

                            <FormikTextInput
                                label="Valor pago pelo Cliente"
                                name="client_value"
                                value={GreatMask.formatCurrencyBR(clientValue)}
                                onChange={formik.handleChange}
                                className="half-input" 
                                disabled={true}/>
                        </Box>

                        <Divider />
                        
                        <Box className="button-content">
                            
                            <MUIButton onClick={handleClose} buttonType="red">
                                Sair 
                            </MUIButton>

                            <MUIButton
                                type="submit"
                                buttonType="green"
                                disabled={formik.isValid && formik.dirty ? false : true}>

                                Salvar
                            </MUIButton>
                        </Box>
                    </form>
                </Box>

            </DialogContent>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })} />
                
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}