export const SET_HOLDER = 'SET_HOLDER';
export const SEARCH_HOLDER = 'SEARCH_HOLDER';
export const ADD_HOLDER = 'ADD_HOLDER';
export const CHANGE_HOLDER = 'CHANGE_HOLDER';
export const HOLDER_PAYMENTS = 'HOLDER_PAYMENTS';
export const HOLDER_PAYMENT_VALUE = 'HOLDER_PAYMENT_VALUE';
export const HOLDER_CREATED_PAYMENT = 'HOLDER_CREATED_PAYMENT';
export const RESET_HOLDER = 'RESET_HOLDER';

const INITIAL_STATE = {
    holders: [],
    holder: {},
    holderPayments: [],
    holderPaymentValue: {}
}

export function holderReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_HOLDER:
            return {
                ...state,
                holders: action.payload
            }
        case SEARCH_HOLDER:
            return {
                ...state,
                holder: action.payload
            }
        case ADD_HOLDER:
            return {
                ...state,
                holders: [...state.holders, action.payload]
            }
        case CHANGE_HOLDER:
            const holders = state.holders;
            const newHolders = holders.map((item) => {
                if (item.id === action.payload.id)
                    return {
                        ...item,
                        expiration_day: action.payload.expiration_day,
                        vehicles: action.payload.vehicles ?? item.vehicles
                    }
                return item
            })
            return {
                ...state,
                holders: newHolders
            }
        case HOLDER_PAYMENTS:
            return {
                ...state,
                holderPayments: action.payload
            }
        case HOLDER_PAYMENT_VALUE:
            return {
                ...state,
                holderPaymentValue: action.payload
            }
        case HOLDER_CREATED_PAYMENT:
            const payments = state.holderPayments.filter(item => item.id !== action.payload.id)
            payments.push(action.payload);
            return {
                ...state,
                holderPayments: payments
            }
        case RESET_HOLDER:
            return INITIAL_STATE
        default:
            return state
    }
}