import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextInput from '../../App/components/TextInput';
import Loader from '../../App/components/Loader';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import GreatMask from '../../App/utils/GreatMask';
import { sendValidationCodeOnline } from '../../App/authActions';
import { getHolder, saveHolder } from '../holderActions';
import { ENUM_NEW_HOLDER } from '../enum';
import FormNewHolder from './FormNewHolder';
import './styles.css';

export default function NewHolderDialog({ open, userExternalId, cancel }) {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [cpf, setCpf] = useState('');
    const [form, setForm] = useState({});
    const [steps, setSteps] = useState(ENUM_NEW_HOLDER.HOLDER_SEARCH);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        setLoading(false)
    }, [])

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    function handleConfirm(value) {
        setLoading(true);

        switch (steps) {
            case ENUM_NEW_HOLDER.HOLDER_SEARCH:
                const cpfOnly = GreatMask.onlyNumber(cpf);
                if (cpfOnly.trim() === '' && cpfOnly.length !== 11) {
                    setLoading(false);
                    return setModal({
                        open: true,
                        title: 'Aviso',
                        text: 'Insira o CPF corretamente.'
                    })
                }

                return dispatch(getHolder(cpfOnly, (resp) => {
                    setLoading(false);
                    setSteps(ENUM_NEW_HOLDER.HOLDER_FORM)
                }));
            case ENUM_NEW_HOLDER.HOLDER_FORM:
                setForm(value);
                if (value.external_id === '' || value.external_id === undefined) {
                    setLoading(false);
                    return saveUserData(value);
                }

                dispatch(sendValidationCodeOnline(value.external_id, userExternalId, (resp) => {
                    setLoading(false);
                    if (typeof resp !== 'boolean' || !resp) {
                        return setModal({
                            open: true,
                            title: 'Aviso',
                            text: resp
                        })
                    }

                }));
                setLoading(false);
                return setSteps(ENUM_NEW_HOLDER.HOLDER_CONFIRM_CODE);
            case ENUM_NEW_HOLDER.HOLDER_CONFIRM_CODE:
                if (typeof form.external_id === 'string' && form.external_id.length > 0 && (code.trim() === '' || code.length !== 6)) {
                    setLoading(false);
                    return setModal({
                        open: true,
                        title: 'Aviso',
                        text: 'Insira a senha corretamente.'
                    })
                }

                saveUserData();
                break;
            default: return (
                setModal({
                    open: true,
                    title: 'ERRO',
                    text: 'Erro'
                })
            )
        }
    }

    function saveUserData(values) {
        dispatch(saveHolder(userExternalId, values, (resp) => {
            setLoading(false);
            if (typeof resp === 'boolean' && resp) {
                setSteps(ENUM_NEW_HOLDER.HOLDER_SEARCH);
                return handleClose()
            }

            setModal({
                open: true,
                title: 'Aviso',
                text: resp
            })
            setSteps(ENUM_NEW_HOLDER.HOLDER_FORM);
        }));
    }

    function getComponents(value) {
        switch (value) {
            case ENUM_NEW_HOLDER.HOLDER_SEARCH: return (
                <>
                    <Typography variant="h5" className="dialog-title">Cadastro do mensalista</Typography>

                    <div className="content-search-holder">
                        <Typography variant="subtitle1" className="dialog-title">
                            Insira o CPF para buscar os dados do usuário/mensalista
                        </Typography>
                        <TextInput
                            id="search-exist-cpf"
                            label="CPF"
                            value={cpf}
                            onChange={(e) => {
                                if (GreatMask.onlyNumber(e.target.value).length < 12)
                                    setCpf(GreatMask.cpf(e.target.value))
                            }}
                            validValue='cpf'
                            className='filter-itens'
                            required
                        />
                    </div>
                </>
            )
            case ENUM_NEW_HOLDER.HOLDER_FORM: return (
                <FormNewHolder
                    cpf={cpf}
                    holderChange={form}
                    onConfirm={handleConfirm}
                    onCancel={handleClose}
                />
            )
            case ENUM_NEW_HOLDER.HOLDER_CONFIRM_CODE: return (
                <>
                    <Typography variant="h5" className="dialog-title">Cadastro do mensalista</Typography>

                    <div className="content-search-holder">
                        <Typography variant="subtitle1" className="dialog-title">
                            Insira o código de segurança que foi enviado para o celular do(a) {form.name} pra confirmar o cadastro do mensalista
                        </Typography>
                        <TextInput
                            label="Código de segurança"
                            value={code}
                            className="filter-itens"
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                </>
            )
            default: return (
                <Typography variant="h5" className="dialog-title">ERROR</Typography>
            )
        }
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen
        >
            <DialogContent>
                {!loading ? getComponents(steps) : <Loader loading={loading} />}
            </DialogContent>

            {steps !== ENUM_NEW_HOLDER.HOLDER_FORM && (
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    {/* <Button onClick={handleClose} >
                                Voltar
                            </Button> */}
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Próximo
                    </Button>
                </DialogActions>
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Dialog>
    );
}