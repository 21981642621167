import axios from 'axios';
import StorageService from './StorageService';
import { history } from './HistoryService';

export const baseURL = 'https://api.prod.estacionefacil.consforsistemas.com.br';
// export const baseURL = 'http://localhost:4001';

export const ApiService = {

    post: async (uri, data) => {

        if (uri === '') {
            return {};
        }
        
        const endpoint = baseURL + uri;

        const config = await getToken();

        return axios.post(endpoint, data, config).then(response => {

            return response;

        }).catch(error => {

            let statusCode = error.response?.status || 0;

            return getError(statusCode);
        });
    },

    get: async (uri) => {

        if (uri === '') {
            return {};
        }

        const endpoint = baseURL + uri;

        const config = await getToken();

        return axios.get(endpoint, config).then(response => {

            return response;

        }).catch(async error => {
            let statusCode = error.response?.status || 0;

            return getError(statusCode);
        });
    },
    patch: async (uri, data) => {
        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const config = await getToken();

        return axios.patch(endpoint, data, config).then(response => {
            return response['data'];
        }).catch(error => {
            let statusCode = error.response?.status || 0;
            return getError(statusCode);
        });
    },
    put: async (uri, data) => {

        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const config = await getToken();

        return axios.put(endpoint, data, config).then(response => {
            return response['data'];

        }).catch(error => {
            let statusCode = error.response?.status || 0;
            return getError(statusCode);
        });
    },
    delete: async (uri, data) => {

        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const token = await StorageService.get('token');

        return axios.delete(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            withCredentials: true,
            data
        }).then(response => {
            return response['data'];
        }).catch(error => {
            let statusCode = error.response?.status || 0;
            return getError(statusCode);
        });
    },
}

async function getToken() {
    const token = await StorageService.get('token');

    return {

        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        },
        withCredentials: true,
    }
}

function getError(statusCode) {
    switch (statusCode) {
        case 400:
            return false;
        case 401:
            window.localStorage.clear();
            history.push('/');
            alert('Sessão expirada, por favor realize login novamente');
            return window.location.reload();
        case 403:
            return false;
        default: return statusCode;
    }
}