import { useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useSelector } from 'react-redux';
import {
    Drawer,
    Divider,
    IconButton,
    List,
    ListItemText,
    ListItem,
    ListItemIcon,
    Avatar,
    Typography,
    Box
} from "@material-ui/core";
import {
    Home,
    ChevronLeft,
    LocalParking,
    ExitToApp,
    Store,
    Loyalty,
    AccountCircle,
    Assignment
} from "@material-ui/icons";
import useStyles from "../hooks/useStyles";
import useColors from "../hooks/useColors";
import ListItemLink from "./ListItemLink";
import AlertModalDialog from './AlertModalDialog';
import './styles.css';
import Payment from "@material-ui/icons/Payment";

const AppDrawer = (props) => {
    const { user } = useSelector((state) => state.user);
    const classes = useStyles();
    const colors = useColors();
    const history = useHistory();
    const [modal, setModal] = useState({
        open: false,
        title: 'Confirme',
        text: 'Deseja encerrar sessão?',
    });

    useEffect(() => {
        if (!user.hasOwnProperty('id')) {
            window.localStorage.clear();
            history.push('/');
        }
    }, [user, history])

    function handleSignout() {
        window.localStorage.clear();
        history.push('/');
        setModal({ ...modal, open: false });
    }

    let color = colors.redDarkCS;

    if (user.id_access_group == 2)
        color = colors.redDarkCS;

    return (
        <Drawer
            id="drawer-menu"
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.open,
                [classes.drawerClose]: !props.open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                }),
            }}>

            <Box className={clsx({
                [classes.toolbarOpenDrawer]: props.open,
                [classes.toolbar]: !props.open,
            })}>
                <IconButton onClick={() => props.setOpen(false)}>
                    {props.theme.direction === 'ltr' && <ChevronLeft style={{ color: colors.redCS }} />}
                </IconButton>
            </Box>
            
            {props.open ? (
                <Box className={classes.openMyData} id="drawer-my-data">
                    <Avatar className="avatar">{user.hasOwnProperty('id') && user?.name.charAt(0)}</Avatar>
                    <Typography variant="h6" noWrap className={classes.userText}>{user.hasOwnProperty('id') && user.name}</Typography>
                    <Typography variant="subtitle2" noWrap className={classes.userText}>{user.hasOwnProperty('id') && user.email}</Typography>
                </Box>
            ) : (
                <Box className={classes.closeMyData} id="drawer-my-data">
                    <Avatar className="avatar">{user.hasOwnProperty('id') && user?.name.charAt(0)}</Avatar>
                </Box>
            )}
            
            <Divider />
            
            <List>
                <ListItemLink button icon={<Home style={{ color: color, marginLeft: 7 }} />} primary='Dashboard' to='/dashboard' />
                <ListItemLink button icon={<LocalParking style={{ color: color, marginLeft: 7 }} />} primary='Estacionamento' to='/parking' />
                <ListItemLink button icon={<Store style={{ color: color, marginLeft: 7 }} />} primary='Convênios' to='/licensing' />
            </List>

            <Divider />
            
            <List>
                <ListItemLink button icon={<Loyalty style={{ color: color, marginLeft: 7 }} />} primary='Mensalistas' to='/holder' />
                <ListItemLink button icon={<Payment style={{ color: color, marginLeft: 7 }} />} primary='Caixa' to='/cash' />
                <ListItemLink button icon={<Payment style={{ color: color, marginLeft: 7 }} />} primary='Placas Livres' to='/vehicleparkingfree' />
                <ListItemLink button icon={<Store style={{ color: color, marginLeft: 7 }} />} primary='Tipo de Convênio' to='/licensingtype' />
                <ListItemLink button icon={<AccountCircle style={{ color: color, marginLeft: 7 }} />} primary='Minha conta' to='/myaccount' />
            </List>
            
            <Divider />

            <List>
                <ListItem >
                    <ListItemIcon><Assignment style={{ color: color, marginLeft: 7 }} /></ListItemIcon>
                    <ListItemText primary='Relatórios' />
                </ListItem>

                <ListItemLink button icon={<LocalParking style={{ color: color, marginLeft: 7 }} />} primary='Estacionamentos' to='/reports' />                
            </List>

            <Divider />
            
            <List>
                <ListItem button onClick={() => { setModal({ ...modal, open: true }) }}>
                    <ListItemIcon><ExitToApp style={{ color: color, marginLeft: 7 }} /></ListItemIcon>
                    <ListItemText primary='Sair' />
                </ListItem>
            </List>

            <AlertModalDialog
                open={modal}
                setOpen={handleSignout}
                cancel={() => { setModal({ ...modal, open: false }) }}
            />
        </Drawer>
    );
}

export default AppDrawer;