import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Card,
    Box,
    Typography,
    MenuItem
} from '@material-ui/core';
import Loader from '../../App/components/Loader';
import MUIButton from '../../App/components/MUIButton';
import FormikTextInput from '../../App/components/FormikTextInput';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikSelect from '../../App/components/FormikSelect';
import GreatMask from '../../App/utils/GreatMask';
import { filterAllLicensings, filterMyLicensings } from '../licensingActions';
import './styles.css';


export default function LicensingHeader({ onNovoConvenio, onGetAllList }) {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const formik = useFormik({
        
        initialValues: {
            isMyLicense: true,
            name: '',
            cnpj: ''
        },
        onSubmit: values => {

            setLoading(true);

            if (values.isMyLicense) {

                dispatch(filterMyLicensings(values, (resp) => {

                    setLoading(false);

                    if (typeof resp !== 'boolean' || !resp) {

                        setModal({
                            open: true,
                            title: 'Aviso',
                            text: resp
                        })
                    }
                }))
            } 
            else {
                
                dispatch(filterAllLicensings(values, (resp) => {

                    setLoading(false);

                    if (typeof resp !== 'boolean' || !resp) {

                        setModal({
                            open: true,
                            title: 'Aviso',
                            text: resp
                        })
                    }
                }))
            }
        }
    });

    function handleGetAllList() {
        if (typeof onGetAllList === 'function') onGetAllList()
    }

    function functionNovoConvenio() {
        typeof onNovoConvenio === 'function' && onNovoConvenio();
    }

    return (
        <Card id="header-container">
            <Box className="title-content">
                <Typography variant="h4">Convênios</Typography>
                <Typography variant="h6">Busca, Alteração, Vincular e desvincular os convênios</Typography>
            </Box>
            <form
                onSubmit={formik.handleSubmit}
                className="form"
            >
                <Box className="parking-filter-inputs">
                    <FormikTextInput
                        label="Nome do Convênio"
                        name="name"
                        value={formik.values.name}
                        className="filter-itens"
                        onChange={(e) => formik.setFieldValue('name', e.target.value)}
                        error={formik.errors.name}
                    />
                    <FormikTextInput
                        label="CNPJ"
                        name="cnpj"
                        value={formik.values.cnpj}
                        className="filter-itens"
                        onChange={(e) => formik.setFieldValue('cnpj', GreatMask.cnpj(e.target.value))}
                        InputLabelProps={{ maxLength: 14 }}
                        error={formik.errors.cnpj}
                    />
                    <FormikSelect
                        label="Vinculados"
                        name="isMyLicense"
                        value={formik.values.isMyLicense}
                        onChange={formik.handleChange}
                        className="filter-itens"
                    >
                        <MenuItem value={true}>Sim</MenuItem>
                        <MenuItem value={false}>Não</MenuItem>
                    </FormikSelect>
                </Box>
                <Box className="parking-filter-buttons">
                    <MUIButton color="primary" className="filter-itens" type="submit">
                        Filtrar
                    </MUIButton>
                    <MUIButton color="primary" className="filter-itens" onClick={handleGetAllList}>
                        Listar Todos
                    </MUIButton>
                    <MUIButton color="primary" className="filter-itens" onClick={functionNovoConvenio}>
                        Cadastrar
                    </MUIButton>                    
                </Box>
            </form>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Card>
    );
}