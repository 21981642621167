import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import GreatMask from "../../App/utils/GreatMask";
import { parkingTypes } from '../../App/utils/typesEstablishment';

export function cashListPDF(cashs) {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    if (cashs.length <= 0) {

        alert('Nenhum registro disponivel, por favor gere uma nova lista');
        
        return;
    }

    const title = 'Relatório Consfor Estacionamentos';
    const headers = [
        [
            "Data Abertura",
            "Valor Abertura",
            "Data Fechamento",
            "Valor Fechamento",
            "Saldo"
        ]
    ];

    let valorTotal = 0;

    const data = cashs.map(register => {

        valorTotal = parseFloat(register['close_value']) - parseFloat(register['open_value']) + valorTotal;

        return [                        
            register.created_at,
            GreatMask.coinBR(register.open_value),
            register.closed_at,
            GreatMask.coinBR(register.close_value),
            GreatMask.coinBR(register.close_value - register.open_value)
        ]
    });

    let content = {
        startY: 50,
        head: headers,
        body: data,
        foot: [
            [`${cashs.length} registros`, '', '', '', GreatMask.coinBR(valorTotal)]
        ]
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    let pageCount = doc.internal.getNumberOfPages();

    doc.setPage(pageCount);

    doc.save("caixas.pdf")
}