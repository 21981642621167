export const LICENSING_SET = 'LICENSING_SET';
export const LICENSING_FILTER = 'LICENSING_FILTER';
export const LICENSING_CREATE = 'LICENSING_CREATE';
export const LICENSING_UPDATE = 'LICENSING_UPDATE';
export const LICENSING_CHANGE_ACTIVE = 'LICENSING_CHANGE_ACTIVE';
export const LICENSING_REMOVE_LICENSE = 'LICENSING_REMOVE_LICENSE';
export const LICENSING_RESET = 'LICENSING_RESET';

const INITIAL_STATE = {
    licensings: [],
    licensing: {},
    isMyLicenses: false,
}

export function licensingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LICENSING_SET:
            return {
                ...state,
                licensings: action.payload
            }
        case LICENSING_FILTER:
            return {
                ...state,
                licensings: action.payload
            }
        case LICENSING_CREATE:
            const newlicensing = [...state.licensing, action.payload]
            return {
                ...state,
                licensings: newlicensing
            }
        case LICENSING_UPDATE:
            let changedLicense = action.payload
            let updatedLicensings = state.licensings.map(item => {
                if (item.id === changedLicense.id)
                    return {
                        ...item,
                        name: changedLicense.name,
                        phone_number: changedLicense.phone_number,
                        address: changedLicense.address,
                        address_number: changedLicense.address_number
                    }

                return item
            })
            return {
                ...state,
                licensings: updatedLicensings
            }
        case LICENSING_CHANGE_ACTIVE:
            let changeActiveLicensings = state.licensings.map((item) => {
                if (item.id === action.payload.id)
                    return {
                        ...item,
                        active: action.payload.active
                    }
                return item
            })
            return {
                ...state,
                licensings: changeActiveLicensings
            }
        case LICENSING_REMOVE_LICENSE:
            let removedLicense = state.licensings.filter((item) => item.id_licensing !== action.payload.id_licensing)
            return {
                ...state,
                licensings: removedLicense
            }
        case LICENSING_RESET:
            return INITIAL_STATE
            
        default:
            return state
    }
}