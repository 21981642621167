import { combineReducers } from 'redux';

import { appReducer } from '../appReducers';
import { userReducer } from '../../MyAccount/userReducers';
import { cashReducer } from '../../Cash/cashReducers';
import { holderReducer } from '../../Holder/holderReducers';
import { parkingReducer } from '../../Parking/parkingReducers';
import { licensingReducer } from '../../Licensing/licensingReducer';
import { plateParkingFreeReducer } from '../../VehicleParkingFree/plateParkingFreeReducer';
import { licensingTypeReducer } from '../../LicensingType/licensingTypeReducer';
import { reportReducer } from '../../Reports/reportReducers';

const Reducers = combineReducers({
    app: appReducer,
    user: userReducer,
    cash: cashReducer,
    holder: holderReducer,
    parking: parkingReducer,
    licensing: licensingReducer,
    plateParkingFree: plateParkingFreeReducer,
    licensingType: licensingTypeReducer,
    report: reportReducer,
});

export default Reducers;