import { ApiService } from './services/ApiService';

export function checkPassword(holder, callback) {
    return dispatch => {
        ApiService.post('/authentication/check_password', {
            user_external_id: holder.externalId,
            password: holder.password
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }
            if (response.data?.success && typeof callback === 'function') {
                callback(true);
            }
            if (typeof callback === 'function')
                callback(response.data.message);
        })
    }
}

export function sendValidationCodeOnline(userExternalId, userLocalExternalId, callback) {
    return () => {
        ApiService.get(`/users​/local​/validation-code?user_local_external_id=${userLocalExternalId}&user_external_id=${userExternalId}`, true).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }
            if (response.data?.success && typeof callback === 'function') { 
                callback(true);
            }
            if (typeof callback === 'function')
                callback(response.data.message);
        })
    }
}