import { isValid, isFuture } from 'date-fns';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Loader from '../../App/components/Loader';
import MUIButton from '../../App/components/MUIButton';
import InputKeyboardDatePicker from '../../App/components/InputKeyboardDatePicker';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikSelect from '../../App/components/FormikSelect';
import { ParkingStatus } from './ParkingUtils';
import { ParkingType } from './ParkingType';
import { ReportType } from './ReportType';
import { filterParking } from '../reportActions';
import './styles.css';

export default function ParkingHeader({ onNewParking, licensings, onSetReportType }) {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const [reportType, setReportType] = useState("analitico")

    const formik = useFormik({

        initialValues: {

            initialDate: new Date(),
            finalDate: new Date(),
            id_licensing: '0',
            parking_type: 'rotativo',
            report_type: reportType,
        },
        validate: values => {

            const errors = {};
            
            if (!isValid(values.initialDate) || isFuture(values.initialDate)) {
                errors.initialDate = true;
            }
            
            if (!isValid(values.finalDate) || isFuture(values.finalDate)) {
                errors.finalDate = true;
            }
            
            return errors;
        },
        onSubmit: values => {
            
            setLoading(true);
            
            dispatch(filterParking({
                
                initialDate:    values.initialDate,
                finalDate:      values.finalDate,
                finished:       values.finished,
                id_licensing:   values.id_licensing,
                parking_type:   values.parking_type,

            }, (resp) => {
                
                if (typeof resp !== 'boolean' || !resp) {

                    setModal({
                        ...modal,
                        open: true,
                        title: 'Aviso',
                        text: resp
                    })
                }

                setLoading(false);

            }));
        },
    });

    // function handleNewParking() {
    //     typeof onNewParking === 'function' && onNewParking();
    // }

    function handleSetReportType (type) {

        setReportType(type)

        if (typeof onSetReportType === 'function') {
            onSetReportType(type)
        }
    }

    return (
        <Card id="header-container">
            <div className="title-content">
                <Typography variant="h4">Relatório de Estacionamentos</Typography>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="form"
            >
                <div className="parking-filter-inputs">
                    <InputKeyboardDatePicker
                        label="Data inicial"
                        name="initialDate"
                        value={formik.values.initialDate}
                        onChange={(date) => formik.setFieldValue('initialDate', date)}
                        className="filter-itens"
                    />
                    <InputKeyboardDatePicker
                        label="Data final"
                        name="finalDate"
                        value={formik.values.finalDate}
                        onChange={(date) => formik.setFieldValue('finalDate', date)}
                        className="filter-itens"
                    />

                    <FormikSelect
                        label="Convênio"
                        name="id_licensing"
                        value={formik.values.id_licensing}
                        className="filter-itens"
                        onChange={(e) => formik.setFieldValue('id_licensing', e.target.value)}>                                

                        {licensings?.map(item => <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>)}

                    </FormikSelect>

                    <FormikSelect
                        label="Tipo"
                        name="parking_type"
                        value={formik.values.parking_type}
                        onChange={(e) => formik.setFieldValue('parking_type', e.target.value)}
                        className="filter-itens">

                        {ParkingType.map(item => <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>)}

                    </FormikSelect>

                    <FormikSelect
                        label="Relatório"
                        name="report_type"
                        value={reportType}
                        onChange={(e) => handleSetReportType(e.target.value)}
                        className="filter-itens">

                        {ReportType.map(item => <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>)}
                    </FormikSelect>

                    <MUIButton color="primary" className="filter-itens" type="submit">
                        Filtrar
                    </MUIButton>

                </div>
            </form>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Card>
    );
}