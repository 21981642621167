import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '../App/services/ApiService';
import GreatMask from '../App/utils/GreatMask';
import { SET_HOLDER, SEARCH_HOLDER, ADD_HOLDER, HOLDER_PAYMENTS, CHANGE_HOLDER, HOLDER_PAYMENT_VALUE, HOLDER_CREATED_PAYMENT } from './holderReducers';


export function setHolders() {
    return dispatch => {
        ApiService.get(`/holders`).then((response) => {
            if (typeof response !== 'object') {
                return
            }
            if (response.data?.success) {
                dispatch({
                    type: SET_HOLDER,
                    payload: response.data.resultSet
                });
            }
            return
        })
    }
}

export function filterHolder(holder, callback) {
    const cpf = GreatMask.onlyNumber(holder.cpf)
    return dispatch => {
        ApiService.get(`/holders?cpf=${cpf}`).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao buscar os mensalistas.');
            }
            if (response?.data.success && typeof callback === 'function') {
                if (response.data.resultSet.length > 0) {
                    dispatch({
                        type: SET_HOLDER,
                        payload: response.data.resultSet
                    });
                    return callback(true)
                }
                return callback(response.data.message)
            }
            if (typeof callback === 'function')
                return callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })
    }
}

export function getHolder(cpf, callback) {
    return dispatch => {
        ApiService.get(`/users/by-cpf?cpf=${cpf}`).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao buscar os mensalistas.');
            }
            if (response?.data.success && typeof callback === 'function') {
                dispatch({
                    type: SEARCH_HOLDER,
                    payload: response.data.resultSet[0]
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })
    }
}

export function saveHolder(holder, callback) {

    console.log(holder)

    return

    let vehicles = holder.vehicles.map(item => {

        return {

            id_vehicle_type:    Number(item.id_vehicle_type),
            name:               item.name,
            plaque:             item.plaque,
            renavam:            item.renavam
        }
    })

    return dispatch => {

        ApiService.post('/holders/create', holder?.external_id !== ''
            
            ? {
                user_external_id:   holder.external_id,
                expiration_day:     holder.expirationDay,
                blocked:            holder.blocked,
                paid:               holder.paid,

                validation_code:    holder.validation_code,
                key_value:          holder.key_value,
                vehicles:           vehicles,
                address_number:     holder.addressNumber,
                zip_code:           holder.zip_code,
                complement:         holder.complement,
            }
            : {
                user_data: {

                    user: {
                        email:          holder.email,
                        phone_number:   GreatMask.onlyNumber(holder.phoneNumber),
                        password:       GreatMask.onlyNumber(holder.cpf)
                    },

                    person: {
                        name:       holder.name,
                        cpf:        GreatMask.onlyNumber(holder.cpf),
                        birthday:   GreatMask.dateBRtoUS(holder.birthday),
                    }
                },

                expiration_day:     holder.expirationDay,
                blocked:            holder.blocked,
                paid:               holder.paid,
                value:              holder.value,
                validation_code:    holder.validation_code,
                key_value:          holder.key_value,
                vehicles:           vehicles,
                address_number:     holder.addressNumber,
                zip_code:           holder.zip_code,
                complement:         holder.complement

            }).then((response) => {

                if (typeof response !== 'object' && typeof callback === 'function') {
                    return callback('Erro ao enviar os dados.');
                }
                
                if (response.data?.success && typeof callback === 'function') {

                    dispatch({
                        type: ADD_HOLDER,
                        payload: response.data.resultSet[0]
                    });

                    callback(true);
                }

                if (typeof callback === 'function')
                    callback(response.data.message);
            })
    }
}

export function getHolderPayments(idHolder, callback) {

    return dispatch => {

        ApiService.get(`/holders/payments?id_holder=${idHolder}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }
            
            if (response.data?.success && typeof callback === 'function') {

                if (response.data.resultSet.length > 0) {

                    const resp = response.data.resultSet
                    let today = new Date();

                    const lastPayment = resp.filter(item => (new Date(item.payment_date).getMonth() + 1 === today.getMonth() + 1)
                        && new Date(item.payment_date).getFullYear() === today.getFullYear())

                    if (lastPayment.length <= 0) {

                        resp.push({

                            id: uuidv4(),
                            id_holder: resp[resp.length - 1].id_holder,
                            payment_date: '',
                            value: resp[resp.length - 1].value,
                            paymentLate: true
                        })
                    }

                    dispatch({
                        type: HOLDER_PAYMENTS,
                        payload: resp
                    });
                    return callback(resp);
                }

                return callback(response.data.message)
            }
            if (typeof callback === 'function')
                callback(response.data.message);
        })
    }
}

export function getHolderPaymentsValue(callback) {
    return dispatch => {
        ApiService.get('/holders/value', true).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }
            if (response.data?.success && typeof callback === 'function') {
                dispatch({
                    type: HOLDER_PAYMENT_VALUE,
                    payload: response.data.resultSet[0]
                });
                
                return callback(true);
            }
            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function newPaymentHolder(holder, callback) {
    return dispatch => {
        ApiService.post('/holders/create-payment', {
            id_holder: holder.holder.id_holder.id,
            value: Number(holder.holder.value),
            payment_date: format(holder.payment_date, 'yyyy-MM-dd')
        }, true).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }
            if (response.data?.success && typeof callback === 'function') {
                const createdPayment = {
                    id: holder.holder.id,
                    id_holder: holder.holder.id_holder,
                    payment_date: response.data.resultSet[0].payment_date.substring(0, 10),
                    value: holder.holder.value
                }

                dispatch({
                    type: HOLDER_CREATED_PAYMENT,
                    payload: createdPayment
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function changeHolder(holder, holderId, callback) {
    return dispatch => {
        ApiService.put('/holders/update', {
            id_holder: String(holderId),
            expiration_day: Number(holder.expirationDay)
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }
            if (response.success && typeof callback === 'function') {
                dispatch({
                    type: CHANGE_HOLDER,
                    payload: response.resultSet[0]
                });

                callback(true);
            }
            if (typeof callback === 'function')
                callback(response.message);
        })
    }
}

export function changeBlockedHolder(holder, callback) {
    return dispatch => {
        // ApiService.put('/holders/change-blocked', {
        //     id_holder: 0
        // }).then((response) => {
        //     if (typeof response !== 'object' && typeof callback === 'function') {
        //         return callback('Erro ao enviar os dados.');
        //     }
        //     if (response.data?.success && typeof callback === 'function') {
        //         // dispatch({
        //         //     type: ADD_HOLDER,
        //         //     payload: response.data.resultSet
        //         // });

        //         callback(true);
        //     }
        //     if (typeof callback === 'function')
        //         callback(response.data.message);
        // })
    }
}