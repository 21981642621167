import 'date-fns';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MUIButton from '../../App/components/MUIButton';
import TextInput from '../../App/components/TextInput';
import GreatMask from '../../App/utils/GreatMask';
import './styles.css';

export default function NewCashDialog({ open, onConfirm, cancel }) {
    const [openValue, setOpenValue] = useState('');

    function handleConfirm() {
        if (openValue.trim !== '' && typeof onConfirm === 'function') {
            onConfirm(Number(openValue / 100));

            setOpenValue('');
        }
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    function formatDate() {
        return new Intl.DateTimeFormat('pt-BR').format(new Date())
    }

    function formatCurrency(value) {
        if (value.length > 2) {
            value = Number(value) / 100;
            value = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
            });
            return value
        }
        return value
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography variant="h5" className="dialog-title">Criar novo caixa</Typography>

                <div className="content-newcash">
                    <Typography variant="subtitle1" className="dialog-title">
                        Insira o valor que o caixa vai ser aberto.
                    </Typography>

                    <div className="input-content">
                        <TextInput
                            label="Data"
                            value={formatDate()}
                            className="filter-itens"
                            disabled
                        />

                        <TextInput
                            label="Valor"
                            value={formatCurrency(openValue)}
                            className="filter-itens"
                            onChange={(e) => setOpenValue(GreatMask.onlyNumber(e.target.value))}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <MUIButton onClick={handleClose} buttonType="red">
                    Cancelar
                </MUIButton>
                <MUIButton onClick={handleConfirm} buttonType="green">
                    Confirmar
                </MUIButton>
            </DialogActions>
        </Dialog>
    );
}