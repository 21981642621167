import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Loader from '../App/components/Loader';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import ParkingHeader from './components/ParkingHeader';
import ParkingTable from './components/ParkingTable';
import MUIButton from '../App/components/MUIButton';
import { parkingListPDF } from './reports/parkingListReports';
import { getLicensings } from './reportActions';
import Typography from '@material-ui/core/Typography';
import { parkingTypes } from '../App/utils/typesEstablishment';
import GreatMask from '../App/utils/GreatMask';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function Reports() {

    const { parking } = useSelector((state) => state.parking);
    const { licensings } = useSelector((state) => state.report);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [newParking, setNewParking] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const [reportType, setReportType] = useState("analitico")

    // console.log(licensings)

    const handleGetLicensings = useCallback(() => {

        setLoading(true)
        
        dispatch(getLicensings((resp) => {

            // setLicensings(resp)

            setLoading(false)
        }))

    }, [dispatch, modal])


    function handleGetLicensings2 () {

        setLoading(true)
        
        dispatch(getLicensings((resp) => {

            setLoading(false)
        }))
    }

    useEffect(() => {

        handleGetLicensings()

        // dispatch(getLicensings())

        setLoading(false)

        return () => { handleGetLicensings() }

    }, [handleGetLicensings])

    function onSetReportType (reportType) {

        setReportType(reportType)
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData) => {

        // https://blog.bitsrc.io/exporting-data-to-excel-with-react-6943d7775a92
        
        const fileName = "estacionamentos"

        let parkings = []
        
        /*for (let i = 0; i <= 25; i++) {

            custs.push({firstName: `first${i}`, lastName: `last${i}`,
            email: `abc${i}@gmail.com`, address: `000${i} street city, ST`, zipcode: `0000${i}`});
        }*/

        csvData.map((parking) => {

            if (reportType === 'analitico') {
            
                parkings.push( {

                    Tipo:                               parkingTypes[parking.id_parking_type],
                    Convenio:                           parking.licensing?.name,
                    Entrada:                            parking.arrival,
                    Finalizado:                         parking.finished_at,
                    Saida:                              parking.exit,
                    Placa:                              parking.plaque,
                    Ticket:                             parking.external_id,
                    Permanência:                        parking.time_elapsed,
                    Valor_Estacionamento_Hora_Inicial:  GreatMask.coinBR(parking.parking_value_initial_hour),
                    Valor_Estacionamento_Total:         GreatMask.coinBR(Number(parking.licensing_value_total) + Number(parking.client_value_total)),
                    Valor_Convênio_Hora_Inicial:        GreatMask.coinBR(parking.licensing_type_value_initial_hour),
                    Valor_Convênio_Hora_Adicional:      GreatMask.coinBR(parking.licensing_type_value_additional_hour),
                    Valor_Convênio_Hora:                GreatMask.coinBR(parking.licensing_type_value_licensing_value),
                    Valor_Convênio_Total:               GreatMask.coinBR(parking.licensing_value_total),
                    Valor_Cliente_Total:                GreatMask.coinBR(parking.client_value_total),
                })
            }
            else {

                parkings.push( {
                    
                    Convenio:                           parking.licensing?.name,
                    Qtde:                               parking.qtde,                    
                    Valor_Estacionamento_Total:         GreatMask.coinBR(parking.parking_value_total),
                    Valor_Convênio_Total:               GreatMask.coinBR(parking.licensing_value_total),
                    Valor_Cliente_Total:                GreatMask.coinBR(parking.client_value_total),
                })
            }
        });

        const ws            = XLSX.utils.json_to_sheet(parkings);
        const wb            = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer   = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data          = new Blob([excelBuffer], {type: fileType});
        
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    function getTableData() {

        if (parking.length === 0)
            return <MainWithoutData title="Lista dos veículos estacionados" />

        return (

            <MainWithData title="Lista dos veículos estacionados">

                <Typography variant="h6">Total: {parking.length}</Typography>

                <ParkingTable
                    resultSet={parking}
                    // stopParking={handleStopParking}
                    reportType={reportType}
                    exportToCSV={exportToCSV}
                />

            </MainWithData>)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ParkingHeader onNewParking={() => setNewParking(true)} licensings={licensings} onSetReportType={setReportType} />
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            
        </Grid>
    );
}