import React from "react";

const ParkingStatus = ({ finalizado }) => {

    const color = finalizado ? `rgba(9, 89, 30, 0.8)` : 'rgba(9, 40, 89, 0.8)'
    return (<span style={{
        border: 'none',
        borderRadius: 8,
        backgroundColor: color,
        color: 'white',
        padding: finalizado ? '6px 30px' :  '6px 16px',

    }}>{finalizado ? 'Finalizado' : 'Em andamento'}</span>)
}

export default ParkingStatus;