import axios from 'axios';
import StorageService from './StorageService';
import { baseURL } from './ApiService';

async function refresh() {
    
    const endpoint = baseURL + '/authentication/refresh';

    const refresh = localStorage.getItem('refresh');

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': refresh,
        }
    }
    return axios.get(endpoint, config).then(response => {
        return response
    }).catch(error => {
        return error
    });
}

let _retry = false;
let requestData
let respRefresh
export async function refreshInterceptor() {

    const endpoint = baseURL + '/authentication/refresh';
    
    // não executar nas rotas refresh, login, cadastro

    axios.interceptors.response.use(function (response) {

        return response

    }, async function (error) {

        const { config, response: { status } } = error;
        const originalRequest = config;

        if (endpoint !== originalRequest.url && status === 401 && !_retry && originalRequest) {

            _retry = true;

            requestData = originalRequest;

            respRefresh = await refresh();

            if (respRefresh.status !== 200) {
                return Promise.reject(error);
            }

            const retryOriginalRequest = new Promise((resolve) => {

                StorageService.add('token', respRefresh['data'].access_token);

                requestData.headers['Authorization'] = respRefresh['data'].access_token;

                resolve(axios(requestData));
            });
            
            _retry = false;

            return retryOriginalRequest;
        } 
        else {

            _retry = false;

            return Promise.reject(error);
        }
    });
}