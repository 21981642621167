import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core';
import Settings from "@material-ui/icons/Settings";
import { HolderStatus } from './HolderStatus';

const useStyles = makeStyles({
    table: {
        minWidth: 940,
    },
});

export default function HolderTable({ resultSet, makePayment, showHolder, changeHolder }) {
    const classes = useStyles();

    // function handleMakePayment(holder, close = () => { }) {
    //     if (typeof makePayment === 'function')
    //         makePayment(holder);
    //     close()
    // }

    function handleShowHolder(holder, close = () => { }) {
        if (typeof showHolder === 'function')
            showHolder(holder);
        close()
    }

    function handlePaymentHistory(holder, close = () => { }) {

        if (typeof changeHolder === 'function')
            changeHolder(holder);

        close()
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell align="center">Data Cadastrado</TableCell>
                        <TableCell align="center">Dia do vencimento</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Configuração</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultSet.map((holder) => (
                        <TableRow key={holder.id}>
                            <TableCell component="th" scope="row">{holder.user.name}</TableCell>
                            <TableCell align="center">{holder.created_at}</TableCell>
                            <TableCell align="center">{holder.expiration_day}</TableCell>
                            <TableCell align="center" padding="none">
                                <HolderStatus blocked={holder.blocked} />
                            </TableCell>
                            <TableCell align="center">
                                <PopupState variant="popover" popupId="demoMenu">
                                    {(popupState) => (
                                        <>
                                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                <Settings />
                                            </IconButton>
                                            <Menu {...bindMenu(popupState)}>
                                                {/* <MenuItem onClick={() => handleMakePayment(holder, popupState.close())}>
                                                    Pagar a mensalidade
                                                </MenuItem> */}
                                                <MenuItem onClick={() => handlePaymentHistory(holder, popupState.close())}>
                                                    Editar mensalista
                                                </MenuItem>
                                                <MenuItem onClick={() => handleShowHolder(holder, popupState.close())}>
                                                    Visualizar detalhes
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}