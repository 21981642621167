import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Loader from '../App/components/Loader';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import PlateParkingFreeHeader from './components/PlateParkingFreeHeader';
import PlateParkingFreeTable from './components/PlateParkingFreeTable';
import { setPlateParkingFree, changeActive } from './plateParkingFreeActions';
import FormNewPlateParkingFree from './components/FormNewPlateParkingFree';
import MUIButton from '../App/components/MUIButton';

export default function VehicleParkingFree() {

    const { platesParkingFree } = useSelector((state) => state.plateParkingFree);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [getNewPlateParkingFree, setGetNewPlateParkingFree] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(true);
    const [selectedPlateParkingFree, setSelectedPlateParkingFree] = useState({});
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const handleGetAllList = useCallback(() => {

        setLoading(true)

        dispatch(setPlateParkingFree((resp) => {

            if (typeof resp !== 'boolean' || !resp)

                setModal({
                    modal,
                    open: true,
                    text: resp
                })

            setLoading(false)
        }))

    }, [dispatch, modal])

    useEffect(() => {

        handleGetAllList()
        
        setLoading(false)

        return () => { handleGetAllList() }

    }, [handleGetAllList])

    function handleGetLicense(licensing) {

        setLoading(true)
        setGetNewPlateParkingFree(true)
        setLoading(false)
    }

    function handleChangeActive (vehicleParkingFree) {

        setLoading(true);

        dispatch(changeActive({

            id: vehicleParkingFree.id,
            active: vehicleParkingFree.active

        }, (resp) => {

            setLoading(false);

            if (typeof resp === 'boolean' && resp) {

                setLoading(false);
            }
        }));
    }

    function getTableData() {

        if (platesParkingFree.length === 0)
            return (
                <MainWithoutData
                    title="Lista das placas com livre acesso"
                    text="Pesquise uma placa listar os detalhes."
                />
            )

        return (
            <MainWithData title="Lista de placas com livre acesso">

                <PlateParkingFreeTable
                    changeActive={handleChangeActive}
                />
            </MainWithData>)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <PlateParkingFreeHeader onNewPlateParkingFree={() => setGetNewPlateParkingFree(true)} onGetAllList={handleGetAllList} />
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            {getNewPlateParkingFree && (
                <FormNewPlateParkingFree
                    open={getNewPlateParkingFree}
                    setOpen={() => setGetNewPlateParkingFree(false)}
                    id_establishment={user.id_establishment.id}
                />
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}