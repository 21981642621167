import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    Menu,
    IconButton,
    MenuItem
} from '@material-ui/core';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import FormikSelect from '../../App/components/FormikSelect';
import GreatMask from '../../App/utils/GreatMask';
import { createLicensingType, setLicensingType, deleteLicensingTypeValue } from '../licensingTypeActions';
import './styles.css';
import useStylesTable from '../../App/styles/useStylesTable'
import { PlateParkingFreeStatus } from './PlateParkingFreeStatus';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Settings } from "@material-ui/icons";

export default function LicensingType({ open, setOpen, id_establishment, onNewLicensingTypeValue, licensingType }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const classes = useStylesTable();

    const isEmpty = Object.keys(licensingType).length === 0

    const formik = useFormik({

        initialValues: {

            id:             isEmpty ? ''    : licensingType?.id,
            description:    isEmpty ? ''    : licensingType?.description,
            free_minutes:   isEmpty ? '0'   : licensingType?.free_minutes.toString(),
            grace_minutes:  isEmpty ? '0'   : licensingType?.grace_minutes.toString(),
            pay_total_time: isEmpty ? ''    : licensingType?.pay_total_time,
        },
        validate: values => {

            const errors = {};

            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            dispatch(createLicensingType(values, id_establishment, (resp) => {

                setModal({

                    open: true,
                    title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                    text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
                })

                if (typeof resp === 'boolean' && resp && typeof setOpen === 'function') {

                    dispatch(setLicensingType((resp) => {

                        if (typeof resp !== 'boolean' || !resp) {

                            setModal({
                                modal,
                                open: true,
                                text: resp
                            })
                        }

                        setLoading(false)
                    }))

                    setOpen();
                }

                setLoading(false);
            }));
        },
    });

    function handleCreateLicensingTypeValue () {

        typeof onNewLicensingTypeValue === 'function' && onNewLicensingTypeValue();
    }

    function handleDeleteLicensingTypeValue(licensingTypeValue) {

        setLoading(true);

        dispatch(deleteLicensingTypeValue(licensingType, licensingTypeValue, (resp) => {

            console.log(resp)

            setModal({

                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Excluído com sucesso!' : resp
            })

            setLoading(false);
        }));
    }

    function handleClose() {
        if (typeof setOpen === 'function') setOpen();
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md">

            <DialogContent>

                <Typography variant="h5" className="dialog-title">Novo Tipo de Convênio</Typography>

                <Box className="content-form">
                    
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form">

                        <Box className="half-div">

                            <FormikTextInput
                                label="Descrição"
                                name="description"
                                value={formik.values.description.toUpperCase()}
                                onChange={formik.handleChange}
                                className="half-input"
                                required/>
                        </Box>

                        <Box className="half-div">

                            <FormikSelect
                                label="Pagar Tempo Total"
                                name="pay_total_time" 
                                value={formik.values.pay_total_time}
                                onChange={(e) => formik.setFieldValue('pay_total_time', e.target.value)}
                                className="half-input">

                                <MenuItem value={true} >Sim</MenuItem>
                                <MenuItem value={false} >Não</MenuItem>

                            </FormikSelect>
                            
                        </Box>

                        <Box className="half-div">

                            <FormikTextInput
                                label="Tempo Livre"
                                name="free_minutes"
                                value={GreatMask.onlyNumber(formik.values.free_minutes)}
                                onChange={formik.handleChange}
                                InputLabelProps={{ maxLength: 3 }}
                                className="half-input" 
                                required/>


                            <FormikTextInput
                                label="Carência"
                                name="grace_minutes"
                                value={GreatMask.onlyNumber(formik.values.grace_minutes)}
                                onChange={formik.handleChange}
                                InputLabelProps={{ maxLength: 3 }}
                                className="half-input" 
                                required/>
                        </Box>

                        <Box className="button-content">
                            
                            <MUIButton
                                type="submit"
                                buttonType="green"
                                disabled={formik.isValid && formik.dirty ? false : true}>

                                Salvar
                            </MUIButton>
                        </Box>
                    </form>
                </Box>

                <Divider />


                <Box className="content-form">
                    
                    <Box className="form">
                    
                        <Typography variant="h6"> Valores do Convênio </Typography>

                        <Box className="button-content">
                            
                                    
                            <MUIButton
                                onClick={handleCreateLicensingTypeValue}
                                buttonType="green"
                                disabled={licensingType.id ? false : true}>
                                Inserir
                            </MUIButton>
                        </Box>

                        <TableContainer component={Paper}>
                        
                            <Table className={classes.table} aria-label="simple table">
                                
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Veículo</TableCell>
                                        <TableCell align="center">Valor Hora Inicial</TableCell>
                                        <TableCell align="center">Valor Hora Adicional</TableCell>
                                        <TableCell align="center">Valor Convênio</TableCell>
                                        <TableCell align="center">Opções</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {licensingType.licensingTypeValue?.map((licensingTypeValue) => {
                                        
                                        {/* console.log(licensingType) */}

                                        return (
                                            <TableRow key={licensingTypeValue?.id}>

                                                <TableCell align="justify" component="th" scope="row">
                                                    { licensingTypeValue?.vehicleType.name }
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    R$ { GreatMask.coinBR(licensingTypeValue.initial_hour_value) }
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    R$ { GreatMask.coinBR(licensingTypeValue?.additional_hour_value) }
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    R$ { GreatMask.coinBR(licensingTypeValue?.licensing_value) }
                                                </TableCell>
                                                
                                                <TableCell width={50}>

                                                    <PopupState variant="popover" popupId="demo-popup-menu">

                                                        {(popupState) => (
                                                            <>
                                                                <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                                    <Settings />
                                                                </IconButton>

                                                                <Menu {...bindMenu(popupState)}>
                                                                
                                                                    <MenuItem onClick={() => handleDeleteLicensingTypeValue(licensingTypeValue, popupState.close()) }>
                                                                        Excluir
                                                                    </MenuItem>
                                                                </Menu>
                                                            </>
                                                        )}
                                                    </PopupState>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider />

                        <Box className="button-content">
                                    
                            <MUIButton onClick={handleClose} buttonType="red">
                                Sair 
                            </MUIButton>
                        </Box>

                    </Box>
                </Box>
            </DialogContent>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })} />
                
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}