import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    TableCell,
    TableRow,
    Menu,
    IconButton,
    MenuItem
} from '@material-ui/core';
import { Settings } from "@material-ui/icons";
import GreatMask from '../../App/utils/GreatMask';
import { LicensingStatus } from './LicensingStatus';

export function TableWithLicense({ license, onRemoveLicense, onShowDetailOrChange }) {
    function isFreeParking(free) {
        return free ? 'Sim' : 'Não'
    }

    return (
        <TableRow key={license.id}>
            <TableCell align="justify" component="th" scope="row">
                {license.licensing.name}
            </TableCell>
            <TableCell align="center">
                {license.licensing.document}
            </TableCell>
            <TableCell align="center">
                {GreatMask.toPhone(license.licensing.phone_number)}
            </TableCell>
            <TableCell align="center">
                <LicensingStatus status={license.active} />
            </TableCell>
            <TableCell align="center">
                {isFreeParking(license.parking_free)}
            </TableCell>
            <TableCell align="center">
                {GreatMask.minutesToHoursMinutes(license.free_minutes)}
            </TableCell>
            <TableCell width={50}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                <Settings />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => {
                                    onRemoveLicense(license, true)
                                    popupState.close()
                                }}>
                                    Desvincular convênio
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    onShowDetailOrChange(license, false)
                                    popupState.close()
                                }}>
                                    Editar convênio
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    onShowDetailOrChange(license, true)
                                    popupState.close()
                                }}>
                                    Visualizar detalhes
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </PopupState>
            </TableCell>
        </TableRow>
    );
}