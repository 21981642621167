import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import aiImg from '../App/assets/loginImage.jpg';
import FormLogin from './components/FormLogin';
import './styles.css';

export default function Login() {

    useEffect(() => {
        window.localStorage.clear();
    }, [])

    return (
        <Grid container>
            <Hidden smDown >
                <Grid item xs={false} sm={false} md={6} lg={8} xl={8} className="img-container" >
                    <img src={aiImg} alt="Artificial Intelligence" />
                </Grid>
            </Hidden>

            <Grid item sm={12} md={6} lg={4} xl={4} className="form-container">
                <FormLogin />
            </Grid>
        </Grid>
    );
}