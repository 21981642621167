import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Card,
    Box,
    Typography,
    MenuItem
} from '@material-ui/core';
import Loader from '../../App/components/Loader';
import MUIButton from '../../App/components/MUIButton';
import FormikTextInput from '../../App/components/FormikTextInput';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikSelect from '../../App/components/FormikSelect';
import GreatMask from '../../App/utils/GreatMask';
import { filterAllPlateParkingFree, filterPlateParkingFree } from '../plateParkingFreeActions';
import './styles.css';


export default function PlateParkingFreeHeader({ onNewPlateParkingFree, onGetAllList }) {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const formik = useFormik({
        
        initialValues: {
            plate: '',
            active: true,
        },
        onSubmit: values => {

            setLoading(true);

            if (values.placa !== '') {

                dispatch(filterPlateParkingFree(values, (resp) => {

                    setLoading(false);

                    if (typeof resp !== 'boolean' || !resp) {

                        setModal({
                            open: true,
                            title: 'Aviso',
                            text: resp
                        })
                    }
                }))
            } 
            else {
                
                dispatch(filterAllPlateParkingFree(values, (resp) => {

                    setLoading(false);

                    if (typeof resp !== 'boolean' || !resp) {

                        setModal({
                            open: true,
                            title: 'Aviso',
                            text: resp
                        })
                    }
                }))
            }
        }
    });

    function handleGetAllList() {
        if (typeof onGetAllList === 'function') onGetAllList()
    }

    function handleNewPlateParkingFree() {
        typeof onNewPlateParkingFree === 'function' && onNewPlateParkingFree();
    }

    return (
        <Card id="header-container">
            
            <Box className="title-content">
                <Typography variant="h4">Placas Livres</Typography>
                <Typography variant="h6">Busca, Alteração, placas com acesso livre</Typography>
            </Box>

            <form
                onSubmit={formik.handleSubmit}
                className="form">

                <Box className="parking-filter-inputs">
                    <FormikTextInput
                        label="Placa"
                        name="plate"
                        value={formik.values.plate}
                        className="filter-itens"
                        onChange={(e) => formik.setFieldValue('plate', e.target.value)} />

                    <FormikSelect
                        label="Ativo"
                        name="active"
                        value={formik.values.active}
                        onChange={formik.handleChange}
                        className="filter-itens">

                        <MenuItem value={true}>Sim</MenuItem>
                        <MenuItem value={false}>Não</MenuItem>

                    </FormikSelect>
                </Box>
                <Box className="parking-filter-buttons">
                    <MUIButton color="primary" className="filter-itens" type="submit">
                        Filtrar
                    </MUIButton>
                    <MUIButton color="primary" className="filter-itens" onClick={handleGetAllList}>
                        Listar Todos
                    </MUIButton>
                    <MUIButton color="primary" className="filter-itens" onClick={handleNewPlateParkingFree}>
                        Cadastrar
                    </MUIButton>                    
                </Box>
            </form>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Card>
    );
}