import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    Typography,
    MenuItem,
    Box,
    Divider,
} from '@material-ui/core';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import FormikSelect from '../../App/components/FormikSelect';
import MUIButton from '../../App/components/MUIButton';
import GreatMask from '../../App/utils/GreatMask';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { changeLicensing } from '../licensingActions';
import './styles.css';

export default function LicensingShowDetail({ open, cancel, isShowDetail, license, parkingValues, licensingTypes }) {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const [descontoHoraAdicional, setDescontoHoraAdicional] = useState(license ? license?.desconto_hora_adicional : false);

    const [parkingValueMotoConvenioCliente, setParkingValueMotoConvenioCliente]     = useState(0);
    const [parkingValueCarroConvenioCliente, setParkingValueCarroConvenioCliente]   = useState(0);
    const [parkingValueVanConvenioCliente, setParkingValueVanConvenioCliente]       = useState(0);

    // console.log(parkingValues.value[1][1].value_additional_hour)
    // console.log(license?.value[1]?.value)

    // console.log(license)

    const formik = useFormik({

            initialValues: {
    
            id_licensing:                   license?.id,
            id_establishment:               license?.id_establishment,
            id_licensing_type:              license?.id_licensing_type,
            document:                       license?.document ?? '',
            name:                           license?.name ?? '',
            active:                         license ? license?.active : false,
            phone_number:                   license?.phone_number ? GreatMask.toPhone(license?.phone_number) : '',
            parking_free:                   license ? license?.licensingEstablishments[0]?.parking_free : false,
            free_minutes:                   license?.licensingEstablishments[0]?.free_minutes ?? '',
            zip_code:                       (license?.address && license?.address.zip_code) ?? '',
            address_number:                 license?.address_number ?? '',
            street:                         license?.address?.street ?? '',
            city:                           license?.address?.city.id_state.name ?? '',
            district:                       license?.address?.district ?? '',
            desconto_hora_adicional:        license ? license?.desconto_hora_adicional : false,

            parking_value_carro:            parkingValues?.value ? GreatMask.coinBR(parkingValues?.value[1][1].value_initial_hour.toFixed(2)) : 0,
            parking_value_additional_carro: parkingValues?.value ? GreatMask.coinBR(parkingValues?.value[1][1].value_additional_hour.toFixed(2)) : 0,
            parking_value_carro_convenio:   license?.value ? license?.value[1].value : 0,

            parking_value_moto:             parkingValues?.value ? GreatMask.coinBR(parkingValues?.value[1][2].value_initial_hour.toFixed(2)) : 0,
            parking_value_additional_moto:  parkingValues?.value ? GreatMask.coinBR(parkingValues?.value[1][2].value_additional_hour.toFixed(2)) : 0,
            parking_value_moto_convenio:    license?.value ? license?.value[2].value : 0,

            parking_value_van:              parkingValues?.value ? GreatMask.coinBR(parkingValues?.value[1][3].value_initial_hour.toFixed(2)) : 0,
            parking_value_additional_van:   parkingValues?.value ? GreatMask.coinBR(parkingValues?.value[1][3].value_additional_hour.toFixed(2)) : 0,
            parking_value_van_convenio:     license?.value ? license?.value[3].value : 0,

        },
        onSubmit: values => {

            setLoading(true);

            dispatch(changeLicensing(values, (resp) => {

                setLoading(false);

                setModal({
                    open: true,
                    title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                    text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
                })

                setIsEdit(false);
            }));
        },
    });

    useEffect(() => {
        setIsEdit(!isShowDetail)
    }, [isShowDetail])

    const searchZipcode = async (zipcode) => {

        setLoading(true);

        const json = await fetch(`https://viacep.com.br/ws/${zipcode}/json`).then(resp => resp.json());

        if (json.hasOwnProperty('erro')) {

            setLoading(false);

            return setModal({
                open: true,
                title: 'ERRO!',
                text: 'CEP não existe, informe o CEP corretamente.'
            })
        }

        await formik.setFieldValue('district', json.bairro)
        await formik.setFieldValue('city', json.localidade)
        await formik.setFieldValue('street', json.logradouro)

        setLoading(false);
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    function handleCheckValueParkingConvenio (parkingValue, txtParkingValueConvenio) {

        parkingValue                = Number(GreatMask.onlyNumber(parkingValue) / 100);

        let parkingValueConvenio    = Number(GreatMask.onlyNumber(txtParkingValueConvenio.value) / 100);

        if (parkingValueConvenio > parkingValue) {

            setModal({
                        modal,
                        open: true,
                        title: 'Aviso',
                        text: "Valor pago pelo convêniado não pode ser maior que o valor do estacionamento."
                    })

            return;
        }

        formik.setFieldValue(txtParkingValueConvenio.name, parkingValueConvenio);

        /*if (txtParkingValueConvenio.name === 'parking_value_moto_convenio' ) {


            setParkingValueMotoConvenioCliente(parkingValue - parkingValueConvenio);
        }

        if (txtParkingValueConvenio.name === 'parking_value_carro_convenio' ) {

            setParkingValueCarroConvenioCliente(parkingValue - parkingValueConvenio);
        }

        if (txtParkingValueConvenio.name === 'parking_value_van_convenio' ) {

            setParkingValueVanConvenioCliente(parkingValue - parkingValueConvenio);
        }*/
    }

    return (

        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md">

            <DialogContent>
                <Typography variant="h5" className="dialog-title">Detalhe do convênio</Typography>

                <Box className="content-form">
                    <Typography variant="subtitle1" className="dialog-title">
                        {isEdit ? 'Altere as informações do convênio' : 'Detalhes das informações do convênio'}
                    </Typography>

                    <form
                        onSubmit={formik.handleSubmit}
                        className="form">

                        <Box className="half-div">

                            <FormikSelect
                                label="Tipo de Convênio"
                                name="id_licensing_type"
                                value={formik.values.id_licensing_type}
                                className="half-input"
                                disabled={!isEdit}
                                onChange={(e) => formik.setFieldValue('id_licensing_type', e.target.value)}>                                

                                {licensingTypes.map(item => <MenuItem key={item.name} value={item.id}>{item.description}</MenuItem>)}

                            </FormikSelect>

                            <FormikSelect
                                label="Estacionamento gratuito"
                                name="parking_free"
                                value={formik.values.parking_free}
                                className="half-input"
                                error={formik.errors.parking_free}
                                disabled={!isEdit}>
                                
                                <MenuItem value={true} >Sim</MenuItem>
                                <MenuItem value={false} >Não</MenuItem>
                            </FormikSelect>
                        </Box>

                        <Box className="half-div">
                            <FormikTextInput
                                label="Número do documento"
                                name="document"
                                value={formik.values.document}
                                onChange={(e) => formik.setFieldValue('document', GreatMask.cnpj(e.target.value))}
                                className="half-input"
                                InputLabelProps={{ maxLength: 18 }}
                                disabled={!isEdit} />

                            <FormikTextInput
                                label="Nome"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className="half-input"
                                required
                                error={formik.errors.name}
                                disabled />
                        </Box>

                        <Box className="half-div">

                            <FormikSelect
                                label="Situação"
                                name="active"
                                value={formik.values.active}
                                onChange={formik.handleChange}
                                className="half-input"
                                required
                                disabled={!isEdit}>

                                <MenuItem value={true} >Ativo</MenuItem>
                                <MenuItem value={false} >Inativo</MenuItem>
                            </FormikSelect>

                            <FormikTextInput
                                label="Número do celular"
                                name="phone_number"
                                value={formik.values.phone_number}
                                onChange={(e) => formik.setFieldValue('phone_number', GreatMask.toPhone(e.target.value))}
                                className="half-input"
                                InputLabelProps={{ maxLength: 14 }}
                                required
                                error={formik.errors.phone_number}
                                disabled/>
                        </Box>

                        <Box className="half-div">
                            <FormikTextInput
                                label="CEP"
                                name="zip_code"
                                value={formik.values.zip_code}
                                onChange={(e) => {
                                    const zipcodeNumber = GreatMask.onlyNumber(e.target.value)
                                    formik.setFieldValue('zip_code', zipcodeNumber)
                                    zipcodeNumber.length === 8 && searchZipcode(zipcodeNumber)
                                }}
                                className="half-input"
                                required
                                error={formik.errors.zip_code}
                                InputLabelProps={{ maxLength: 8 }}
                                disabled={!isEdit}
                            />
                            <FormikTextInput
                                label="Número"
                                name="address_number"
                                value={formik.values.address_number}
                                onChange={formik.handleChange}
                                className="half-input"
                                required
                                error={formik.errors.address_number}
                                disabled={!isEdit}
                            />
                        </Box>
                        <Box className="full-div">
                            <FormikTextInput
                                label="Rua"
                                name="street"
                                value={formik.values.street}
                                className="full-input"
                                disabled
                            />
                        </Box>
                        <Box className="half-div">
                            <FormikTextInput
                                label="Bairro"
                                name="district"
                                value={formik.values.district}
                                className="half-input"
                                disabled
                            />
                            <FormikTextInput
                                label="Cidade"
                                name="city"
                                value={formik.values.city}
                                className="half-input"
                                disabled
                            />
                        </Box>

                        <Divider />

                        <Box className="button-content">
                            <MUIButton onClick={handleClose} buttonType="red">
                                Sair
                            </MUIButton>

                            {isEdit ? (
                                <MUIButton type="submit" buttonType="green">
                                    Alterar
                                </MUIButton>
                            ) : (
                                <MUIButton onClick={() => setIsEdit(true)} buttonType="green">
                                    Editar
                                </MUIButton>
                            )}
                        </Box>
                    </form>
                </Box>
            </DialogContent>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}