export const LICENSINGTYPE_SET              = 'LICENSINGTYPE_SET';
export const LICENSINGTYPE_FILTER           = 'LICENSINGTYPE_FILTER';
export const LICENSINGTYPE_CREATE           = 'LICENSINGTYPE_CREATE';
export const LICENSINGTYPE_UPDATE           = 'LICENSINGTYPE_UPDATE';
export const LICENSINGTYPE_CHANGE_ACTIVE    = 'LICENSINGTYPE_CHANGE_ACTIVE';
export const LICENSINGTYPE_VALUE_DELETE     = 'LICENSINGTYPE_VALUE_DELETE';
export const LICENSINGTYPE_VALUE_CREATE     = 'LICENSINGTYPE_VALUE_CREATE';

const INITIAL_STATE = {

    licensingTypes: [],
    licensingType: {},
}

export function licensingTypeReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case LICENSINGTYPE_SET:

            return {
                ...state,
                licensingTypes: action.payload
            }
        
        case LICENSINGTYPE_FILTER:

            return {
                ...state,
                licensingTypes: action.payload
            }

        case LICENSINGTYPE_CREATE:
            
            const newLicensingType = [...state.licensingType, action.payload]
            
            return {
                ...state,
                licensingType: newLicensingType
            }

        case LICENSINGTYPE_UPDATE:

            let changedPlateParkingFree = action.payload
            let updatedPlatesParkingFree = state.platesParkingFree.map(item => {

                if (item.id === changedPlateParkingFree.id) {

                    return {
                        ...item,
                        plate: changedPlateParkingFree.plate,
                        observation: changedPlateParkingFree.observation
                    }
                }

                return item
            })

            return {
                ...state,
                platesParkingFree: updatedPlatesParkingFree
            }

        case LICENSINGTYPE_CHANGE_ACTIVE:

            let changeActiveLicensingType = state.licensingTypes.map((item) => {

                if (item.id === action.payload.id)
                    return {
                        ...item,
                        active: action.payload.active
                    }

                return item
            })

            return {
                ...state,
                licensingTypes: changeActiveLicensingType
            }

        case LICENSINGTYPE_VALUE_CREATE:

            action.licensingType.licensingTypeValue.push(action.licensingTypeValue)

            return {
                ...state,
                licensingType: action.licensingType
            }

        case LICENSINGTYPE_VALUE_DELETE:

            let licensingType       = action.licensingType
            let licensingTypeValue  = action.licensingTypeValue

            licensingType.licensingTypeValue.map((item, index) => {

                if (item.id === licensingTypeValue.id) {

                    licensingType.licensingTypeValue.splice(index, 1)
                }
            })

            // console.log(licensingType)

            return {
                ...state,
                licensingType: licensingType
            }

        default:
            return state
    }
}