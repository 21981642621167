export const vehicleTypes = [
    { id_vehicle_type: "1", name: "CARRO" },
    { id_vehicle_type: "2", name: "MOTO" },
    { id_vehicle_type: "3", name: "CAMINHONETE/VAN" },
    // { id_vehicle_type: "4", name: "Ônibus" },
    // { id_vehicle_type: "5", name: "Caminhão" },
];

// icons
// icon: 'DirectionsCar'
// icon: 'Motorcycle'
// icon: 'LocalShipping'
// icon: 'DirectionsBus'