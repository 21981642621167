import { useState, useEffect } from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Settings from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@material-ui/core/Menu';
import { types } from '../../App/utils/typesCashRegister';
import ChangeValueDialog from './ChangeValueDialog';
import CloseCashDialog from './CloseCashDialog';
import GreatMask from '../../App/utils/GreatMask';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    }
});

export default function CashTable({ resultSet, openCash, closeCash, changeCash }) {

    const classes = useStyles();
    const [selectCash, setSelectCash] = useState({});
    const [newCash, setNewCash] = useState(false);
    const [closeValueCash, setCloseValueCash] = useState(false);
    const [totalSaldo, setTotalSaldo] = useState(0.00);

    useEffect((i) => {

        let x = 0

        resultSet.map((i, index) => {

            x +=  (i.close_value - i.open_value)

            setTotalSaldo(x)

        })
      });


    function handleOpenCash(cash, close = () => { }) {
        if (typeof openCash === 'function') openCash(cash);
        close();
    }

    function handleCloseCash(cash, close = () => { }) {
        setSelectCash(cash);
        setCloseValueCash(true);
        close();
    }

    function handleChangeCash(cash, close = () => { }) {
        setSelectCash(cash);
        setNewCash(true);
        close();
    }

    function handleConfirmChangeCash(value) {
        setSelectCash({});
        setNewCash(false);
        if (typeof changeCash === 'function') changeCash(value);
    }

    function handleCancelConfirmChangeCash() {
        setSelectCash({});
        setNewCash(false);
    }

    function handleConfirmCloseCash(value) {
        setSelectCash({});
        setCloseValueCash(false);
        if (typeof closeCash === 'function') closeCash(value);
    }

    function handleCancelConfirmCloseCash() {
        setSelectCash({});
        setCloseValueCash(false);
    }

    
    function getSaldo(cashOpenValue, cashCloseValue) {

        let saldo = Number(cashCloseValue) - Number(cashOpenValue);

        let total = 0.00;
        
        total += saldo;

        // console.log(total);

        // setTotalSaldo(total);

        return saldo;
    }


    function getTotal () {

        let result = 0;

        {resultSet.map((cash) => (

            result = cash.reduce((total, cash) => total = total + cash.close_value, 0)
           
        ))}

        console.log(result)

        return result;
    }

    return (
        <TableContainer component={Paper}>

            <Table className={classes.table} aria-label="simple table">

                <TableHead>

                    <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell align="right">Valor da abertura</TableCell>
                        <TableCell align="center">Data do fechamento</TableCell>
                        <TableCell align="right">Valor do fechamento</TableCell>
                        <TableCell align="center">Saldo</TableCell>
                    </TableRow>

                </TableHead>

                <TableBody>
                    {resultSet.map((cash) => (

                        <TableRow key={cash.id}>
                            <TableCell component="th" scope="row">{cash.created_at}</TableCell>
                            <TableCell align="right">{`R$ ${ GreatMask.coinBR(cash.open_value) }` }</TableCell>
                            <TableCell align="center">{cash.closed_at === '' ? 'EM ANDAMENTO' : cash.closed_at}</TableCell>
                            <TableCell align="right">{cash.close_value === '' ? 'EM ANDAMENTO' : `R$ ${ GreatMask.coinBR(cash.close_value) }` }</TableCell>          

                            { Number(cash.close_value - cash.open_value) < 0 ?

                                <TableCell align="center" style={{ color: '#FF5733'}} > {`R$ ${ GreatMask.coinBR(cash.close_value - cash.open_value) }`}</TableCell>  :

                                <TableCell align="center" style={{ color: '#0FB907'}} > {`R$ ${ GreatMask.coinBR(cash.close_value - cash.open_value) }`}</TableCell>  
                            }

                        </TableRow>

                    ))}
                </TableBody>

                <tfoot>
                <tr>
                    <th scope="row">Total</th>
                    
                    <td ></td>
                    <td ></td>
                    <td ></td>

                    { totalSaldo < 0 ?
                        <td align="center" style={{ color: '#FF5733'}} >{`R$ ${ GreatMask.coinBR(totalSaldo) }`}</td> :
                        <td align="center" style={{ color: '#0FB907'}} >{`R$ ${ GreatMask.coinBR(totalSaldo) }`}</td> 
                    }
                </tr>
            </tfoot>

            </Table>

            <ChangeValueDialog
                open={newCash}
                onConfirm={handleConfirmChangeCash}
                cancel={handleCancelConfirmChangeCash}
                data={selectCash}
            />

            <CloseCashDialog
                open={closeValueCash}
                onConfirm={handleConfirmCloseCash}
                cancel={handleCancelConfirmCloseCash}
                data={selectCash}
            />
        </TableContainer>
    );
}