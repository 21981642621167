import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Card,
    Box,
    Typography
} from '@material-ui/core';
import Loader from '../../App/components/Loader';
import MUIButton from '../../App/components/MUIButton';
import FormikTextInput from '../../App/components/FormikTextInput';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import GreatMask from '../../App/utils/GreatMask';
import Validation from '../../App/utils/Validation';
import { filterHolder } from '../holderActions';
import './styles.css';

export default function HolderHeader({ onNewHolder, onGetAllList }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });
    const formik = useFormik({
        initialValues: {
            cpf: '',
        },
        validate: values => {
            const errors = {};
            const cpf = GreatMask.onlyNumber(values.cpf)
            if (!Validation.cpf(cpf)) {
                errors.cpf = true;
            }
            return errors;
        },
        onSubmit: values => {
            setLoading(true);
            dispatch(filterHolder({
                cpf: values.cpf
            }, (resp) => {
                setLoading(false);
                if (typeof resp !== 'boolean' || !resp) {
                    setModal({
                        open: true,
                        title: 'Aviso',
                        text: resp
                    })
                }
            }))
        }
    });

    // function handleNewHolder() {
    //     typeof onNewHolder === 'function' && onNewHolder();
    // }

    function handleGetAllList() {
        if (typeof onGetAllList === 'function') onGetAllList()
    }

    return (
        <Card id="header-container">
            <Box className="title-content">
                <Typography variant="h4">Mensalista</Typography>
                <Typography variant="h6">Cadastro, Alteração, Busca e Pagamento dos Mensalistas</Typography>
            </Box>
            <form
                onSubmit={formik.handleSubmit}
                className="form"
            >
                <Box className="holder-filter-inputs">
                    <FormikTextInput
                        label="CPF"
                        name="cpf"
                        value={formik.values.cpf}
                        className="filter-itens"
                        onChange={(e) => formik.setFieldValue('cpf', GreatMask.cpf(e.target.value))}
                        InputLabelProps={{ maxLength: 14 }}
                        error={formik.errors.cpf}
                    />
                </Box>
                <Box className="holder-filter-buttons">
                    <MUIButton color="primary" className="filter-itens" type="submit">
                        Filtrar
                    </MUIButton>
                    <MUIButton color="primary" className="filter-itens" onClick={handleGetAllList}>
                        Listar Todos
                    </MUIButton>
                    {/* <MUIButton color="primary" className="filter-itens" onClick={handleNewHolder}>
                        Cadastrar
                    </MUIButton> */}
                </Box>
            </form>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Card>
    );
}