import { makeStyles } from "@material-ui/core/styles";

const useStylesTable = makeStyles((theme) => {
    return {
        table: {
            minWidth: 650,
        },
    }
});

export default useStylesTable;