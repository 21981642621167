import Validation from "./Validation";

const GreatMask = {
    onlyText: (value) => {
        return value.replace(/[^a-zA-Z-' ']/g, '');
    },
    onlyNumber: (value) => {
        return value.replace(/[^0-9]/g, '');
    },
    onlyTextAndNumber: (value) => {
        return value.replace(/[^a-zA-Z0-9-' '-@-.]/g, '')
    },
    toPhone: (value) => {
        return value.replace(/\D/g, "").replace(/^(\d{2})(\d)/g, "($1)$2").replace(/(\d{5})(\d)/, "$1-$2");
    },
    cep: (value = '') => {
        return value.replace(/\D/g, "").replace(/^(\d{5})(\d)/, "$1-$2");
    },
    cpf: (value) => {
        return value.replace(/\D/g, "").replace(/(\d{3})(\d)/, "$1.$2").replace(/(\d{3})(\d)/, "$1.$2").replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    },
    cnpj: (value) => {
        return value.replace(/\D/g, "").replace(/^(\d{2})(\d)/, "$1.$2").replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            .replace(/\.(\d{3})(\d)/, ".$1/$2").replace(/(\d{4})(\d)/, "$1-$2")
    },
    formatCurrencyBR: (value) => {
        value = Number(value) / 100;
        value = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL"
        });
        return value
    },
    formatCoinBR: (value) => {
        value = value + '';
        value = parseInt(value.replace(/[\D]+/g, ''));
        value = value + '';
        value = value.replace(/([0-9]{2})$/g, ",$1");

        if (value.length > 6) {
            value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }

        return value === 'NaN' ? '' : value;
    },
    coinBR: (value) => {
        return parseFloat(value).toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+,)/g, "$1.");
    },
    dateBR: (value) => {
        let newValue = GreatMask.onlyNumber(value).replace(/^(\d{2})(\d{2})(\d)/, "$1/$2/$3")

        return newValue;
    },
    dateUStoBR: (value) => {
        const year = value.substring(0, 4)
        const month = value.substring(5, 7);
        const day = value.substring(8);

        value = `${day}/${month}/${year}`;

        return value;
    },
    dateBRtoUS: (value) => {
        const day = value.substring(0, 2);
        const month = value.substring(3, 5);
        const year = value.substring(6);
        return `${year}-${month}-${day}`;
    },
    monthYear: (value) => {
        return value.replace(/\D/g, "").replace(/^(\d{2})(\d)/, "$1/$2");
    },
    monthDay: (value) => {
        const month = value.substring(5, 7);
        const day = value.substring(8, 10)

        value = `${day}/${month}`;

        return value;
    },
    hours: (value) => {
        return value.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{2})/, "$1:$2");
    },
    hourMinutes: (value) => {
        const hour = value.substring(11, 16);

        return hour;
    },
    minutesToHoursMinutes: (value) => {
        if(value === 0 || value === '') return 0;

        const hours = Math.floor(value / 60);
        const min = (value % 60);

        if (hours === 0) return `${min} min`;
        return `${hours} h ${min} min`;
    },
    toUpperCaseOnlyText: (value) => {
        let text = '';
        for (let i = 0; i < value.length; i++) {
            let character = value.charAt(i);
            let char = Validation.isCharacter(character) ? character.toLocaleUpperCase() : character
            text = `${text}${char}`
        }

        return text;
    },
    weekDay: () => {
        let day = new Date();
        let weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        return weekdays[day.getDay()];
    }
}

export default GreatMask;