import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from './App/services/HistoryService';
import AuthenticatedRouter from "./App/AuthenticatedRouter";
import { refreshInterceptor } from './App/services/RefreshService';
import Login from "./Login";
import Dashboard from "./Dashboard";
import Parking from "./Parking";
import Licensing from "./Licensing";
import MyAccount from "./MyAccount";
import VehicleParkingFree from "./VehicleParkingFree";
import LicensingType from "./LicensingType";
import Cash from "./Cash";
import Holder from "./Holder";
import Reports from "./Reports";
import { store, persistor } from './App/store';
import Loader from './App/components/Loader';
import './styles.css';

refreshInterceptor();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Loader loading={true} />} persistor={persistor}>
            <Router history={history}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <AuthenticatedRouter path="/dashboard" component={Dashboard} />
                        <AuthenticatedRouter path="/parking" component={Parking} />
                        <AuthenticatedRouter path="/licensing" component={Licensing} />
                        <AuthenticatedRouter path="/vehicleparkingfree" component={VehicleParkingFree} />
                        <AuthenticatedRouter path="/licensingtype" component={LicensingType} />
                        <AuthenticatedRouter path="/myaccount" component={MyAccount} />
                        <AuthenticatedRouter path="/cash" component={Cash} />
                        <AuthenticatedRouter path="/holder" component={Holder} />
                        <AuthenticatedRouter path="/reports" component={Reports} />
                        <Route path="*" component={() => <h1>Page not found</h1>} />
                    </Switch>
                </BrowserRouter>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);