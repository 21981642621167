import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmCashDialog({ open, onConfirm, cancel }) {
    const { cashConfirm } = useSelector((state) => state.cash);
    const { user } = useSelector((state) => state.user);

    function handleConfirm() {
        if (typeof onConfirm === 'function') onConfirm(cashConfirm);
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    const today = format(new Date(), 'dd-MM-yyyy')

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Confirmação de Caixa</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        align="justify"
                    >
                        O administrador nos informou que hoje(<b>{today}</b>) você <b>{user.name}</b>, irá iniciar seu caixa com o valor de <b>R${cashConfirm[0].open_value}</b>. <br></br>
                        Por favor, confira se o valor físico do caixa corresponde com o valor registrado no sistema. 
                        Caso esteja certo, clique em <b>"CONFIRMAR"</b>, porém se o valor não coincidir, 
                        clique em <b>"CANCELAR"</b> e entre em contato com o seu administrador para informar sobre a divergência.
                        <br></br><br></br><br></br>
                        <b>Aviso importante sobre está operação!</b>
                        <br></br><br></br>
                        * Caso confirme o caixa no sistema e o valor físico do caixa esteja errado, deverá prestar explicações ao administrador.<br></br>
                        * Você só poderá utilizar as funcionalidades do sistema após confirmar o caixa.<br></br>
                        * Esta operação é irreversível, ou seja, apoés confirmado não conseguirá editar o caixa para confirma-lo em outro momento.<br></br>
                        * Caso o valor não coincidir e depois de arrumar este problema, você poderá confirmar a abertura do caixa normalmente através da sua "DASHBOARD".
                    </DialogContentText>
                </DialogContent>
                <DialogActions id="dialog-action-term">
                    <Button onClick={handleClose} color="primary" id="dialog-action-term-recuse">
                        CANCELAR
                    </Button>
                    <Button onClick={handleConfirm} color="primary" id="dialog-action-term-accept">
                        CONFIRMAR
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}