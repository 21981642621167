import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIButton from '../../App/components/MUIButton';
import Loader from '../../App/components/Loader';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import { removeLicensing } from '../licensingActions';

export default function ConfirmRemoveLicenseDialog({ open, setOpen, selectedLicense }) {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: ''
    });
    
    useEffect(() => { 
        setLoading(false) 
    }, [])

    function handleConfirm() {

        setLoading(true);

        dispatch(removeLicensing({

            id_licensing: selectedLicense.id_licensing ?? selectedLicense.id,
            id_establishment: user.id_establishment.id

        }, (resp) => {

            setLoading(false);
            
            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Desvinculado com sucesso!' : resp
            })
        }));
    }

    function handleClose() {
        if (typeof setOpen === 'function') setOpen(false);
    }

    return loading ? (
        <Loader loading={loading} />
    ) : (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Confirmação</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" className="dialog-title">
                    Deseja desvincular com o convênio {selectedLicense.name} ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <MUIButton onClick={handleClose} buttonType="red">
                    Cancelar
                </MUIButton>
                <MUIButton onClick={handleConfirm} buttonType="green" autoFocus>
                    Confirmar
                </MUIButton>
            </DialogActions>

            <AlertModalDialog
                open={modal}
                setOpen={() => {
                    setModal({ ...modal, open: false })
                    handleClose()
                }}
            />
        </Dialog>
    );
}