import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import { Settings } from "@material-ui/icons";
import { IconButton, MenuItem } from "@material-ui/core";
import { parkingTypes } from '../../App/utils/typesEstablishment';
import GreatMask from '../../App/utils/GreatMask';
import ParkingStatus from "./ParkingStatus";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ParkingTable({ resultSet, stopParking, showDetailParking }) {
    const classes = useStyles();

    // function handleStopParking(parking, close = () => { }) {
    //     if (typeof stopParking === 'function')
    //         stopParking(parking);
    //     close()
    // }

    function handleShowDetailParking(parking, close = () => { }) {
        if (typeof showDetailParking === 'function')
            showDetailParking(parking);
        close()
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Tipo</TableCell>
                        <TableCell align="center">Data Entrada</TableCell>
                        <TableCell align="center">Placa</TableCell>
                        <TableCell align="center">Data Saída</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell align="center">Permanência</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell>Opções</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultSet.map((parking) => (
                        <TableRow key={parking.id}>
                            <TableCell>{parkingTypes[parking.id_parking_type]}</TableCell>
                            <TableCell align="center">{parking.arrival}</TableCell>
                            <TableCell align="center" component="th" scope="row">{parking.plaque}</TableCell>
                            <TableCell align="center">{parking.exit === '' ? 'Não contém' : parking.exit}</TableCell>
                            <TableCell width={185} align="center">
                                <ParkingStatus finalizado={parking.finished} />
                            </TableCell>
                            <TableCell align="center">
                                {GreatMask.minutesToHoursMinutes(parking.minutes_elapsed)}
                            </TableCell>
                            <TableCell align="right">R${parking.value}</TableCell>
                            <TableCell width={50}>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <>
                                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                <Settings />
                                            </IconButton>
                                            <Menu {...bindMenu(popupState)}>
                                                {/* {!parking.finished && (
                                                    <MenuItem onClick={() => handleStopParking(parking, popupState.close())}>
                                                        Finalizar Estacionamento
                                                    </MenuItem>
                                                )} */}
                                                <MenuItem onClick={() => handleShowDetailParking(parking, popupState.close())}>
                                                    Visualizar detalhes
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}