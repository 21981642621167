import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import { Settings } from "@material-ui/icons";
import { IconButton, MenuItem, Typography } from "@material-ui/core";
import { parkingTypes } from '../../App/utils/typesEstablishment';
import GreatMask from '../../App/utils/GreatMask';
import ParkingStatus from "./ParkingStatus";
import MUIButton from '../../App/components/MUIButton';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ParkingTable({ resultSet, stopParking, showDetailParking, reportType, exportToCSV }) {

    const classes = useStyles();

    let summaryReport

    let minutesElapsedTotal 
    let parkingValueTotal   = 0
    let licensingValueTotal = 0
    let clientValueTotal    = 0 

    if (reportType === 'sintetico') {

        summaryReport = resultSet.reduce(groupByParkings, []);

        // console.log(summaryReport)

        parkingValueTotal = summaryReport.reduce((accumulator, parking) => {

            return accumulator + Number(parking.licensing_value_total) + Number(parking.client_value_total);

        }, 0);

        licensingValueTotal = summaryReport.reduce((accumulator, parking) => {

            return accumulator + Number(parking.licensing_value_total);

        }, 0);

        clientValueTotal = summaryReport.reduce((accumulator, parking) => {

            return accumulator + Number(parking.client_value_total);

        }, 0);
    }
    else {

        minutesElapsedTotal = resultSet.reduce((accumulator, parking) => {

            return accumulator + parking.minutes_elapsed;

        }, 0);

        parkingValueTotal = resultSet.reduce((accumulator, parking) => {

            return accumulator + Number(parking.licensing_value_total) + Number(parking.client_value_total);

        }, 0);

        licensingValueTotal = resultSet.reduce((accumulator, parking) => {

            return accumulator + Number(parking.licensing_value_total);

        }, 0);

        clientValueTotal = resultSet.reduce((accumulator, parking) => {

            return accumulator + Number(parking.client_value_total);

        }, 0);
    }

    function handleShowDetailParking(parking, close = () => { }) {

        if (typeof showDetailParking === 'function')
            showDetailParking(parking);

        close()
    }

    function groupByParkings(newArr, line) {

        // console.log(line)

        let { id, id_licensing, licensing, parking_value_total, licensing_value_total, client_value_total, qtde=1 } = line;

        licensing_value_total   = Number(licensing_value_total)
        client_value_total      = Number(client_value_total)
        parking_value_total     = licensing_value_total + client_value_total

        
        const total = newArr.find(item => item.id_licensing === id_licensing);  

        if (total) {

            total.parking_value_total   += Number(licensing_value_total) + Number(client_value_total);
            total.licensing_value_total += Number(licensing_value_total);
            total.client_value_total    += Number(client_value_total);
            total.qtde                  += 1
        }
        else {

            const newTotal = { id, id_licensing, licensing, parking_value_total, licensing_value_total, client_value_total, qtde };
            
            newArr.push(newTotal);
        }
          
        return newArr;
    }

    function handleExportToCsv (type) {


        if (typeof exportToCSV === 'function') {

            if (reportType === 'analitico')
                exportToCSV(resultSet)
            else
                exportToCSV(summaryReport)
        }
    }

    function getComponents(reportType) {

        switch (reportType) {

            case "analitico": return (

                <Table className={classes.table} aria-label="simple table">

                    <TableHead>

                        <TableRow>

                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">
                                <MUIButton
                                buttonType="green"
                                onClick={handleExportToCsv}>
                                    Salvar                        
                                </MUIButton>
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell>Tipo</TableCell>
                            <TableCell align="center">Convênio</TableCell>
                            <TableCell align="center">Data Entrada</TableCell>
                            <TableCell align="center">Placa</TableCell>
                            <TableCell align="center">Data Saída</TableCell>
                            <TableCell align="center">Permanência</TableCell>
                            <TableCell align="right">Valor Estacionamento</TableCell>
                            <TableCell align="right">Valor Convênio</TableCell>
                            <TableCell align="right">Total Cliente</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {resultSet.map((parking) => (

                            <TableRow key={parking.id}>

                                <TableCell>{parkingTypes[parking.id_parking_type]}</TableCell>
                                <TableCell align="center">{parking.licensing?.name}</TableCell>
                                <TableCell align="center">{parking.arrival}</TableCell>
                                <TableCell align="center" component="th" scope="row">{parking.plaque}</TableCell>
                                <TableCell align="center">{parking.exit === '' ? 'Não contém' : parking.exit}</TableCell>                            
                                <TableCell align="center">
                                    {GreatMask.minutesToHoursMinutes(parking.minutes_elapsed)}
                                </TableCell>
                                <TableCell align="right">R$ { GreatMask.coinBR(Number(parking.licensing_value_total) + Number(parking.client_value_total)) }</TableCell>
                                <TableCell align="right">R$ { GreatMask.coinBR(parking.licensing_value_total) }</TableCell>
                                <TableCell align="right">R$ { GreatMask.coinBR(parking.client_value_total) }</TableCell>

                            </TableRow>
                        ))}

                        <TableRow>

                            <TableCell align="right">Totais</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="center">{GreatMask.minutesToHoursMinutes(minutesElapsedTotal)}</TableCell>
                            <TableCell align="right">R$ {GreatMask.coinBR(parkingValueTotal)}</TableCell>
                            <TableCell align="right">R$ {GreatMask.coinBR(licensingValueTotal)}</TableCell>
                            <TableCell align="right">R$ {GreatMask.coinBR(clientValueTotal)}</TableCell>

                        </TableRow>
                    </TableBody>

                </Table>
            )

            case "sintetico": return (

                <Table className={classes.table} aria-label="simple table">

                    <TableHead>

                        <TableRow>

                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">
                                <MUIButton
                                buttonType="green"
                                onClick={handleExportToCsv}>
                                    Salvar                        
                                </MUIButton>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Convênio</TableCell>
                            <TableCell align="center">Qtde</TableCell>
                            <TableCell align="right">Valor Total Estacionamento</TableCell>
                            <TableCell align="right">Valor Total Covênio</TableCell>
                            <TableCell align="right">Valor Total Cliente</TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>

                        {summaryReport.map((summary) => (

                            <TableRow key={summary.id}>
                                <TableCell align="left">{summary.licensing?.name}</TableCell>
                                <TableCell align="center">{summary.qtde}</TableCell>
                                <TableCell align="right">R$ { GreatMask.coinBR(Number(summary.parking_value_total)) }</TableCell>
                                <TableCell align="right">R$ { GreatMask.coinBR(summary.licensing_value_total) }</TableCell>
                                <TableCell align="right">R$ { GreatMask.coinBR(summary.client_value_total) }</TableCell>

                            </TableRow>
                        ))}

                        <TableRow>

                            <TableCell align="right">Totais</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">R$ {GreatMask.coinBR(parkingValueTotal)}</TableCell>
                            <TableCell align="right">R$ {GreatMask.coinBR(licensingValueTotal)}</TableCell>
                            <TableCell align="right">R$ {GreatMask.coinBR(clientValueTotal)}</TableCell>

                        </TableRow>

                    </TableBody>
                </Table>
            )

            default: return (
                <Typography variant="h5" className="dialog-title">Sem dados</Typography>
            )
        }
    }

    return (
        <TableContainer component={Paper}>

            {getComponents(reportType)}

        </TableContainer>
    );
}