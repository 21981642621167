import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import Loader from '../App/components/Loader';
import { firstAccess } from '../App/appActions';
import { setParking } from '../Parking/parkingActions';
import { getLicensings } from '../Reports/reportActions';
import { searchCash, openCash } from '../Cash/cashActions';
import SummaryHistoryParkingTable from './components/SummaryHistoryParkingTable';
import ConfirmCashDialog from './components/ConfirmCashDialog';
import ValuesWidget from './components/ValuesWidget';
import './styles.css';

export default function Dashboard() {
    const { user } = useSelector((state) => state.user);
    const { first } = useSelector((state) => state.app);
    const { parking } = useSelector((state) => state.parking);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [showConfirmCash, setShowConfirmCash] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {

        dispatch(setParking());

        if (user.id_access_group === 2 && first) {

            dispatch(searchCash((resp) => {
                
                if (resp)
                    setShowConfirmCash(resp);
            }));
        }

        setLoading(false);

    }, [user, first, dispatch])

    function handleConfirmCash(value) {

        setShowConfirmCash(false);

        setLoading(true);

        dispatch(firstAccess());

        dispatch(openCash(value, (resp) => {

            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Pronto ;)' : 'Ooops!',
                text: typeof resp === 'boolean' && resp ? 'Abertura do caixa realizado com sucesso!' : resp
            })
        }));

        setLoading(false);
    }

    function getTableData() {
        
        if (parking.length === 0)
            return <MainWithoutData title="Ultimos estacionamentos registrados" />

        return (
            <MainWithData title="Ultimos estacionamentos registrados">
                <SummaryHistoryParkingTable resultSet={parking} />
            </MainWithData>
        )
    }

    const enabledParking = parking.filter((item) => (item.finished === false));

    return (
        <div id="dashboard-container">
        
            <Grid container spacing={3} id="grid-container">
                <Grid item xs={12} xl={12} sm={6} md={6}>
                    <ValuesWidget title="Total Estacionamentos" values={parking.length} onClickButton='/parking' />
                </Grid>
                <Grid item xs={12} xl={12} sm={6} md={6}>
                    <ValuesWidget title="Estacionamentos em aberto" values={enabledParking.length} onClickButton='/parking' />
                </Grid>
            </Grid>

            {!loading ? getTableData() : (<Loader loading={loading} />)}

            {showConfirmCash && (
                <ConfirmCashDialog
                    open={showConfirmCash}
                    onConfirm={handleConfirmCash}
                    cancel={() => setShowConfirmCash(false)}
                />
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

        </div>
    );
}