import { format } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import GreatMask from '../App/utils/GreatMask';
import { PARKING_SET, LICENSINGS_SET } from './reportReducers';

export function setParking() {
    return dispatch => {

        const currentDate = format(new Date(), 'yyyy-MM-dd')

        ApiService.get(`/parking?initDate=${currentDate}&finalDate=${currentDate}`)
            .then((response) => {

                if (typeof response !== 'object') 
                    return

                if (response.data?.success) {

                    dispatch({

                        type: PARKING_SET,
                        payload: response.data.resultSet

                    });
                }
        })
    }
}

export function filterParking(data, callback) {

    const initialDate   = format(data.initialDate, 'yyyy-MM-dd');
    const finalDate     = format(data.finalDate, 'yyyy-MM-dd');

    return dispatch => {

        ApiService.get(`/parking?initDate=${initialDate}&finalDate=${finalDate}&finished=true&parking_type=${data.parking_type}&id_licensing=${data.id_licensing}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            
            if (response.data.success && typeof callback === 'function') {

                dispatch({

                    type: PARKING_SET,
                    payload: response.data.resultSet

                });

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function getLicensings() {

    return dispatch => {

        ApiService.get('/licensing')

            .then((response) => {

                
                if (typeof response !== 'object') {

                    return
                }

                if (response.data.success) {

                    dispatch({
                        type: LICENSINGS_SET,
                        payload: response.data.resultSet
                    });
                }
            })
    }
}