import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import GreatMask from "../../App/utils/GreatMask";
import { parkingTypes } from '../../App/utils/typesEstablishment';

export function parkingListPDF(parkings) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    if (parkings.length <= 0) {
        alert('Nenhum registro disponivel, por favor gere uma nova lista');
        return
    }

    const title = 'Relatório Consfor Estacionamentos';
    const headers = [
        [
            "Veiculo #Placa",
            "Tipo Estacionamento",
            "Convênio",
            "Entrada",
            "Saída",
            "Tempo na vaga",
            "Finalizado",
            "Valor"
        ]
    ];

    let valorTotal = 0;
    const data = parkings.map(register => {
        valorTotal = parseFloat(register['value']) + valorTotal;

        return [
            register.vehicle.hasOwnProperty('name') ? `${register.vehicle.name} #${register.plaque}` : `Sem Nome #${register.plaque}`,
            parkingTypes[register.id_parking_type],
            register.establishment.name,
            register.arrival,
            register.finished ? register.exit : 'Não contém',
            !register.finished ? 'Indisponível' : GreatMask.minutesToHoursMinutes(register.minutes_elapsed),
            register.finished ? 'Sim' : 'Não',
            GreatMask.coinBR(register['value'])
        ]
    });

    let content = {
        startY: 50,
        head: headers,
        body: data,
        foot: [
            [`${parkings.length} registros`, '', '', '', '', '', '', GreatMask.coinBR(valorTotal)]
        ]
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    let pageCount = doc.internal.getNumberOfPages();

    doc.setPage(pageCount);

    doc.save("parking-history.pdf")
}