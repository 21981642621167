import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LogoImg from '../assets/icon.png';
import './styles.css';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        flexDirection: 'column'
    },
    imgLogo: {
        borderRadius: '50%'
    }
}));

export default function Loader({ loading }) {
    const classes = useStyles();

    return (
        <div id="loader">
            <Backdrop className={classes.backdrop} open={loading}>
                <img src={LogoImg} alt="Logo" className={classes.imgLogo} />
                <Typography variant="subtitle1">Carregando...</Typography>
            </Backdrop>
        </div>
    );
}