import {
    Card,
    CardContent,
    Typography,
    Box
} from '@material-ui/core';
import MUIButton from '../../App/components/MUIButton';
import GreatMask from '../../App/utils/GreatMask';

export default function PaymentsCard({ payment, makePayment }) {
    function getRefPayment(date) {
        const paymentDate = date === '' ? new Date() : date;

        let month = new Date(paymentDate).getMonth() + 1;
        const year = new Date(paymentDate).getFullYear();


        return `${month < 10 ? `0${month}` : month} / ${year}`
    }

    function handleMakePayment() {
        if (typeof makePayment === 'function') {
            makePayment(payment)
        }
    }

    return (
        <Card className="payment-container">
            <CardContent style={{ padding: 0, margin: 0 }}>
                <Box className={payment?.paymentLate ? "late" : "updated"}>
                    <Typography variant="subtitle1">
                        Referencia: {getRefPayment(payment.payment_date)}
                    </Typography>
                </Box>
                <Box className="content">
                    {payment?.paymentLate ? (
                        <MUIButton
                            buttonType="green"
                            onClick={handleMakePayment}
                        >
                            Efetuar pagamento
                        </MUIButton>
                    ) : (
                        <>
                            <Typography variant="subtitle1">Pagamento realizado em</Typography>
                            <Typography variant="subtitle1">
                                {GreatMask.dateUStoBR(payment.payment_date)}
                            </Typography>
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}