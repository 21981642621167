import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Loader from '../App/components/Loader';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import LicensingHeader from './components/LicensingHeader';
import LicensingTable from './components/LicensingTable';
import { getOneLicensing, setLicensing, getEstablishmentParkingValues, getEstablishmentLicensingType } from './licensingActions';
import FormNewLicensing from './components/FormNewLicensing';
import FormNewLicensingEstablishment from './components/FormNewLicensingEstablishment';
import LicensingShowDetail from './components/LicensingShowDetail';
import ConfirmRemoveLicenseDialog from './components/ConfirmRemoveLicenseDialog';
import MUIButton from '../App/components/MUIButton';
import { licencingListPDF } from './reports/licencingListReports';

export default function Licensing() {

    const { licensings } = useSelector((state) => state.licensing);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [getNewLicense, setGetNewLicense] = useState(false);
    const [getNewLicenseEstablishment, setGetNewLicenseEstablishment] = useState(false);
    const [removeLicense, setRemoveLicense] = useState(false);
    const [showLicensingShowDetail, setShowLicensingShowDetail] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(true);
    const [selectedLicensing, setSelectedLicensing] = useState({});
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const [ parkingValues, setParkingValues ] = useState({});
    const [ licensingTypes, setLicensingTypes ] = useState({});

    // console.log(user.id_establishment.id)

    const handleGetAllList = useCallback(() => {

        setLoading(true)

        dispatch(setLicensing((resp) => {

            if (typeof resp !== 'boolean' || !resp)

                setModal({
                    modal,
                    open: true,
                    text: resp
                })

            setLoading(false)
        }))

    }, [dispatch, modal])

    useEffect(() => {

        handleGetAllList()
        
        setLoading(false)

        return () => { handleGetAllList() }

    }, [handleGetAllList])

    function handleGetLicense(licensing) {

        setLoading(true)
        setGetNewLicense(true)
        setSelectedLicensing(licensing)
        setLoading(false)
    }

    function handleGetLicenseEstablishment(licensing) {

        setLoading(true)
        setGetNewLicenseEstablishment(true)
        setSelectedLicensing(licensing)
        setLoading(false)
    }

    function handleRemoveLicense(licensing) {

        setLoading(true);
        setRemoveLicense(true)
        setSelectedLicensing(licensing)
        setLoading(false);
    }

    function handleShowDetailOrChange(licensing, isShow) {

        setLoading(true);

        // dispatch(getEstablishmentParkingValues(licensing.id_establishment, (resp) => {
        dispatch(getEstablishmentLicensingType(licensing.id_establishment, (resp) => {

            setLoading(false);

            if (typeof resp !== 'object') {

                setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })
            }

            // setParkingValues(resp)

            setLicensingTypes(resp)
            
            setIsShowDetail(isShow)
            setSelectedLicensing(licensing)
            setShowLicensingShowDetail(true)
        }))
    }

    async function handleShowDetailOrChangeMyLicense(licensing, isShow) {

        setLoading(true);

        dispatch(getOneLicensing({

            name: licensing.licensing.name,
            cnpj: licensing.licensing.document,

        }, (resp) => {

            setLoading(false);

            if (typeof resp !== 'object') {

                setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })
            }

            setIsShowDetail(isShow)
            setSelectedLicensing(resp)
            setShowLicensingShowDetail(true)
        }))
    }

    function getTableData() {

        if (licensings.length === 0)
            return (
                <MainWithoutData
                    title="Lista dos convênios registrados"
                    text="Pesquise um convênio para listar os detalhes."
                />
            )

        return (
            <MainWithData title="Lista dos convênios registrados">
                {!licensings[0].hasOwnProperty('licensingEstablishments') && (
                    <MUIButton
                        buttonType="green"
                        onClick={() => licencingListPDF(licensings)}>

                        Gerar PDF
                    </MUIButton>
                )}

                <LicensingTable
                    resultSet={licensings}
                    onGetLicenseEstablishment={handleGetLicenseEstablishment}
                    onRemoveLicense={handleRemoveLicense}
                    onShowDetailOrChange={handleShowDetailOrChange}
                    onShowDetailOrChangeMyLicense={handleShowDetailOrChangeMyLicense}
                />
            </MainWithData>)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <LicensingHeader onNovoConvenio={() => setGetNewLicense(true)} onGetAllList={handleGetAllList} />
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            {getNewLicense && (
                <FormNewLicensing
                    open={getNewLicense}
                    setOpen={() => setGetNewLicense(false)}
                    license={selectedLicensing}
                    id_establishment={user.id_establishment.id}
                />
            )}

            {getNewLicenseEstablishment && (
                <FormNewLicensingEstablishment
                    open={getNewLicenseEstablishment}
                    setOpen={() => setGetNewLicenseEstablishment(false)}
                    license={selectedLicensing}
                />
            )}

            {removeLicense && (
                <ConfirmRemoveLicenseDialog
                    open={removeLicense}
                    setOpen={() => setRemoveLicense(false)}
                    selectedLicense={selectedLicensing}
                />
            )}

            {showLicensingShowDetail && (
                <LicensingShowDetail
                    open={showLicensingShowDetail}
                    cancel={() => setShowLicensingShowDetail(false)}
                    isShowDetail={isShowDetail}
                    license={selectedLicensing}
                    parkingValues={parkingValues}
                    licensingTypes={licensingTypes}
                />
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}