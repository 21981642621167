export const CASH_SET = 'CASH_SET';
export const CASH_CREATE = 'CASH_CREATE';
export const CASH_OPEN = 'CASH_OPEN';
export const CASH_CLOSE = 'CASH_CLOSE';
export const CASH_CHANGE = 'CASH_CHANGE';
export const CASH_CONFIRM = 'CASH_CONFIRM';
export const CASH_RESET = 'CASH_RESET';

const INITIAL_STATE = {
    cash: [],
    cashConfirm: []
}

export function cashReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CASH_SET:
            return {
                ...state,
                cash: action.payload
            }
        case CASH_CREATE:
            let newCash = [...state.cash, action.payload]
            return {
                ...state,
                cash: newCash
            }
        case CASH_OPEN:
            let opensCashs = state.cash.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        id_status_cash_register: action.payload.id_status_cash_register,
                        id_user_confirmation: action.payload.id_user_confirmation
                    }
                }
                return item
            })
            return {
                ...state,
                cash: opensCashs
            }
        case CASH_CLOSE:
            let cashs = state.cash.filter(item => item.id !== action.payload.id)
            cashs.push(action.payload)
            return {
                ...state,
                cash: cashs
            }
        case CASH_CHANGE:
            let changedcashs = state.cash.filter(item => item.id !== action.payload.id)
            changedcashs.push(action.payload)
            return {
                ...state,
                cash: changedcashs
            }
        case CASH_CONFIRM:
            return {
                ...state,
                cashConfirm: action.payload
            }
        case CASH_RESET:
            return INITIAL_STATE
        default:
            return state
    }
}