import 'date-fns';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MUIButton from '../../App/components/MUIButton';
import TextInput from '../../App/components/TextInput';
import GreatMask from '../../App/utils/GreatMask';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import './styles.css';

export default function CloseCashDialog({ open, onConfirm, cancel, data }) {
    const [openValue, setOpenValue] = useState('');
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    function handleConfirm() {
        setModal({
            open: true,
            title: 'Aviso',
            text: `Tem certeza que deseja fechar o caixa?`
        })
    }

    function handleConfirmChangeValue() {
        if (typeof onConfirm === 'function') {
            onConfirm({
                id: data.id,
                value: Number(openValue / 100)
            });

            setOpenValue('');
        }
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    function formatCurrency(value) {
        if (value.length > 2) {
            value = Number(value) / 100;
            value = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
            });
            return value
        }
        return value
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Typography variant="h5" className="dialog-title">Fechamento do caixa</Typography>

                <div className="content-newcash">
                    <Typography variant="subtitle1" className="dialog-title">
                        {`Insira o valor do caixa do dia ${data.created_at}.`}
                    </Typography>

                    <div className="input-content">
                        <TextInput
                            label="Valor do caixa"
                            value={formatCurrency(openValue)}
                            onChange={(e) => setOpenValue(GreatMask.onlyNumber(e.target.value))}
                            className="full-input"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <MUIButton onClick={handleClose} buttonType="red">
                    Cancelar
                </MUIButton>
                <MUIButton onClick={handleConfirm} buttonType="green">
                    Confirmar
                </MUIButton>
            </DialogActions>

            <AlertModalDialog
                open={modal}
                setOpen={handleConfirmChangeValue}
                cancel={() => setModal({ ...modal, open: false })}
            />
        </Dialog>
    );
}