import { useSelector } from 'react-redux';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
    IconButton,
    MenuItem
} from '@material-ui/core';
import { Settings } from "@material-ui/icons";
import GreatMask from '../../App/utils/GreatMask';
import useStylesTable from '../../App/styles/useStylesTable'
import { PlateParkingFreeStatus } from './PlateParkingFreeStatus';

export default function LicensingTypeTable ({changeLicensingType, changeActive}) {
    
    const { licensingTypes } = useSelector((state) => state.licensingType);
    const classes = useStylesTable();

    function handleChangeLicensingType (licensingType, close = () => {}) {

        if (typeof changeLicensingType === 'function') {
            
            changeLicensingType(licensingType);            
        }

        close()
    }

    function handleChangeActive (licensingType, close = () => {}) {

        if (typeof changeActive === 'function') {
            
            changeActive(licensingType);            
        }

        close()
    }

    return (
        
        <TableContainer component={Paper}>
            
            <Table className={classes.table} aria-label="simple table">
                
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Descrição</TableCell>
                        <TableCell align="center">Tempo Livre</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell>Opções</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {licensingTypes.map((licensingType) => {

                    {/* console.log(licensingType) */}

                    return (
                        <TableRow key={licensingType.id}>

                            <TableCell align="justify" component="th" scope="row">
                                {licensingType.description}
                            </TableCell>

                            <TableCell align="center" component="th" scope="row">
                                {licensingType.free_minutes}
                            </TableCell>


                            <TableCell align="center">
                                <PlateParkingFreeStatus status={licensingType.active} />
                            </TableCell>

                            <TableCell width={50}>

                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <>
                                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                <Settings />
                                            </IconButton>

                                            <Menu {...bindMenu(popupState)}>
                                            
                                                <MenuItem onClick={() => handleChangeActive(licensingType, popupState.close()) }>
                                                    {licensingType.active ? 'Desativar' : 'Ativar' }
                                                </MenuItem>

                                                <MenuItem onClick={() => handleChangeLicensingType(licensingType, popupState.close()) }>
                                                    {licensingType.active ? 'Alterar' : 'Ativar' }
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </TableCell>
                        </TableRow>
                    )
                    })}

                </TableBody>
            </Table>
        </TableContainer>
    );
}