import { ApiService } from '../App/services/ApiService';
import { LICENSING_SET, LICENSING_UPDATE, LICENSING_CHANGE_ACTIVE, LICENSING_REMOVE_LICENSE } from './licensingReducer';
import GreatMask from '../App/utils/GreatMask';
import { SauronApi } from "../App/services/SauronApi";

export function setLicensing(callback) {

    return dispatch => {

        ApiService.get('/licensing')
            .then((response) => {

                if (typeof response !== 'object' && typeof callback === 'function') {
                    return callback(response);
                }

                if (response.data.success && typeof callback === 'function') {

                    dispatch({
                        type: LICENSING_SET,
                        payload: response.data.resultSet
                    });

                    return callback(true)
                }

                if (typeof callback === 'function')
                    return callback(response.data.message);
            })
    }
}

export function filterMyLicensings(licensing, callback) {

    return dispatch => {

        ApiService.get(`/licensing/licensing-establishments?name=${licensing.name}&document=${licensing.cnpj}&active=true`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {

                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {

                dispatch({

                    type: LICENSING_SET,
                    payload: response.data.resultSet

                });

                return callback(true);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);

        })
    }
}

export function filterAllLicensings(licensing, callback) {

    return dispatch => {

        ApiService.get(`/licensing?name=${licensing.name}&document=${licensing.cnpj}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {

                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {

                dispatch({

                    type: LICENSING_SET,
                    payload: response.data.resultSet

                });

                return callback(true);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);

        })
    }
}

export function getOneLicensing(licensing, callback) {

    return dispatch => {

        ApiService.get(`/licensing?name=${licensing.name}&document=${licensing.cnpj}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {
                return callback(response.data.resultSet[0]);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function getEstablishmentParkingValues(id_establishment, callback) {

    return dispatch => {

        ApiService.get('/parking/parking-values/'+id_establishment).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {
                return callback(response.data.resultSet[0]);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function getEstablishmentLicensingType(id_establishment, callback) {

    return dispatch => {

        ApiService.get('/licensing/licensing-type').then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {
                return callback(response.data.resultSet);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function createLicensing(data, id_establishment, callback) {

    return () => {
        
        ApiService.post('/licensing/create', {

            id_establishment:   id_establishment,
            name:               data.name,
            phone_number:       GreatMask.onlyNumber(data.phone_number),
            free_minutes:       data.free_minutes.toString(),
            parking_free:       data.parking_free,
            zip_code:           data.zip_code,
            address_number:     data.address_number,

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.data.success && typeof callback === 'function') {
                return callback(true)
                
            }

            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function createLicensingEstablishment(data, callback) {

    return () => {

        ApiService.post('/licensing/create/licensing-establishment', {

            id_licensing: data.id_licensing,
            free_minutes: data.free_minutes,
            parking_free: data.parking_free,

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.data.success && typeof callback === 'function') {
                return callback(true)
                
            }

            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function removeLicensing(data, callback) {

    return dispatch => {

        ApiService.patch('/licensing/change-active/licensing-establishment', {

            id_licensing: Number(data.id_licensing),
            id_establishment: Number(data.id_establishment)

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {

                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({

                    type: LICENSING_REMOVE_LICENSE,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}

export function changeLicensing(data, callback) {

    // console.log(data)

    const values = {

        value: {

            1: {
                value: Number(data.parking_value_carro_convenio.toString().replace(",", ".")),
            },

            2: {
                value: Number(data.parking_value_moto_convenio.toString().replace(",", ".")),
            },

            3: {
                value: Number(data.parking_value_van_convenio.toString().replace(",", ".")),
            },

        }
    }

    return dispatch => {

        ApiService.put('/licensing/licensing-establishment', {

            document:                   GreatMask.onlyNumber(data.document).toString(),
            id_licensing:               data.id_licensing,
            active:                     data.active,
            zip_code:                   data.zip_code,
            address_number:             data.address_number,
            parking_free:               data.parking_free,
            free_minutes:               data.free_minutes,
            value:                      values.value,
            desconto_hora_adicional:    data.desconto_hora_adicional,
            id_licensing_type:          data.id_licensing_type,

        }).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({

                    type: LICENSING_UPDATE,
                    payload: response.resultSet[0]
                });

                SauronApi.post('/exec', {

                    command:    'sync_licensing_list',
                    client_id:  data.id_establishment,

                    params: {
                        
                    },

                }).then((response) => {

                    // console.log(response)
                    
                })

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.message);
        })
    }
}

export function changeActiveLicensing(data, callback) {

    return dispatch => {

        ApiService.patch('/licensing/change-active', {

            id: String(data.id),
            active: !data.active

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({
                    type: LICENSING_CHANGE_ACTIVE,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}
