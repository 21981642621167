import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import Loader from '../App/components/Loader';
import AlertModalDialog from '../App/components/AlertModalDialog';
import HolderHeader from './components/HolderHeader';
import HolderTable from './components/HolderTable';
import NewHolderDialog from './components/NewHolderDialog';
import ShowHolderDetailDialog from './components/ShowHolderDetailDialog';
import { getHolder, setHolders } from './holderActions';
import PaymentsDialog from './components/PaymentsDialog';

export default function Holder() {
    const { holders } = useSelector((state) => state.holder);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [newHolder, setNewHolder] = useState(false);
    const [changeOrShowHolder, setChangeOrShowHolder] = useState(false);
    const [showPayments, setShowPayments] = useState(false);
    const [selectedHolder, setSelectedHolder] = useState({
        holder: {},
        change: false
    });
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    console.log(holders)

    useEffect(() => {
        setLoading(false);
        dispatch(setHolders(user.external_id));
    }, [dispatch, user])

    // function handleMakePayment(holder) {
    //     setLoading(true);
    //     dispatch(getHolderPayments(holder.id, (resp) => {
    //         setLoading(false);
    //         if (typeof resp === 'object') {
    //             setSelectedHolder({
    //                 ...selectedHolder,
    //                 holder
    //             })
    //             setShowPayments(true);
    //             return;
    //         }

    //         setModal({
    //             open: true,
    //             title: 'Aviso',
    //             text: resp
    //         })
    //     }))
    // }

    function handleChangeHolder(holder) {

        setLoading(true);

        dispatch(getHolder(holder.user.cpf, (resp) => {

            setLoading(false);

            if (typeof resp === 'boolean' && resp) {

                setSelectedHolder({
                    holder,
                    change: true
                })

                return setChangeOrShowHolder(true);
            }

            setModal({
                open: true,
                title: 'Aviso',
                text: resp
            })
        }));
    }

    function handleShowHolder(holder) {

        setLoading(true);

        dispatch(getHolder(holder.user.cpf, (resp) => {

            setLoading(false);

            if (typeof resp === 'boolean' && resp) {

                setSelectedHolder({
                    holder,
                    change: false
                })

                return setChangeOrShowHolder(true);
            }

            setModal({
                open: true,
                title: 'Aviso',
                text: resp
            })
        }));
    }

    function handleGetAllList() {

        setLoading(true)

        dispatch(setHolders(user.external_id));

        setLoading(false)
    }

    function getTableData() {

        if (holders.length === 0)
            return <MainWithoutData title="Lista dos mensalistas" />

        return (
            <MainWithData title="Lista dos mensalistas">
                <HolderTable
                    resultSet={holders}
                    // makePayment={handleMakePayment}
                    showHolder={handleShowHolder}
                    changeHolder={handleChangeHolder}
                />
            </MainWithData>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <HolderHeader 
                    onNewHolder={() => setNewHolder(true)} 
                    onGetAllList={handleGetAllList}
                />
            </Grid>
            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {newHolder && <NewHolderDialog
                open={newHolder}
                userExternalId={user.external_id}
                cancel={() => setNewHolder(false)}
            />}
            {changeOrShowHolder && <ShowHolderDetailDialog
                open={changeOrShowHolder}
                cancel={() => setChangeOrShowHolder(false)}
                selectedListHolder={selectedHolder}
            />}
            {showPayments && <PaymentsDialog
                open={showPayments}
                cancel={() => setShowPayments(false)}
                holder={selectedHolder.holder}
                userExternalId={user.external_id}
            />}
        </Grid>
    );
}