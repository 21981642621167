import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export default function FormikSelect({ ...props }) {
    const {
        idLabel,
        label,
        idSelect,
        name,
        value,
        onChange,
        variant = "outlined",
        className,
        required,
        children,
        disabled
    } = props;

    return (
        <FormControl variant={variant} className={className} required={required}>
            <InputLabel id={idLabel ? idLabel : `standard-required-name-${label.trim()}`}>
                {label}
            </InputLabel>
            <Select
                labelId={idLabel ? idLabel : `standard-required-name-${label.trim()}`}
                id={idSelect ? idSelect : `standard-required-name-${name.trim()}`}
                name={name}
                value={value}
                onChange={(value) => { onChange(value) }}
                label={label}
                disabled={disabled}
            >
                {children}
            </Select>
        </FormControl>
    )
}