import { isValid, isFuture } from 'date-fns';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Loader from '../../App/components/Loader';
import MUIButton from '../../App/components/MUIButton';
import InputKeyboardDatePicker from '../../App/components/InputKeyboardDatePicker';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikSelect from '../../App/components/FormikSelect';
import { ParkingStatus } from './ParkingUtils';
import { filterParking } from '../parkingActions';
import './styles.css';

export default function ParkingHeader({ onNewParking }) {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });
    const formik = useFormik({

        initialValues: {

            initialDate: new Date(),
            finalDate: new Date(),
            finished: 'Todos',
        },
        validate: values => {

            const errors = {};
            
            if (!isValid(values.initialDate) || isFuture(values.initialDate)) {
                errors.initialDate = true;
            }
            
            if (!isValid(values.finalDate) || isFuture(values.finalDate)) {
                errors.finalDate = true;
            }
            
            return errors;
        },
        onSubmit: values => {
            
            setLoading(true);
            
            dispatch(filterParking({
                
                initialDate: values.initialDate,
                finalDate: values.finalDate,
                finished: values.finished

            }, (resp) => {
                
                if (typeof resp !== 'boolean' || !resp) {

                    setModal({
                        ...modal,
                        open: true,
                        title: 'Aviso',
                        text: resp
                    })
                }

                setLoading(false);

            }));
        },
    });

    // function handleNewParking() {
    //     typeof onNewParking === 'function' && onNewParking();
    // }

    return (
        <Card id="header-container">
            <div className="title-content">
                <Typography variant="h4">Estacionamento</Typography>
                <Typography variant="h6">Buscar e visualizar detalhes dos veículos estacionados</Typography>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="form"
            >
                <div className="parking-filter-inputs">
                    <InputKeyboardDatePicker
                        label="Data inicial"
                        name="initialDate"
                        value={formik.values.initialDate}
                        onChange={(date) => formik.setFieldValue('initialDate', date)}
                        className="filter-itens"
                    />
                    <InputKeyboardDatePicker
                        label="Data final"
                        name="finalDate"
                        value={formik.values.finalDate}
                        onChange={(date) => formik.setFieldValue('finalDate', date)}
                        className="filter-itens"
                    />
                    <FormikSelect
                        label="Status"
                        name="finished"
                        value={formik.values.finished}
                        onChange={(e) => formik.setFieldValue('finished', e.target.value)}
                        className="filter-itens"
                    >
                        {ParkingStatus.map(item => <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>)}
                    </FormikSelect>
                </div>
                <div className="parking-filter-buttons">
                    <MUIButton color="primary" className="filter-itens" type="submit">
                        Filtrar
                    </MUIButton>
                    {/* <MUIButton color="primary" className="filter-itens" onClick={handleNewParking}>
                        Registrar veículo
                    </MUIButton> */}
                </div>
            </form>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Card>
    );
}