import { format } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import { CASH_SET, CASH_CREATE, CASH_OPEN, CASH_CLOSE, CASH_CHANGE, CASH_CONFIRM, CASH_RESET } from './cashReducers';

export function createCash(data, callback) {
    return dispatch => {
        ApiService.post('/cash-register/create', {
            open_value: data.openValue
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response?.data.success && typeof callback === 'function') {
                dispatch({
                    type: CASH_CREATE,
                    payload: response.data.resultSet[0]
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function openCash(data, callback) {
    return dispatch => {
        ApiService.patch('/cash-register/opening-confirmation', {
            id_cash_register: data.id
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response?.success && typeof callback === 'function') {
                dispatch({
                    type: CASH_OPEN,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}

export function changeCash(data, callback) {
    return dispatch => {
        ApiService.patch('/cash-register/change-value', {
            id_cash_register: data.id,
            open_value: data.value
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response?.success && typeof callback === 'function') {
                dispatch({
                    type: CASH_CHANGE,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}

export function closeCash(data, callback) {

    return dispatch => {

        ApiService.patch('/cash-register/closing', {

            id_cash_register: data.id,
            close_value: data.value

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({
                    type: CASH_CLOSE,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }
            
            if (typeof callback === 'function')
                callback(response.message);
        })
    }
}

export function listCash() {
    return dispatch => {
        ApiService.get('/cash-register').then((response) => {
            if (response.data?.success) {
                dispatch({
                    type: CASH_SET,
                    payload: response.data.resultSet
                });
            }
        })
    }
}

export function filterCash(date, callback) {
    const initialDate = format(date.initialDate, 'yyyy-MM-dd');
    const finalDate = format(date.finalDate, 'yyyy-MM-dd');

    return dispatch => {
        ApiService.get(`/cash-register?initDate=${initialDate}&finalDate=${finalDate}`).then((response) => {
            dispatch({
                type: CASH_RESET
            });

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response?.data.success && typeof callback === 'function') {
                dispatch({
                    type: CASH_SET,
                    payload: response.data.resultSet
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}
export function searchCash(callback) {
    const today = format(new Date(), 'yyyy-MM-dd');

    return dispatch => {
        ApiService.get(`/cash-register?created_at=${today}&id_status_cash_register=${1}`).then((response) => {
            dispatch({
                type: CASH_RESET
            });

            if (typeof response !== 'object') {
                return alert('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response?.data.success) {
                dispatch({
                    type: CASH_CONFIRM,
                    payload: response.data.resultSet
                });

                return response.data.resultSet.length > 0 ? false : true;
            }

            alert(response.data.message);
        })
    }
}