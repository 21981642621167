import { useEffect, useState } from 'react';
import TextInput from '../../App/components/TextInput';
import Loader from "../../App/components/Loader";
import GreatMask from '../../App/utils/GreatMask';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import './styles.css';

export default function MyDataTabPanel({ user }) {
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({
        name: '',
        cpf: '',
        birthday: '',
        cellphone: '',
        cellphone2: '',
        email: '',
        email2: '',
        genre_external_id: '',
        genre_other: ''
    });
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        if (user) {
            setForm({
                name: user.name,
                cpf: user?.cpf,
                birthday: user?.birthday,
                cellphone: String(user?.phone_number),
                email: user?.email,
                genre_external_id: user?.genre_external_id,
                genre_other: user?.genre_other
            })
        }

        setLoading(false);
    }, [user]);

    function getGenre(id) {
        switch (id) {
            case 'GNR_MASC': return 'Masculino';
            case 'GNR_FEMI': return 'Feminino';
            case 'GNR_OTHER': return 'Outros';
            default: return '';
        }
    }

    return !loading ? (
        <form className="form-content">
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            <TextInput
                label="Nome"
                value={form.name}
                className="textField"
                disabled
            />
            {form.genre_external_id && (
                <TextInput
                    label="Gênero"
                    value={getGenre(form.genre_external_id)}
                    className="textField"
                    disabled
                />
            )}
            {form.genre_external_id === 'GNR_OTHER' && (
                <TextInput
                    label="Gênero"
                    value={form.genre_other}
                    className="textField"
                    disabled
                />
            )}
            <TextInput
                label="CPF"
                value={GreatMask.cpf(form.cpf)}
                className="textField"
                disabled
            />
            <TextInput
                label="Data de nascimento"
                value={GreatMask.dateUStoBR(form.birthday)}
                className="textField"
                disabled
            />
            <TextInput
                label="Celular"
                value={GreatMask.toPhone(form.cellphone)}
                className="textField"
                disabled
            />
            <TextInput
                label="Email"
                value={form.email}
                className="textField"
                disabled
            />
        </form>
    ) : (
        <Loader loading={loading} />
    )
}