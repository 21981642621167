import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import FormikTextInputPassword from '../../App/components/FormikTextInputPassword';
import MUIButton from '../../App/components/MUIButton';
import { login } from '../LoginActions';
import './styles.css';

export default function FormLogin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: 'Ok, entendido'
    });
    const formik = useFormik({
        initialValues: {
            // username: 'porteiroprod@prod.com', 
            // username: 'admin1@teste.com',  USR60F9B3FF45524
            username: '',
            password: ''
        },
        validate: values => {
            const errors = {};
            if (!values.username || values.username.length < 5) {
                errors.username = true;
            }
            if (!values.password || values.password.length < 5) {
                errors.password = true;
            }
            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            dispatch(login(values.username, values.password, (resp) => {

                setLoading(false);
                
                if (typeof resp === 'boolean' && resp) {
                    return history.push('/dashboard');;
                }
    
                setModal({
                    ...modal,
                    open: true,
                    title: 'Aviso!',
                    text: resp
                });
            }));
        },
    });

    useEffect(() => {
        setLoading(false);
        return () => setLoading(false);
    }, []);

    function handleKeyPress(e) {
        if (e.key === 'Enter') formik.onSubmit()
    }

    return loading
        ? <Loader loading={loading} />
        : <div id="login-container">
            <Typography variant="h4">Consfor Estacionamentos</Typography>

            <form
                onSubmit={formik.handleSubmit}
                className="form"
            >
                <FormikTextInput
                    label="Usuário"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    className="form-itens"
                    required
                    error={formik.errors.username}
                />
                <FormikTextInputPassword
                    label="Senha"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    minLength={6}
                    className="form-itens"
                    required
                    error={formik.errors.password}
                    onKeyPress={(value) => handleKeyPress(value)}
                />
                <MUIButton
                    type="submit"
                    buttonType="green"
                    disabled={formik.isValid === true && formik.dirty === true ? false : true}
                    className="form-itens"
                >
                    ENTRAR
                </MUIButton>
            </form>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </div>
}