import { withStyles, makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const disabledStyles = {
    border: '1px solid #b1b1b1',
    backgroundColor: 'none',
    color: '#dbdbdb',
    '&:hover': {
        border: '1px solid #b1b1b1',
        color: '#fff',
        backgroundColor: '#dbdbdb',
    },
}


export default function MUIButton({ buttonType, ...props }) {
    const { children, disabled, className } = props;
    const classes = useStyles();
    const ButtonGreen = withStyles(() => ({
        root: disabled
            ? disabledStyles
            : {
                border: '1px solid #12af1a',
                backgroundColor: 'none',
                color: '#12af1a',
                '&:hover': {
                    border: '1px solid #21db2b',
                    color: '#fff',
                    backgroundColor: '#21db2b',
                },
            },
    }))(Button);

    const ButtonBlue = withStyles(() => ({
        root: disabled
            ? disabledStyles
            : {
                border: '1px solid #1283af',
                backgroundColor: 'none',
                color: '#1283af',
                '&:hover': {
                    border: '1px solid #189fd4',
                    color: '#fff',
                    backgroundColor: '#189fd4',
                },
            },
    }))(Button);

    const ButtonRed = withStyles(() => ({
        root: disabled
            ? disabledStyles
            : {
                border: '1px solid #af2a12',
                backgroundColor: 'none',
                color: '#d4361a',
                '&:hover': {
                    border: '1px solid #d4361a',
                    color: '#fff',
                    backgroundColor: '#d4361a',
                },
            },
    }))(Button);

    function getType(type) {
        switch (type) {
            case 'green': return (
                <ButtonGreen variant="outlined" disabled={disabled} className={clsx(classes.margin, className)} {...props}>
                    {children}
                </ButtonGreen>
            );
            case 'blue': return (
                <ButtonBlue variant="outlined" disabled={disabled} className={clsx(classes.margin, className)} {...props}>
                    {children}
                </ButtonBlue>
            );
            case 'red': return (
                <ButtonRed variant="outlined" disabled={disabled} className={clsx(classes.margin, className)} {...props}>
                    {children}
                </ButtonRed>
            );
            default: return (
                <Button variant="outlined" disabled={disabled} className={clsx(classes.margin, className)} {...props}>
                    {children}
                </Button>
            );
        }
    }

    return getType(buttonType);
}