import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Loader from '../../App/components/Loader';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import MUIButton from '../../App/components/MUIButton';
import { getHolderPaymentsValue, newPaymentHolder } from '../holderActions';
import PaymentsCard from './PaymentsCard';
import ConfirmPaymentDialog from './ConfirmPaymentDialog';
import './styles.css';

export default function PaymentsDialog({ open, cancel }) {
    const { holderPayments } = useSelector((state) => state.holder);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState({});
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        setLoading(false);
    }, [])

    function handleMakePayment(payment) {
        setLoading(true);
        dispatch(getHolderPaymentsValue((resp) => {
            setLoading(false);
            if (typeof resp === 'boolean' && resp) {
                setSelectedPayment(payment)
                setConfirmPayment(true);
                return
            }
            setModal({
                open: true,
                title: 'Aviso',
                text: resp
            })
        }))
    }

    function handleConfirmPayment(payment) {
        setLoading(true);
        setConfirmPayment(false);

        dispatch(newPaymentHolder(payment, (resp) => {
            setLoading(false);
            setModal({
                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: resp
            })
        }))
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullScreen
            id="payment-dialog"
        >
            <DialogContent>
                {!loading
                    ? (
                        <>
                            <Typography variant="h5" className="dialog-title">Pagamentos do Mensalista</Typography>

                            <Box component="div" className="content-payments">
                                <Grid container direction="row">
                                    {holderPayments.map(payment => {
                                        return (
                                            <PaymentsCard
                                                key={String(payment.id)}
                                                payment={payment}
                                                makePayment={handleMakePayment}
                                            />
                                        )
                                    })}
                                </Grid>
                                <Box component="div" className="button">
                                    <MUIButton onClick={handleClose} buttonType="blue">
                                        Fechar
                                    </MUIButton>
                                </Box>
                            </Box>
                        </>
                    )
                    : <Loader loading={loading} />
                }
            </DialogContent>
            {confirmPayment && <ConfirmPaymentDialog
                open={confirmPayment}
                selectedHolder={selectedPayment}
                onConfirm={handleConfirmPayment}
                cancel={() => setConfirmPayment(false)}
            />}
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Dialog>
    );
}