import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import GreatMask from "../../App/utils/GreatMask";

export function licencingListPDF(licensings) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    if (licensings.length <= 0) {
        alert('Nenhum registro disponivel, por favor gere uma nova lista');
        return
    }

    const title = 'Consfor Estacionamentos - Convênios cadastrados';
    const headers = [
        [
            "Nome",
            "Documento",
            "Telefone",
            "Livre",
            "Minutos livre",
            "Situação"
        ]
    ];

    const data = licensings.map(register => {
        return [
            register.licensing.name,
            register.licensing.document,
            GreatMask.toPhone(register.licensing.phone_number),
            register.parking_free ? 'Gratuito' : 'Pago',
            GreatMask.minutesToHoursMinutes(register.free_minutes),
            register.active ? 'Ativo' : 'Inativo',
        ]
    });

    let content = {
        startY: 50,
        head: headers,
        body: data,
        foot: [
            ['', '', '', '', '', `${licensings.length} Convênio (s)`]
        ]
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    let pageCount = doc.internal.getNumberOfPages();

    doc.setPage(pageCount);

    doc.save("licensings-list.pdf")
}