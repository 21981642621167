import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Loader from "../../App/components/Loader";
import AlertModalDialog from '../../App/components/AlertModalDialog';
import MyDataTabPanel from './MyDataTabPanel';
import ChangePasswordTabPanel from './ChangePasswordTabPanel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Card
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box p={3}>{children}</Box>)}
        </Card>
    );
}

function a11yProps(index) {
    
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function MyAccountTab() {
    const history = useHistory();
    const { user, address } = useSelector((state) => state.user);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        if (!user?.id) return history.push('/');
        setLoading(false);
    }, [user, address, history]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div id="my-account-tab-container">
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            <Paper id="mydata-options">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example">

                    <Tab label="Meus dados" {...a11yProps(0)} />
                    <Tab label="Alterar senha" {...a11yProps(1)} />
                </Tabs>
            </Paper>
            {!loading ? (
                <>
                    <TabPanel value={value} index={0} className="mydata-tab-panel">
                        <MyDataTabPanel user={user} />
                    </TabPanel>
                    
                    <TabPanel value={value} index={1} className="mydata-tab-panel">
                        <ChangePasswordTabPanel cpf={user?.cpf} username={user?.phone_number} />
                    </TabPanel>
                </>
            ) : (
                <Loader loading={loading} />
            )}
        </div>
    );
}
