import { ApiService } from '../App/services/ApiService';
import GreatMask from '../App/utils/GreatMask';
import {
    CHANGE_USER_EMAIL,
    CHANGE_USER_CELLPHONE,
    DELETE_USER_EMAIL,
    DELETE_USER_CELLPHONE,
    RESET_USER
} from './userReducers';

export function createEmailCellphone(newData, callback) {

    const phone = GreatMask.onlyNumber(String(newData.phone_number));

    return dispatch => {
        
        ApiService.post('/users/register/personal-data',
            newData.email === '' || newData.email === undefined ? {
                phone_number: phone
            } : {
                email: newData.email
            }).then((response) => {
                if (typeof response !== 'object' && typeof callback === 'function') {
                    return callback('Erro ao enviar os dados. Tente novamente');
                }
                if (response.data?.success && typeof callback === 'function') {
                    if (newData.email === '' || newData.email === undefined) {
                        dispatch({
                            type: CHANGE_USER_CELLPHONE,
                            payload: { phone_number: phone }
                        });
                    } else {
                        dispatch({
                            type: CHANGE_USER_EMAIL,
                            payload: { email: newData.email }
                        });
                    }
                    return callback(true);
                }
                if (typeof callback === 'function')
                    callback('Dados inconsistentes, confira os seus dados e tente novamente.');
            })

    }
}

export function deleteCellphone(deleteData, callback) {
    return dispatch => {
        ApiService.delete('/users/delete/personal-data', { phone_number: deleteData.phone_number }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Tente novamente');
            }
            if (response?.success && typeof callback === 'function') {
                dispatch({
                    type: DELETE_USER_CELLPHONE,
                    payload: deleteData
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })

    }
}

export function deleteEmail(deleteData, callback) {
    return dispatch => {
        ApiService.delete('/users/delete/personal-data', { email: deleteData.email }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Tente novamente');
            }
            if (response?.success && typeof callback === 'function') {
                dispatch({
                    type: DELETE_USER_EMAIL,
                    payload: deleteData
                });

                if (typeof callback === 'function')
                    callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })

    }
}

export function recoverPassword(user, callback) {

    return () => {

        ApiService.post('/authentication/forgot-password', {

            username: user

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {
                callback(true);
            }
            
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })
    }
}

export function changePassword(data, callback) {
    
    // console.log(data)

    return () => {

        ApiService.patch('/authentication/change-password', {

            password: data.password,
            cpf: data.cpf,
            check_code: data.code

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Tente novamente');
            }

            if (response.success && typeof callback === 'function') {
                callback(true);
            }
            
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira os seus dados e tente novamente.');
        })
    }
}

export function resetUser() {
    return dispatch => {
        dispatch({
            type: RESET_USER
        });
    }
}