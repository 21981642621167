import { useSelector } from 'react-redux';
import { 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box
 } from '@material-ui/core';
import MUIButton from '../../App/components/MUIButton';

export default function ConfirmPaymentDialog({ open, cancel, onConfirm, selectedHolder }) {
    const { holderPaymentValue } = useSelector((state) => state.holder);

    function handleConfirm() {
        if (typeof onConfirm === 'function') 
            onConfirm({
                holder: selectedHolder,
                payment_date: today
            });
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel(false);
    }

    const today = new Date();
    const todayMonth = today.getMonth() + 1

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Confirmação do pagamento</DialogTitle>
            <DialogContent>
                <Box className="content-confirm-payment">
                    <Typography variant="subtitle1" className="dialog-title">
                        Confirma o pagamento da mensalidade do mês
                    </Typography>
                    <Typography variant="h6" className="dialog-title">
                        {todayMonth},
                    </Typography>
                </Box>
                <Box className="content-confirm-payment">
                    <Typography variant="subtitle1" className="dialog-title">
                        no valor de R$
                    </Typography>
                    <Typography variant="h6" className="dialog-title">
                        {holderPaymentValue}
                    </Typography>
                    <Typography variant="subtitle1" className="dialog-title">
                        ?
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <MUIButton onClick={handleClose} buttonType="red">
                    Cancelar
                </MUIButton>
                <MUIButton onClick={handleConfirm} buttonType="green" autoFocus>
                    Confirmar
                </MUIButton>
            </DialogActions>
        </Dialog>
    );
}