import useColors from "../../App/hooks/useColors";

export const HolderStatus = ({ blocked }) => {

    const colors = useColors();

    const color = blocked ? colors.red : colors.greenCS

    return (
        <pre style={{
            border: 'none',
            borderRadius: 8,
            backgroundColor: color,
            color: 'white',
            padding: 4,
            margin: 0,
            fontFamily: "Arial"
        }}>
            {blocked ? 'Bloqueado' : 'Ativo'}
        </pre>
    )
}