import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from "../hooks/useStyles";

export default function AlertModalDialog({ open, setOpen, cancel }) {
    const classes = useStyles();

    function handleConfirm() {
        if (typeof setOpen === 'function') setOpen();
    }

    return (
        <Dialog
            open={open.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{open.title ? open.title : 'Aviso'}</DialogTitle>

            <DialogContent>
                <DialogContentText className={classes.dialogContent} >
                    <span>{open.text}</span>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                {typeof cancel === 'function' && (
                    <Button onClick={() => cancel()} color="secondary">
                        Cancelar
                    </Button>
                )}
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {typeof open.buttonConfirmText === 'string' && open.buttonConfirmText.length > 0
                        ? open.buttonConfirmText
                        : 'Confirmar'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}