export const PLATEPARKINGFREE_SET = 'PLATEPARKINGFREE_SET';
export const PLATEPARKINGFREE_FILTER = 'PLATEPARKINGFREE_FILTER';
export const PLATEPARKINGFREE_CREATE = 'PLATEPARKINGFREE_CREATE';
export const PLATEPARKINGFREE_UPDATE = 'PLATEPARKINGFREE_UPDATE';
export const PLATEPARKINGFREE_CHANGE_ACTIVE = 'PLATEPARKINGFREE_CHANGE_ACTIVE';

const INITIAL_STATE = {

    platesParkingFree: [],
    plateParkingFree: {},
}

export function plateParkingFreeReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case PLATEPARKINGFREE_SET:

            return {
                ...state,
                platesParkingFree: action.payload
            }
        
        case PLATEPARKINGFREE_FILTER:

            return {
                ...state,
                platesParkingFree: action.payload
            }

        case PLATEPARKINGFREE_CREATE:
            
            const newPlateParkingFree = [...state.plateParkingFree, action.payload]
            
            return {
                ...state,
                platesParkingFree: newPlateParkingFree
            }

        case PLATEPARKINGFREE_UPDATE:

            let changedPlateParkingFree = action.payload
            let updatedPlatesParkingFree = state.platesParkingFree.map(item => {

                if (item.id === changedPlateParkingFree.id) {

                    return {
                        ...item,
                        plate: changedPlateParkingFree.plate,
                        observation: changedPlateParkingFree.observation
                    }
                }

                return item
            })

            return {
                ...state,
                platesParkingFree: updatedPlatesParkingFree
            }

        case PLATEPARKINGFREE_CHANGE_ACTIVE:

            let changeActivePlatesParkingFree = state.platesParkingFree.map((item) => {

                if (item.id === action.payload.id)
                    return {
                        ...item,
                        active: action.payload.active
                    }

                return item
            })

            return {
                ...state,
                platesParkingFree: changeActivePlatesParkingFree
            }

        default:
            return state
    }
}