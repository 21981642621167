import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
    Dialog,
    DialogContent,
    Typography,
    MenuItem
} from '@material-ui/core';
import Loader from '../../App/components/Loader';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikTextInput from '../../App/components/FormikTextInput';
import FormikSelect from '../../App/components/FormikSelect';
import MUIButton from '../../App/components/MUIButton';
import GreatMask from '../../App/utils/GreatMask';
import { vehicleTypes } from '../../App/utils/typesVehicles';
import { changeHolder } from '../holderActions';
import './styles.css';

export default function ShowHolderDetailDialog({ open, cancel, selectedListHolder }) {
    const { holder } = useSelector((state) => state.holder);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });
    const formik = useFormik({
        initialValues: {
            external_id: holder?.external_id ?? '',
            email: holder?.email ?? '',
            phoneNumber: holder?.phone_number ?? '',
            name: holder?.name ?? '',
            cpf: holder?.cpf ?? '',
            birthday: holder?.formatedUserServiceResult.birthday ? GreatMask.dateUStoBR(holder.formatedUserServiceResult.birthday) : '',
            expirationDay: selectedListHolder?.holder.expiration_day ?? 1,
            blocked: selectedListHolder?.holder.blocked ?? true,

            vehicles: [{
                id: uuidv4(),
                plaque: '',
                id_vehicle_type: '1',
                renavam: '',
                name: '',
                photo: '',
            }],
            address: '',
            addressNumber: '',
            zipcode: '',
            number: '',
        },
        validate: values => {
            const errors = {};
            if (!values.expirationDay) {
                errors.expirationDay = true;
            }
            return errors;
        },
        onSubmit: values => {
            if (isEdit) {
                setLoading(true);
                dispatch(changeHolder(values, selectedListHolder?.holder.id, (resp) => {
                    setLoading(false);
                    if (typeof resp === 'boolean' && resp) {
                        return handleClose()
                    }

                    setModal({
                        open: true,
                        title: 'Aviso',
                        text: resp
                    })
                }));
            } else {
                setIsEdit(true)
            }
        },
    });
    const vehicleNewData = {
        id: uuidv4(),
        plaque: '',
        id_vehicle_type: '1',
        renavam: '',
        name: '',
        photo: '',
    }

    const existData = useCallback(async () => {
        async function searchZipcode() {
            const zipcode = String(holder.userAddress[0].address.zip_code);
            const json = await fetch(`https://viacep.com.br/ws/${zipcode}/json`).then(resp => resp.json());

            if (json.hasOwnProperty('erro')) {
                setLoading(false);
                return setModal({
                    open: true,
                    title: 'ERRO!',
                    text: 'CEP não existe, informe o CEP corretamente.'
                })
            }

            await formik.setFieldValue('zipcode', zipcode)
            await formik.setFieldValue('addressNumber', holder.userAddress[0].number)
            await formik.setFieldValue('address', json.logradouro)
            setLoading(false);
        }

        setIsEdit(selectedListHolder.change)

        if (holder.userAddress.length > 0) {
            searchZipcode()
        }

        await formik.setFieldValue('vehicles', holder.vehicles)
    }, [holder, selectedListHolder])

    useEffect(() => {
        existData();
        setLoading(false);

        return () => { existData() }
    }, [existData])

    function handleAddNewVehicle() {
        let newVehicle = formik.values.vehicles;
        newVehicle.push(vehicleNewData);
        formik.setFieldValue('vehicles', newVehicle);
    }

    function handleRemoveNewVehicle(vehicle) {
        if (formik.values.vehicles.length === 1)
            return formik.setFieldValue('vehicles', [vehicleNewData]);

        let filterData = formik.values.vehicles.filter(item => item.id !== vehicle.id);
        if (filterData.length === 0) {
            filterData = [vehicleNewData]
        }
        formik.setFieldValue('vehicles', filterData);
    }

    function handleSaveData(item, value, n) {
        const novo = formik.values.vehicles.map((i) => {
            if (i.id === item.id)
                return {
                    ...i,
                    [`${n}`]: value
                }
            return i
        })
        formik.setFieldValue('vehicles', novo);
    }

    function handleClose() {
        if (typeof cancel === 'function') cancel();
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent>
                {!loading
                    ? (<>
                        <Typography variant="h5" className="dialog-title">Detalhes dos dados do mensalista</Typography>

                        <div className="content-form-newholder">
                            <Typography variant="subtitle1" className="dialog-title">
                                {isEdit ? 'Altere as informações do mensalista' : 'Detalhes das informações do mensalista'}
                            </Typography>
                            <form
                                onSubmit={formik.handleSubmit}
                                className="form"
                            >
                                <div className="person">
                                    <Typography variant="h6" className="form-div-title">Dados pessoais</Typography>
                                    <FormikTextInput
                                        label="Nome"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        className="full-input"
                                        required
                                        error={formik.errors.name}
                                        disabled
                                    />
                                    <div className="half-div">
                                        <FormikTextInput
                                            label="Data de nascimento"
                                            name="birthday"
                                            value={formik.values.birthday}
                                            className='half-input'
                                            required
                                            disabled
                                        />
                                        <FormikTextInput
                                            label="CPF"
                                            name="cpf"
                                            value={formik.values.cpf}
                                            className='half-input'
                                            required
                                            disabled
                                        />
                                    </div>
                                    <div className="half-div">
                                        <FormikTextInput
                                            label="Celular"
                                            name="phoneNumber"
                                            value={formik.values.phoneNumber}
                                            className='half-input'
                                            required
                                            disabled
                                        />
                                        <FormikTextInput
                                            label="Email"
                                            name="email"
                                            value={formik.values.email}
                                            className="half-input"
                                            required
                                            disabled
                                        />
                                    </div>
                                    <div className="half-div">
                                        <FormikTextInput
                                            label="CEP"
                                            name="zipcode"
                                            value={formik.values.zipcode}
                                            className="half-input"
                                            required
                                            disabled
                                        />
                                        <FormikTextInput
                                            label="Número"
                                            name="addressNumber"
                                            value={formik.values.addressNumber}
                                            className="half-input"
                                            required
                                            disabled
                                        />
                                    </div>
                                    <FormikTextInput
                                        label="Endereço"
                                        name="address"
                                        value={formik.values.address}
                                        className="full-input"
                                        onChange={formik.handleChange}
                                        disabled
                                    />
                                </div>
                                <div className="payment">
                                    <Typography variant="h6" className="form-div-title">Dados do pagamento</Typography>
                                    <div className="half-div">
                                        <FormikTextInput
                                            label="Dia do vencimento"
                                            name="expirationDay"
                                            value={formik.values.expirationDay}
                                            className="half-input"
                                            onChange={(e) => {
                                                if (GreatMask.onlyNumber(e.target.value).length < 3 && Number(e.target.value) < 32)
                                                    formik.setFieldValue('expirationDay', GreatMask.onlyNumber(e.target.value))
                                            }}
                                            required
                                            error={formik.errors.expirationDay}
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>
                                <div className="Vehicle">
                                    <Typography variant="h6" className="form-div-title">Dados do veículo</Typography>
                                    {isEdit && (
                                        <div className="add-data">
                                            <MUIButton onClick={handleAddNewVehicle} color="primary">
                                                Adicionar veículo
                                            </MUIButton>
                                        </div>
                                    )}
                                    {formik.values.vehicles.map((item, index) => (
                                        <div key={item.id}>
                                            <div className="half-div">
                                                <FormikSelect
                                                    idSelect={`vehicle-select-vehicle-type-${item.index}`}
                                                    label="Tipo do veículo"
                                                    value={item.id_vehicle_type}
                                                    onChange={(e) => handleSaveData(item, e.target.value, 'id_vehicle_type')}
                                                    defaultValue="1"
                                                    className="half-input"
                                                    required
                                                    disabled={!isEdit || item.hasOwnProperty('created_at')}
                                                >
                                                    {vehicleTypes.map((item) => <MenuItem value={item.id_vehicle_type} key={item.id_vehicle_type} className="menu">
                                                        {`${item.name}`}
                                                    </MenuItem>
                                                    )}
                                                </FormikSelect>
                                                <FormikTextInput
                                                    id={`vehicle-name-${item.id}`}
                                                    label="Descrição breve do veículo"
                                                    value={item.name}
                                                    onChange={(e) => handleSaveData(item, e.target.value, 'name')}
                                                    className="half-input"
                                                    required
                                                    disabled={!isEdit || item.hasOwnProperty('created_at')}
                                                />
                                            </div>
                                            <div className="half-div">
                                                <FormikTextInput
                                                    id={`vehicle-plaque-${item.id}`}
                                                    label="Placa"
                                                    value={item.plaque}
                                                    onChange={(e) => handleSaveData(item, e.target.value, 'plaque')}
                                                    InputLabelProps={{ maxLength: 7 }}
                                                    className="half-input"
                                                    required
                                                    error={formik.errors.plaque}
                                                    disabled={!isEdit || item.hasOwnProperty('created_at')}
                                                />
                                                <FormikTextInput
                                                    id={`vehicle-renavam-${item.id}`}
                                                    label="Renavam"
                                                    value={item.renavam}
                                                    onChange={(e) => handleSaveData(item, e.target.value, 'renavam')}
                                                    InputLabelProps={{ maxLength: 11 }}
                                                    className="half-input"
                                                    required
                                                    error={formik.errors.renavam}
                                                    disabled={!isEdit || item.hasOwnProperty('created_at')}
                                                />
                                            </div>
                                            {isEdit && (
                                                <div className="add-data">
                                                    <MUIButton onClick={() => handleRemoveNewVehicle(item)} color="primary">
                                                        Excluir veículo
                                                    </MUIButton>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="button-content">
                                    <MUIButton onClick={handleClose} color="secondary">
                                        {isEdit ? 'Cancelar' : 'Fechar'}
                                    </MUIButton>
                                    <MUIButton
                                        type="submit"
                                        buttonType="green"
                                        disabled={formik.isValid === true ? false : true}
                                    >
                                        {isEdit ? 'Salvar' : 'Alterar'}
                                    </MUIButton>
                                </div>
                            </form>
                        </div>

                        <AlertModalDialog
                            open={modal}
                            setOpen={() => setModal({ ...modal, open: false })}
                        />
                    </>
                    )
                    : <Loader loading={loading} />
                }
            </DialogContent>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Dialog>
    );
}