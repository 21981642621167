import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Loader from '../App/components/Loader';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import LicensingTypeHeader from './components/LicensingTypeHeader';
import LicensingTypeTable from './components/LicensingTypeTable';
import { setLicensingType, changeActive } from './licensingTypeActions';
import FormNewLicensingType from './components/FormNewLicensingType';
import FormNewLicensingTypeValue from './components/FormNewLicensingTypeValue';
import MUIButton from '../App/components/MUIButton';

export default function LicensingType() {

    const { licensingTypes } = useSelector((state) => state.licensingType);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [getNewLicensingType, setGetNewLicensingType] = useState(false);
    const [newLicensingTypeValue, setNewLicensingTypeValue] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(true);
    const [selectedLicensingType, setSelectedLicensingType] = useState('');
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    const handleGetAllList = useCallback(() => {

        setLoading(true)

        dispatch(setLicensingType((resp) => {

            if (typeof resp !== 'boolean' || !resp)

                setModal({
                    modal,
                    open: true,
                    text: resp
                })

            setLoading(false)
        }))

    }, [dispatch, modal])

    useEffect(() => {

        handleGetAllList()
        
        setLoading(false)

        return () => { handleGetAllList() }

    }, [handleGetAllList])

    function handleGetLicense(licensing) {

        setLoading(true)
        setGetNewLicensingType(true)
        setLoading(false)
    }

    function handleNewLicensingType () {

        setSelectedLicensingType({})

        setGetNewLicensingType(true)
    }


    function handleChangeLicensingType (licensingType) {

        setSelectedLicensingType(licensingType)

        setGetNewLicensingType(true)
    }

    function handleNewLicensingTypeValue () {

        setNewLicensingTypeValue(true)
    }

    function handleChangeActive (vehicleParkingFree) {

        setLoading(true);

        dispatch(changeActive({

            id:     vehicleParkingFree.id,
            active: vehicleParkingFree.active

        }, (resp) => {

            setLoading(false);

            if (typeof resp === 'boolean' && resp) {

                setLoading(false);
            }
        }));
    }

    function getTableData() {

        if (licensingTypes.length === 0)

            return (
                <MainWithoutData
                    text="Pesquise uma descrição listar os detalhes."
                />
            )

        return (
            <MainWithData>

                <LicensingTypeTable
                    changeLicensingType={handleChangeLicensingType}
                    changeActive={handleChangeActive}
                />
            </MainWithData>)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <LicensingTypeHeader onNewLicensingType={() => handleNewLicensingType(true)} onGetAllList={handleGetAllList} />
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            {getNewLicensingType && (
                <FormNewLicensingType
                    open={getNewLicensingType}
                    setOpen={() => setGetNewLicensingType(false)}
                    id_establishment={user.id_establishment.id}                    
                    onNewLicensingTypeValue={() => handleNewLicensingTypeValue(true)}
                    licensingType={selectedLicensingType}
                />
            )}

            {newLicensingTypeValue && (
                <FormNewLicensingTypeValue
                    open={newLicensingTypeValue}
                    setOpen={() => setNewLicensingTypeValue(false)}
                    licensingType={selectedLicensingType}
                />
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}