import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Loader from '../App/components/Loader';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import ParkingHeader from './components/ParkingHeader';
import ParkingTable from './components/ParkingTable';
import FormNewParking from './components/FormNewParking';
// import { stopParking } from './parkingActions';
import MUIButton from '../App/components/MUIButton';
import { parkingListPDF } from './reports/parkingListReports';

export default function Parking() {
    const { parking } = useSelector((state) => state.parking);
    // const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [newParking, setNewParking] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
        buttonConfirmText: ''
    });

    useEffect(() => {
        setLoading(false)
    }, [])

    function handleSaveNewParking() {
        setNewParking(false);
        setLoading(false);
    }

    // function handleStopParking(vehicle) {
    //     setLoading(true);
    //     dispatch(stopParking(vehicle, (resp) => {
    //         setModal({
    //             ...modal,
    //             open: true,
    //             title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
    //             text: typeof resp === 'boolean' && resp ? 'Finalizado com sucesso!' : resp
    //         })
    //         setLoading(false);
    //     }));
    // }

    function getTableData() {
        if (parking.length === 0)
            return <MainWithoutData title="Lista dos veículos estacionados" />

        return (
            <MainWithData title="Lista dos veículos estacionados">
                <MUIButton
                    buttonType="green"
                    onClick={() => parkingListPDF(parking)}
                >
                    Gerar PDF
                </MUIButton>

                <ParkingTable
                    resultSet={parking}
                    // stopParking={handleStopParking}
                />
            </MainWithData>)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ParkingHeader onNewParking={() => setNewParking(true)} />
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {newParking && <FormNewParking
                open={newParking}
                cancel={() => setNewParking(false)}
                onConfirm={handleSaveNewParking}
                />}
        </Grid>
    );
}