import axios from 'axios';

export const baseURL = 'https://sauron-server.consforsistemas.com.br';

// axios.defaults.timeout = 3000;

const httpClient = axios.create();
// httpClient.defaults.timeout = 3000;
httpClient.defaults.baseURL = baseURL;


export const SauronApi = {

    post: async (uri, data) => {

        const endpoint = baseURL + uri;

        
        return httpClient.post(endpoint, data, {timeout: 3000}).then(response => {

            return response['data'];
            
        }).catch(error => {
            console.log(error);
        });
    }
}



