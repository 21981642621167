import useColors from "../../App/hooks/useColors";

export const LicensingStatus = ({ status }) => {
    const colors = useColors();

    return (
        <pre style={{
            border: 'none',
            borderRadius: 8,
            backgroundColor: status ? colors.greenCS : colors.red,
            color: '#FFF',
            padding: 4,
            margin: 0,
            fontFamily: "Arial"
        }}>
            {status ? 'Ativo' : 'Inativo'}
        </pre>
    )
}