import { ApiService } from '../App/services/ApiService';
import { PLATEPARKINGFREE_SET, PLATEPARKINGFREE_UPDATE, PLATEPARKINGFREE_CHANGE_ACTIVE } from './plateParkingFreeReducer';
import GreatMask from '../App/utils/GreatMask';

export function setPlateParkingFree(callback) {

    return dispatch => {

        ApiService.get('/vehicle/vehicle-parking-free')
            .then((response) => {

                if (typeof response !== 'object' && typeof callback === 'function') {
                    return callback(response);
                }

                if (response.data.success && typeof callback === 'function') {

                    dispatch({
                        type: PLATEPARKINGFREE_SET,
                        payload: response.data.resultSet
                    });

                    return callback(true)
                }

                if (typeof callback === 'function')
                    return callback(response.data.message);
            })
    }
}

export function filterPlateParkingFree(plaque, callback) {

    return dispatch => {

        ApiService.get(`/vehicle/vehicle-parking-free?plaque=${plaque.name}&active=true`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {

                dispatch({
                    type: PLATEPARKINGFREE_SET,
                    payload: response.data.resultSet
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);

        })
    }
}

export function filterAllPlateParkingFree(licensing, callback) {

    return dispatch => {

        ApiService.get(`/licensing?name=${licensing.name}&document=${licensing.cnpj}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response.data.success && typeof callback === 'function') {
                dispatch({
                    type: PLATEPARKINGFREE_SET,
                    payload: response.data.resultSet
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                return callback(response.data.message);

        })
    }
}


export function getEstablishmentParkingValues(id_establishment, callback) {

    return dispatch => {

        ApiService.get('/parking/parking-values/'+id_establishment).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {
                return callback(response.data.resultSet[0]);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function createPlateParkingFree(data, id_establishment, callback) {

    return () => {

        ApiService.post('/vehicle/create/vehicle-parking-free', {

            id_establishment:   id_establishment,
            plaque:             data.plate.toUpperCase(),
            renavam:            data.renavam,
            observation:        data.observation,
            permanent:          data.permanent,
            qty_day:            0,

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.data.success && typeof callback === 'function') {
                return callback(true)
                
            }

            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function changeActive(data, callback) {

    return dispatch => {

        ApiService.patch('/vehicle/vehicle-parking-free-change-active', {

            id: String(data.id),
            active: !data.active

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({
                    type: PLATEPARKINGFREE_CHANGE_ACTIVE,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}
