import { ApiService } from '../App/services/ApiService';
import { LICENSINGTYPE_SET, LICENSINGTYPE_UPDATE, LICENSINGTYPE_CHANGE_ACTIVE, LICENSINGTYPE_VALUE_CREATE, LICENSINGTYPE_VALUE_DELETE } from './licensingTypeReducer';
import GreatMask from '../App/utils/GreatMask';
import { SauronApi } from "../App/services/SauronApi";

export function setLicensingType(callback) {

    return dispatch => {

        ApiService.get('/licensing/licensing-type')

            .then((response) => {  

                if (typeof response !== 'object' && typeof callback === 'function') {
                    return callback(response);
                }

                if (response.data.success && typeof callback === 'function') {

                    dispatch({
                        type: LICENSINGTYPE_SET,
                        payload: response.data.resultSet
                    });

                    return callback(true)
                }

                if (typeof callback === 'function')
                    return callback(response.data.message);
            })
    }
}

export function filterLicensingType(params, callback) {

    return dispatch => {

        ApiService.get(`/licensing/licensing-type?description=${params.description}&active=true`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {

                dispatch({
                    type: LICENSINGTYPE_SET,
                    payload: response.data.resultSet
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);

        })
    }
}

export function filterAllLicensingTypes(licensing, callback) {

    return dispatch => {

        ApiService.get(`/licensing?name=${licensing.name}&document=${licensing.cnpj}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response.data.success && typeof callback === 'function') {
                dispatch({
                    type: LICENSINGTYPE_SET,
                    payload: response.data.resultSet
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                return callback(response.data.message);

        })
    }
}


export function getEstablishmentParkingValues(id_establishment, callback) {

    return dispatch => {

        ApiService.get('/parking/parking-values/'+id_establishment).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {
                return callback(response.data.resultSet[0]);
            }

            if (typeof callback === 'function')
                return callback(response.data.message);
        })
    }
}

export function createLicensingType(data, id_establishment, callback) {
    
    // console.log(data) 
    // return
    
    return () => {

        ApiService.post('/licensing/licensing-type-create', {

            id:                 data?.id ? data.id : null,
            id_establishment:   id_establishment,
            description:        data.description.toUpperCase(),
            free_minutes:       Number(data.free_minutes),
            grace_minutes:      Number(data.grace_minutes),
            pay_total_time:     data.pay_total_time,
 
        }).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.data.success && typeof callback === 'function') {

                SauronApi.post('/exec', {

                    command: 'sync_licensing_type_list',
                    client_id: id_establishment,

                    params: {
                        
                    },

                }).then((response) => {

                    console.log(response)
                    
                })

                return callback(true)
                
            }

            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function createLicensingTypeValue(data, licensingType, callback) {
    
    // console.log(data) 

    /*return dispatch => {
        
        dispatch({
                    type: LICENSINGTYPE_VALUE_CREATE,
                    licensingType:      licensingType,
                    licensingTypeValue: data
                });

        return callback(true)
    }*/
    
    return dispatch => {

        ApiService.post('/licensing/licensing-type-value-create', {

            id_licensing_type:      data.id_licensing_type,
            id_vehicle_type:        data.id_vehicle_type,
            initial_hour_value:     Number(data.initial_hour_value),
            additional_hour_value:  Number(data.additional_hour_value),
            licensing_value:        Number(data.licensing_value),

        }).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.data.success && typeof callback === 'function') {

                dispatch ({

                    type: LICENSINGTYPE_VALUE_CREATE,
                    licensingType:      licensingType,
                    licensingTypeValue: data
                });

                SauronApi.post('/exec', {

                    command: 'sync_licensing_type_list',
                    client_id: licensingType.id_establishment,

                    params: {
                        
                    },

                }).then((response) => {

                    console.log(response)
                    
                })


                return callback(true)                
            }

            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function changeActive(data, callback) {

    return dispatch => {

        ApiService.patch('/vehicle/vehicle-parking-free-change-active', {

            id: String(data.id),
            active: !data.active

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({
                    type: LICENSINGTYPE_CHANGE_ACTIVE,
                    payload: response.resultSet[0]
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}

export function deleteLicensingTypeValue(licensingType, licensingTypeValue, callback) {

    return dispatch => {

        ApiService.delete('/licensing/licensing-type-value-delete/'+licensingTypeValue.id).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.success && typeof callback === 'function') {

                dispatch({
                    type: LICENSINGTYPE_VALUE_DELETE,
                    licensingType:      licensingType,
                    licensingTypeValue: licensingTypeValue
                });

                return callback(true);
            }

            if (typeof callback === 'function')
                callback(response.message);

        })
    }
}
