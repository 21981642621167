import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    Typography,
    MenuItem,
    Box
} from '@material-ui/core';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import FormikSelect from '../../App/components/FormikSelect';
import GreatMask from '../../App/utils/GreatMask';
import { createPlateParkingFree, setPlateParkingFree } from '../plateParkingFreeActions';
import './styles.css';

export default function FormNewPlateParkingFree({ open, setOpen, id_establishment }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const formik = useFormik({

        initialValues: {
            plate: '',
            renavam: '',
            observation: '',
            permanent: true,
            qty_day: '',
        },
        validate: values => {

            const errors = {};

            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            dispatch(createPlateParkingFree(values, id_establishment, (resp) => {

                setModal({

                    open: true,
                    title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                    text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
                })

                if (typeof resp === 'boolean' && resp && typeof setOpen === 'function') {

                    dispatch(setPlateParkingFree((resp) => {

                        if (typeof resp !== 'boolean' || !resp) {

                            setModal({
                                modal,
                                open: true,
                                text: resp
                            })
                        }

                        setLoading(false)
                    }))

                    setOpen();
                }

                setLoading(false);
            }));
        },
    });


    function handleClose() {
        if (typeof setOpen === 'function') setOpen();
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md">

            <DialogContent>
                <Typography variant="h5" className="dialog-title">Nova Placa com livre acesso</Typography>

                <Box className="content-form">
                    
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form">

                        <Box className="half-div">

                            <FormikTextInput
                                label="Placa"
                                name="plate"
                                value={formik.values.plate.toUpperCase()}
                                onChange={formik.handleChange}
                                InputLabelProps={{ maxLength: 7 }}
                                className="half-input"
                                required/>

                            <FormikTextInput
                                label="Renavam"
                                name="renavam"
                                value={GreatMask.onlyNumber(formik.values.renavam)}
                                onChange={formik.handleChange}
                                InputLabelProps={{ maxLength: 11 }}
                                className="half-input" 
                                required/>
                        </Box>

                        <Box className="half-div">

                            <FormikSelect
                                label="Permanente"
                                name="permanent"
                                value={formik.values.permanent}
                                onChange={(e) => formik.setFieldValue('permanent', e.target.value)}
                                className="half-input">

                                <MenuItem value={true} >Sim</MenuItem>
                                <MenuItem value={false} >Não</MenuItem>

                            </FormikSelect>

                            <FormikTextInput
                                label="Dias Livre"
                                name="qty_day"
                                value={formik.values.qty_day}
                                onChange={(e) => formik.setFieldValue('qty_day', GreatMask.onlyNumber(e.target.value))}
                                disabled={formik.values.permanent}
                                className="half-input" />
                        </Box>

                        <Box className="half-div">

                            <FormikTextInput
                                label="Observação"
                                name="observation"
                                value={formik.values.observation.toUpperCase()}
                                onChange={formik.handleChange}
                                className="full-input" />
                        </Box>

                        <Box className="button-content">
                            
                            <MUIButton onClick={handleClose} buttonType="red">
                                Cancelar
                            </MUIButton>

                            <MUIButton
                                type="submit"
                                buttonType="green"
                                disabled={formik.isValid && formik.dirty ? false : true}>

                                Cadastrar
                            </MUIButton>
                        </Box>
                    </form>
                </Box>
            </DialogContent>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })} />
                
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}