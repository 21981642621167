import { useSelector } from 'react-redux';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
    IconButton,
    MenuItem
} from '@material-ui/core';
import { Settings } from "@material-ui/icons";
import GreatMask from '../../App/utils/GreatMask';
import useStylesTable from '../../App/styles/useStylesTable'
import { TableWithLicense } from './TableWithLicense';
import { LicensingStatus } from './LicensingStatus';

export default function Licensing({ onGetLicenseEstablishment, onRemoveLicense, onShowDetailOrChange, onShowDetailOrChangeMyLicense }) {
    
    const { licensings } = useSelector((state) => state.licensing);
    const classes = useStylesTable();

    function handleGetOrRemoveLicensing(licensing, haveLicensing, onClosePopup = () => { }) {

        // console.log(licensing)

        if (haveLicensing && typeof onRemoveLicense === 'function') {

            onRemoveLicense(licensing)
        } 
        else if (typeof onGetLicenseEstablishment === 'function') {

            onGetLicenseEstablishment(licensing)
        }

        onClosePopup()
    }

    function handleShowDetailOrChange(licensing, isShow, onClosePopup = () => { }) {

        if (typeof onShowDetailOrChange === 'function')
            onShowDetailOrChange(licensing, isShow)

        onClosePopup()
    }

    function handleShowDetailOrChangeMyLicense(licensing, isShow) {

        if (typeof onShowDetailOrChangeMyLicense === 'function')
            onShowDetailOrChangeMyLicense(licensing, isShow)
    }

    function isFreeParking(free) {
        return free ? 'Sim' : 'Não'
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Nome</TableCell>
                        <TableCell align="center">CNPJ</TableCell>
                        <TableCell align="center">Telefone</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell align="center">Livre</TableCell>
                        <TableCell align="center">Minutos livre</TableCell>
                        <TableCell>Opções</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {licensings.map((licensing) => {

                    {/* console.log(licensing) */}

                        const isMyLicenses = !licensing?.hasOwnProperty('licensingEstablishments');
                        
                        const haveLicensing = !isMyLicenses
                            && licensing?.licensingEstablishments[0]?.active === true
                            && licensing?.licensingEstablishments[0];

                        if (isMyLicenses) {

                            return (
                                <TableWithLicense
                                    key={licensing.id}
                                    license={licensing}
                                    onRemoveLicense={handleGetOrRemoveLicensing}
                                    onShowDetailOrChange={handleShowDetailOrChangeMyLicense}
                                />
                            )
                        }

                        return (
                            <TableRow key={licensing.id}>
                                <TableCell align="justify" component="th" scope="row">
                                    {licensing.name}
                                </TableCell>
                                <TableCell align="center">
                                    {licensing.document}
                                </TableCell>
                                <TableCell align="center">
                                    {GreatMask.toPhone(licensing.phone_number)}
                                </TableCell>
                                <TableCell align="center">
                                    <LicensingStatus status={licensing.active} />
                                </TableCell>
                                <TableCell align="center">
                                    {!haveLicensing ? '---' : isFreeParking(haveLicensing.parking_free)}
                                </TableCell>
                                <TableCell align="center">
                                    {!haveLicensing
                                        ? '---'
                                        : GreatMask.minutesToHoursMinutes(haveLicensing.free_minutes)
                                    }
                                </TableCell>
                                <TableCell width={50}>
                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                        {(popupState) => (
                                            <>
                                                <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                    <Settings />
                                                </IconButton>
                                                <Menu {...bindMenu(popupState)}>
                                                    <MenuItem onClick={() => handleGetOrRemoveLicensing(licensing, !!haveLicensing, popupState.close())}>
                                                        {!!haveLicensing ? 'Desvincular convênio' : 'Vincular convênio'}
                                                    </MenuItem>
                                                    {!haveLicensing &&
                                                        <MenuItem onClick={() => handleShowDetailOrChange(licensing, false, popupState.close())}>
                                                            Editar convênio
                                                        </MenuItem>
                                                    }
                                                    <MenuItem onClick={() => handleShowDetailOrChange(licensing, true, popupState.close())}>
                                                        Visualizar detalhes
                                                    </MenuItem>
                                                </Menu>
                                            </>
                                        )}
                                    </PopupState>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}