import React from 'react'
import { Redirect, Route } from "react-router";
import { useSelector } from 'react-redux';
import App from "./App";


function AuthenticatedRouter({component: Component, ...rest}) {
    const { user } = useSelector((state) => state.user);

    const isAuthenticated = () => {
        const token = localStorage.getItem('token');
        
        try {
            return token && user.hasOwnProperty('id')
        } catch (error) {
            return false;
        }
    }

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? (
                    <App><Component {...props} /></App>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                )
            }
        />
    );
}
export default AuthenticatedRouter;