import { format } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import GreatMask from '../App/utils/GreatMask';
import { PARKING_SET, PARKING_CREATE, PARKING_STOP } from './parkingReducers';

export function setParking() {

    return dispatch => {

        const currentDate = format(new Date(), 'yyyy-MM-dd')

        ApiService.get(`/parking?initDate=${currentDate}&finalDate=${currentDate}`)
            .then((response) => {

                if (typeof response !== 'object') 
                    return

                if (response.data?.success) {

                    dispatch({

                        type: PARKING_SET,
                        payload: response.data.resultSet
                    });
                }
        })
    }
}

export function filterParking(date, callback) {
    
    const initialDate = format(date.initialDate, 'yyyy-MM-dd');
    const finalDate = format(date.finalDate, 'yyyy-MM-dd');
    const finished = date.finished === 'Todos' ? '' : date.finished;

    return dispatch => {

        ApiService.get(`/parking?initDate=${initialDate}&finalDate=${finalDate}&finished=${finished}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            
            if (response.data.success && typeof callback === 'function') {

                dispatch({

                    type: PARKING_SET,
                    payload: response.data.resultSet
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function createParking(data, external_id, callback) {

    return dispatch => {

        ApiService.post('/parking/create', {

            id_parking_type: String(data.typeEstablishment),
            id_vehicle_type: String(data.id_vehicle_type),
            infos: {
                comments: data.comments
            },
            plaque: GreatMask.toUpperCaseOnlyText(data.plaque),
            arrival: `${GreatMask.dateBRtoUS(data.date)} ${data.hour}`,
            external_id: String(external_id)

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response?.data.success && typeof callback === 'function') {

                dispatch({
                    type: PARKING_CREATE,
                    payload: response.data.resultSet
                });

                return callback(true);
            }
            
            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}

export function stopParking(data, callback) {
    return dispatch => {
        ApiService.post('/parking/stop', {
            id: String(data.id),
            fake: false
        }).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }
            if (response?.data.success && typeof callback === 'function') {
                dispatch({
                    type: PARKING_STOP,
                    payload: response.data.resultSet[0]
                });

                return callback(true);
            }
            if (typeof callback === 'function')
                callback(response.data.message);

        })
    }
}