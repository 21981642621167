import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikTextInput from '../../App/components/FormikTextInput';
import FormikSelect from '../../App/components/FormikSelect';
import MUIButton from '../../App/components/MUIButton';
import GreatMask from '../../App/utils/GreatMask';
import Validation from '../../App/utils/Validation';
import { vehicleTypes } from '../../App/utils/typesVehicles';
import './styles.css';
import Loader from '../../App/components/Loader';

export default function FormNewHolder({ cpf, holderChange, onConfirm, onCancel }) {
    const { holder } = useSelector((state) => state.holder);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const formik = useFormik({

        initialValues: {

            external_id:        '',
            email:              '',
            phoneNumber:        '',
            name:               '',
            cpf:                '',
            birthday:           '',
            expirationDay:      1,
            blocked:            true,
            paid:               false,
            value:              GreatMask.formatCoinBR('0'),

            validation_code:    '',
            key_value:          '',

            vehicles: [{

                id:             uuidv4(),
                plaque:         '',
                id_vehicle_type:'1',
                renavam:        '',
                name:           '',
                photo:          '',
            }],

            address:            '',
            addressNumber:      '',
            zipcode:            '',
            number:             '',
            complement:         '',

        },
        validate: values => {

            const errors = {};
            
            if (values.name.trim() !== '' && values.name.length < 3) {
                errors.name = true;
            }
            
            if (values.birthday.trim() !== '' && !Validation.birthdayAdult(values.birthday)) {
                errors.birthday = true;
            }
            
            if (values.cpf.trim() !== '' && !Validation.cpf(GreatMask.onlyNumber(values.cpf))) {
                errors.cpf = true;
            }
            
            if (values.phoneNumber.trim() !== '' && GreatMask.onlyNumber(values.phoneNumber).length !== 11) {
                errors.phoneNumber = true;
            }
            
            if (values.email.length < 3) {
                errors.email = true;
            }
            
            if (values.zipcode.length < 5) {
                errors.zipcode = true;
            }
            
            if (values.addressNumber.length < 1) {
                errors.addressNumber = true;
            }
            
            if (values.expirationDay.length < 1) {
                errors.expirationDay = true;
            }
            
            return errors;
        },

        onSubmit: values => {
            if (typeof onConfirm === 'function') onConfirm(values);
        },
    });

    const vehicleNewData = {

        id:             uuidv4(),
        plaque:         '',
        id_vehicle_type:'1',
        renavam:        '',
        name:           '',
        photo:          '',
    }

    const existData = useCallback(() => {

        if (typeof holderChange === 'object' && holderChange.hasOwnProperty('cpf')) {

            for (let [key, value] of Object.entries(holderChange)) {
                formik.setFieldValue(`${key}`, value);
            }
            
            return;
        }

        if (typeof holder === 'object' && holder.hasOwnProperty('external_id')) {

            formik.setFieldValue('external_id', holder.external_id);
            formik.setFieldValue('email',       holder.email);
            formik.setFieldValue('phoneNumber', holder.phone_number);
            formik.setFieldValue('name',        holder.name);
            formik.setFieldValue('cpf',         holder.cpf);
            formik.setFieldValue('birthday',    holder.birthday);
            
            return;
        }

        if (typeof cpf === 'string') {
            return formik.setFieldValue('cpf', cpf);
        }

    }, [cpf, holder, holderChange, formik])

    useEffect(() => {

        existData();
        
        setLoading(false);

        return () => { existData() }

    }, [existData])

    function handleClose() {

        if (typeof onCancel === 'function')
            onCancel();
    }

    async function searchZipcode(zipcode) {

        setLoading(true);
        
        const json = await fetch(`https://viacep.com.br/ws/${zipcode}/json`).then(resp => resp.json());

        if (json.hasOwnProperty('erro')) {

            setLoading(false);
            
            return setModal({
                open: true,
                title: 'ERRO!',
                text: 'CEP não existe, informe o CEP corretamente.'
            })
        }

        formik.setFieldValue('district', json.bairro)
        formik.setFieldValue('city', json.localidade)
        formik.setFieldValue('distrstreetict', json.logradouro)
        formik.setFieldValue('uf', json.uf)
        
        setLoading(false);
    }

    function handleAddNewVehicle() {
        
        let newVehicle = formik.values.vehicles;
        
        newVehicle.push(vehicleNewData);
        
        formik.setFieldValue('vehicles', newVehicle);
    }

    function handleRemoveNewVehicle(vehicle) {

        if (formik.values.vehicles.length === 1)
            return formik.setFieldValue('vehicles', [vehicleNewData]);

        let filterData = formik.values.vehicles.filter(item => item.id !== vehicle.id);
        
        if (filterData.length === 0) {
            filterData = [vehicleNewData]
        }
        
        formik.setFieldValue('vehicles', filterData);
    }

    function handleSaveData(item, value, n) {
        
        const novo = formik.values.vehicles.map((i) => {

            if (i.id === item.id)
                return {
                    ...i,
                    [`${n}`]: value
                }

            return i
        })

        formik.setFieldValue('vehicles', novo);
    }

    return loading

        ? <Loader loading={loading} />
        : (<>
            <Typography variant="h5" className="dialog-title">Cadastro do mensalista</Typography>

            <div className="content-form-newholder">
                <Typography variant="subtitle1" className="dialog-title">
                    Preencha o formulário para cadastrar o mensalista
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    className="form"
                >
                    <div className="person">
                        <Typography variant="h6" className="form-div-title">Dados pessoais</Typography>
                        <FormikTextInput
                            label="Nome"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className="full-input"
                            required
                            error={formik.errors.name}
                        />
                        <div className="half-div">
                            <FormikTextInput
                                label="Data de nascimento"
                                name="birthday"
                                value={formik.values.birthday}
                                onChange={(e) => formik.setFieldValue('birthday', GreatMask.dateBR(e.target.value))}
                                InputLabelProps={{ maxLength: 10 }}
                                className='half-input'
                                required
                                error={formik.errors.birthday}
                            />
                            <FormikTextInput
                                label="CPF"
                                name="cpf"
                                value={formik.values.cpf}
                                onChange={(e) => formik.setFieldValue('cpf', GreatMask.cpf(e.target.value))}
                                className='half-input'
                                InputLabelProps={{ maxLength: 14 }}
                                required
                                error={formik.errors.cpf}
                            />
                        </div>
                        <div className="half-div">
                            <FormikTextInput
                                label="Celular"
                                name="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={(e) => formik.setFieldValue('phoneNumber', GreatMask.toPhone(e.target.value))}
                                InputLabelProps={{ maxLength: 14 }}
                                className='half-input'
                                required
                                error={formik.errors.phoneNumber}
                            />
                            <FormikTextInput
                                label="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                className="half-input"
                                required
                                error={formik.errors.email}
                            />
                        </div>
                        <FormikTextInput
                            label="CEP"
                            name="zipcode"
                            value={formik.values.zipcode}
                            onChange={(e) => {
                                const zipcodeNumber = GreatMask.onlyNumber(e.target.value)
                                formik.setFieldValue('zipcode', zipcodeNumber)
                                zipcodeNumber.length === 8 && searchZipcode(zipcodeNumber)
                            }}
                            InputLabelProps={{ maxLength: 8 }}
                            className="half-input"
                            required
                            error={formik.errors.zipcode}
                        />
                        <FormikTextInput
                            label="Endereço"
                            name="address"
                            value={formik.values.address}
                            className="full-input"
                            onChange={formik.handleChange}
                        />
                        <div className="half-div">
                            <FormikTextInput
                                label="Número"
                                name="addressNumber"
                                value={formik.values.addressNumber}
                                className="half-input"
                                onChange={formik.handleChange}
                                required
                                error={formik.errors.addressNumber}
                            />
                            <FormikTextInput
                                label="Complemento"
                                name="complement"
                                value={formik.values.complement}
                                className="half-input"
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="payment">
                        <Typography variant="h6" className="form-div-title">Dados do pagamento</Typography>
                        <div className="half-div">
                            <FormikSelect
                                label="O pagamento já esta sendo realizado?"
                                name="paid"
                                value={formik.values.paid}
                                onChange={formik.handleChange}
                                className="half-input"
                            >
                                <MenuItem value={false}>NÃO</MenuItem>
                                <MenuItem value={true}>SIM</MenuItem>
                            </FormikSelect>

                            <FormikTextInput
                                label="Dia do vencimento"
                                name="expirationDay"
                                value={formik.values.expirationDay}
                                className="half-input"
                                onChange={(e) => {
                                    if (GreatMask.onlyNumber(e.target.value).length < 3 && Number(e.target.value) < 32)
                                        formik.setFieldValue('expirationDay', GreatMask.onlyNumber(e.target.value))
                                }}
                                required
                                error={formik.errors.expirationDay}
                            />

                            <FormikTextInput
                                label="Valor"
                                name="value"
                                value={formik.values.value}
                                className="half-input"
                                onChange={(e) => formik.setFieldValue('value', GreatMask.formatCoinBR(e.target.value))}
                                required
                                error={formik.errors.value}
                            />
                        </div>
                    </div>
                    <div className="Vehicle">
                        <Typography variant="h6" className="form-div-title">Dados do veículo</Typography>
                        <div className="add-data">
                            <MUIButton onClick={handleAddNewVehicle} color="primary">
                                Adicionar veículo
                            </MUIButton>
                        </div>
                        {formik.values.vehicles.map((item, index) => (
                            <div key={item.id}>
                                <div className="half-div">
                                    <FormikSelect
                                        idSelect={`vehicle-select-vehicle-type-${item.index}`}
                                        label="Tipo do veículo"
                                        value={item.id_vehicle_type}
                                        onChange={(e) => handleSaveData(item, e.target.value, 'id_vehicle_type')}
                                        defaultValue="1"
                                        className="half-input"
                                        required
                                    >
                                        {vehicleTypes.map((item) => <MenuItem value={item.id_vehicle_type} key={item.id_vehicle_type} className="menu">
                                            {`${item.name}`}
                                        </MenuItem>
                                        )}
                                    </FormikSelect>
                                    <FormikTextInput
                                        id={`vehicle-name-${item.id}`}
                                        label="Descrição breve do veículo"
                                        value={item.name}
                                        onChange={(e) => handleSaveData(item, e.target.value, 'name')}
                                        className="half-input"
                                        required
                                    />
                                </div>
                                <div className="half-div">
                                    <FormikTextInput
                                        id={`vehicle-plaque-${item.id}`}
                                        label="Placa"
                                        value={item.plaque}
                                        onChange={(e) => handleSaveData(item, e.target.value, 'plaque')}
                                        InputLabelProps={{ maxLength: 7 }}
                                        className="half-input"
                                        required
                                        error={formik.errors.plaque}
                                    />
                                    <FormikTextInput
                                        id={`vehicle-renavam-${item.id}`}
                                        label="Renavam"
                                        value={item.renavam}
                                        onChange={(e) => handleSaveData(item, e.target.value, 'renavam')}
                                        InputLabelProps={{ maxLength: 11 }}
                                        className="half-input"
                                        required
                                        error={formik.errors.renavam}
                                    />
                                </div>
                                <div className="add-data">
                                    <MUIButton onClick={() => handleRemoveNewVehicle(item)} color="primary">
                                        Excluir veículo
                                    </MUIButton>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="button-content">
                        <MUIButton onClick={handleClose} color="secondary">
                            Cancelar
                        </MUIButton>
                        {/* <Button onClick={handleClose} >
                                Voltar
                            </Button> */}
                        {/* <Button onClick={handleConfirm} color="primary" autoFocus> */}
                        <MUIButton
                            type="submit"
                            buttonType="green"
                            disabled={formik.isValid === true ? false : true}
                        >
                            Próximo
                        </MUIButton>
                    </div>
                </form>
            </div>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </>);
}