import { ApiService } from '../App/services/ApiService';
import StorageService from '../App/services/StorageService';
import { SET_API_KEY } from '../App/appReducers';
import { SET_USER, RESET_USER } from '../MyAccount/userReducers';

export function login(username, password, callback) {

    return dispatch => {

        ApiService.post('/authentication/log-in', { username, password }).then((response) => {

            window.localStorage.clear();

            if (response === 0) {
                return callback('Verifique a sua internet e tente novamente.');
            }

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar os dados.');
            }

            if (response.data?.success) {

                if (response['data'].resultSet[0].id_access_group !== 1 && response['data'].resultSet[0].id_access_group !== 2) {
                    return callback('Você não tem permissão para acessar.')
                }

                StorageService.add('token', response.headers['authorization-token']);
                StorageService.add('refresh', response.headers['refresh-token']);

                dispatch({
                    type: SET_USER,
                    payload: {
                        user: response['data'].resultSet[0]
                    }
                });

                dispatch({
                    type: SET_API_KEY,
                    payload: response['data'].resultSet[0].authentication_key
                });

                if (typeof callback === 'function')
                    callback(true);
            }
            
            if (typeof callback === 'function')
                callback('Usuário ou senha incorreto. Verifique os dados informados e tente novamente.');
        })
    }
}

export function logout(callback = () => {
}) {
    return dispatch => {
        ApiService.post('/authentication/log-out', {}).then(() => {

            window.localStorage.clear();

            dispatch({
                type: RESET_USER
            });

            if (typeof callback === 'function')
                callback(true);
        })

    }
}