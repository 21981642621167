export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_USER = 'SET_USER';
export const SET_USER_CELLPHONE_NUMBER = 'SET_USER_CELLPHONE_NUMBER';
export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const DELETE_USER_EMAIL = 'DELETE_USER_EMAIL';
export const CHANGE_USER_CELLPHONE = 'CHANGE_USER_CELLPHONE';
export const DELETE_USER_CELLPHONE = 'DELETE_USER_CELLPHONE';
export const RESET_USER = 'RESET_USER';

const INITIAL_STATE = {
    user: {},
    cellphoneNumber: ''
}

export function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_NEW_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case SET_USER_CELLPHONE_NUMBER:
            return {
                ...state,
                cellphoneNumber: action.payload
            }
        case CHANGE_USER_EMAIL:
            let newUserEmails = state.user;
            newUserEmails.emails.push(action.payload);
            return {
                ...state,
                user: newUserEmails
            }
        case CHANGE_USER_CELLPHONE:
            let newUserCellphone = state.user;
            newUserCellphone.phone_numbers.push(action.payload);

            return {
                ...state,
                user: newUserCellphone
            }
        case DELETE_USER_EMAIL:
            let deletedUserEmail = state.user;
            const deletedEmail = deletedUserEmail.emails.filter(item => action.payload.email !== item.email);
            deletedUserEmail.emails = deletedEmail

            return {
                ...state,
                user: deletedUserEmail
            }
        case DELETE_USER_CELLPHONE:
            let deletedUserCellphone = state.user;
            const deletedCellphone = deletedUserCellphone.phone_numbers.filter(item => action.payload.phone_number !== item.phone_number);
            deletedUserCellphone.phone_numbers = deletedCellphone

            return {
                ...state,
                user: deletedUserCellphone
            }
        case RESET_USER:
            return INITIAL_STATE
        default:
            return state
    }
}